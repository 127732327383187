import * as _ from 'ramda';
import produce from 'immer';
import { Reducer } from 'redux';
import { PersonaIntervento, ColturaModel } from '../Containers/Azienda/Coltura/Model';

export type State = {
  persona: PersonaIntervento | null,
  nuovaPersona?: ColturaModel,
  idIntervento: string
  coltura?: ColturaModel
};

export type Action = {
  type: 'NUOVA_PERSONA'
  colturaRow: ColturaModel,
  idIntervento: string,
} | {
  type: 'EDIT_PERSONA'
  persona: PersonaIntervento
  coltura: ColturaModel
  idIntervento: string
};

export const defaultState: State 
  = { persona: null, idIntervento: "" };

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'NUOVA_PERSONA':
      return {
        ...state,
        // persona: null,
        nuovaPersona: payload.colturaRow,
        idIntervento: payload.idIntervento
      };
    case 'EDIT_PERSONA':
      return {
        ...state,
        persona: payload.persona,
        idIntervento: payload.idIntervento,
        coltura: payload.coltura
      };
    default: return state;
  }
};
