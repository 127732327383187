import React, { useEffect, useState } from 'react';
import { Rilevazione as RilevazioneModel, ColturaModel, TipoRilevazione, RilevazioneNew, initialStateRilevazione } from '../../Coltura/Model';
import { fetchObjects, BasicDropdown, BasicInput, postObject, ID_UNITA } from '../../../../Utilities/SharedComponent';
import { useDispatch, useSelector } from '../../../../Utilities/SharedHooks/State';
import { UNDO_ACTION, UNDO } from '../../../../Reducers/Root';
import * as _ from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import produce from 'immer';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { navigateBack } from '../../../../Reducers/Route';

const fetchTipiRilevazione = fetchObjects(`tiporilevazione/${ID_UNITA}`);

export const Rilevazione: React.FC = () => {
  
  const rilevazione = useSelector(x => x.present.rilevazione);
  const dispatch = useDispatch();

  const submitForm = () => {
    console.log(`noop`)
  }

  const formBody =
    rilevazione && (
      <form onSubmit={submitForm} className="has-450-max-width">

        <span>
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
          <span className="subtitle has-text-grey">{rilevazione.idRilevazione}</span>
        </span>
        <br/>
        <br/>
        <BasicInput
          label="Posizione"
          value={rilevazione.posizione || ""}
          readOnly={false}
        />

        <BasicInput
          label="Valore"
          value={rilevazione.valore || ""}
          readOnly={false}
        />

        <BasicInput
          label="Unità di misura"
          value={rilevazione.unitaDiMisura || ""}
          readOnly={false}
        />

        <BasicInput
          label="Note"
          value={rilevazione.note || ""}
          readOnly={false}
        />
      </form>
    )

  return (
    <>{formBody}</>
  );
};

export const NuovaRilevazione: React.FC = () => {
  const [tipiRilevazione, setTipiRilevazione] = useState<TipoRilevazione[]>([]);
  const [rilevazioniAssociate, setRilevazioniAssociate] = useState<TipoRilevazione[]>()
  const [error, setError] = useState("");
  const [tipiRilevazioneIntervento, setTipiRilevazioneIntervento] = useState<TipoRilevazione[]>([]);
  const [tipoRilevazioneSelezionato, setTipoRilevazioneSelezionato] = useState<TipoRilevazione>();

  const [rilevazione, setRilevazione] = useState<RilevazioneNew>(initialStateRilevazione);

  const stateColtura = useSelector(state => state.present.nuovaRilevazione);
  const idIntervento = useSelector(state => state.present.idIntervento);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTipiRilevazione
      .then((data) => { setTipiRilevazione(data); })
      .catch((err) => { setError(err); });
  });

  useEffect(() => {
    setTipiRilevazioneIntervento(tipiRilevazione?.filter(x => x.tag === "Intervento"));
  }, [tipiRilevazione]);

  const handleTipoRilevazioneSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tipoRilevazione =
      _.find(
        _.propEq("descrizione", e.target.value)
        , tipiRilevazione);
    if (tipoRilevazione) {
      setTipoRilevazioneSelezionato(tipoRilevazione);
    }
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tipoRilevazioneSelezionato) {
      const nuovaRilevazione: RilevazioneNew = {
        id: idIntervento
      , tag: "intervento"
      , tipoRilevazione: tipoRilevazioneSelezionato
      , posizione: rilevazione.posizione
      , valore: rilevazione.valore
      , unitaDiMisura: rilevazione.unitaDiMisura
      , note: rilevazione.note
      };

      if(stateColtura) {
        const newStateColtura: ColturaModel = produce(stateColtura, c => {
          _.find(
            colt => colt.idIntervento === idIntervento, c.interventi
          )?.rilevazioni.push({
              ...nuovaRilevazione, idRilevazione : ""
            })
        })
        postObject(`colture/${stateColtura.idReparto}`, newStateColtura);
      }
    }
  };

  const handleTextboxChange = (fn: (v: RilevazioneNew) => void) => {
    setRilevazione(produce(rilevazione, fn));
  };

  return (
    <form onSubmit={submitForm} className="has-450-max-width">
      <span><FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" /><span className="subtitle has-text-grey">Aggiungi una rilevazione</span></span>

      <br/>
      <br/>

      <BasicDropdown label={"Tipo rilevazione"} defaultValue="Seleziona tipo rilevazione" values={tipiRilevazioneIntervento.map(x => x.descrizione)} onSelection={(e) => { handleTipoRilevazioneSelection(e); }} />

      <BasicInput
        label="Posizione"
        value={rilevazione.posizione || ""}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: RilevazioneNew) => { p.posizione = Number(e.target.value); }); }}/>

      <BasicInput
        label="Valore"
        value={rilevazione.valore || ""}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: RilevazioneNew) => { p.valore = e.target.value; }); }}
      />

      <BasicInput
        label="Unità di misura"
        value={rilevazione.unitaDiMisura || ""}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: RilevazioneNew) => { p.unitaDiMisura = e.target.value; }); }}
      />

      <BasicInput
        label="Note"
        value={rilevazione.note || ""}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: RilevazioneNew) => { p.note = e.target.value; }); }}
      />

      <div className="field is-right">
        <p className="control">
          <button type="submit" className="button is-success" >
            Salva rilevazione
          </button>
        </p>
      </div>

    </form>
  );
};
