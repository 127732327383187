import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";

const EditButtonGroup: React.FC<{
  onEdit?: () => void;
  onEditConfirm: () => void;
  onDelete: () => void;
  onCancel?: () => void;
  showDelButton?: boolean
  showConfirmButton?: boolean
}> = ({ onEdit, onDelete, onEditConfirm, showDelButton, showConfirmButton, onCancel }) => {
  showDelButton = showDelButton ?? true
  showConfirmButton = showConfirmButton ?? true
  //const [isUpdating, setUpdateState] = useState(false);
  
  // const startEditing = () => {
  //   setUpdateState(true);
  //   // onEdit();
  // };

  // const btnCancelAct = () => {
  //   stopEditing();
  //   // onCancel();
  // };

  const btnConfirmAct = () => {
    //stopEditing();
    onEditConfirm();
  };

  // const stopEditing = () => {
  //   setUpdateState(false);
  // };

  return (
    <div className="columns has-fullwidth text-is-aligned-right is-display-inherit">
      {showDelButton && (
        <button style={{ margin: "0 20px" }} className="button is-danger" type="button" onClick={onDelete} >
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faTrash} size="lg" />
          Elimina
        </button>
      )}
      {showConfirmButton && (
        <button style={{ margin: "0 20px" }} className="button is-success" type="button" onClick={btnConfirmAct} >
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faCheck} size="lg" />
          Applica
        </button>       
      )}
    </div>
    // <div className="columns has-300-max-width">
    //   <div className="column">
    //     {isUpdating ? (
    //       <button className="button is-primary is-light" onClick={btnConfirmAct} >
    //         Conferma
    //       </button>
    //     ) : (
    //         <button className="button is-primary is-light" onClick={onDelete} >
    //           Elimina
    //       </button>
    //       )}
    //   </div>
    //   <div className="column">
    //     {isUpdating ? (
    //       <button className="button is-primary is-light" onClick={btnCancelAct}>
    //         Annulla
    //       </button>
    //     ) : (
    //         <button className="button is-primary is-light" onClick={startEditing}>
    //           Modifica
    //       </button>
    //       )}
    //   </div>
    // </div>
  );
};

export default EditButtonGroup;
