import React, { useState, useEffect } from 'react';
import { ArticoloIngressoIntervento, initialStateArticoloIngressoIntervento, ArticoloIngressoInterventoNew, ColturaModel, Articolo, TipoRilevazione, Rilevazione, TagRilevazione, Fornitore, UnitaMisura } from '../../Coltura/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { fetchObjects, BasicDropdown, BasicInput, ErrorModal, DateInput, ID_AZIENDA, PUNTO_AZIENDA, ID_UNITA, sortByCaseInsensitive } from '../../../../Utilities/SharedComponent';
import { UNDO_ACTION, UNDO, AllActions } from '../../../../Reducers/Root';
import { useDispatch, useSelector } from '../../../../Utilities/SharedHooks/State';
import * as _ from 'ramda';
import produce from 'immer';
import * as uuid from "uuid"
import moment from 'moment';
import { usePost } from '../../../../Utilities/SharedHooks/Sync';
import CustomTable from '../../../CustomTable/CustomTable';
import { finalizeRestore } from '../../../../Utilities/Redux/Restore';
import { navigateBack } from '../../../../Reducers/Route';
import { useToasts } from 'react-toast-notifications';

const promiseTipiRilevazione = fetchObjects(`tiporilevazione/${ID_UNITA}`)
const fetchFornitore = (codice: string) => fetchObjects(`${ID_AZIENDA}/fornitori?codice=${codice}`);
const fetchUM = fetchObjects(`${ID_AZIENDA}/unitamisura`)
type TagTipoRIlevazioneArticolo = { articolo: string }

export const ArticoloIngresso: React.FC<{ onSubmit: (_ : ColturaModel) => void }> = ({ onSubmit }) => {
  const articoloIngresso = useSelector(x => x.present.articoloIngresso)
  const coltura = useSelector(x => x.present.coltura)
  const idIntervento = useSelector(x => x.present.idIntervento)
  const dispatch = useDispatch<AllActions>()
  const [articoli, setArticoli] = useState<Articolo[]>([])
  const [fornitori, setFornitori] = useState<Fornitore[]>([]);
  const [codiceFornitoreSelezionato, setCodiceFornitoreSelezionato] = useState(articoloIngresso?.codiceFornitore);
  const [costoUnitario, setCostoUnitario] = useState<number | undefined>()
  const [infoArt, setInfoArt] = useState<InfoArticolo[]>([])
  const [articoloModificato, setArticoloModificato] = useState(articoloIngresso)
  const [postColtura, colturaPostata] = usePost(`colture/${coltura?.idReparto}`)
  const [tipiRilevazioni, setTipiRilevazioni] = useState<TipoRilevazione[]>([])
  const [tipiRilevazioniArt, setTipiRilevazioniArt] = useState<TipoRilevazione[]>([])
  const [rilevazioni, setRilevazioni] = useState<Rilevazione[]>([])
  const [um, setUm] = useState<UnitaMisura[]>([])

  useEffect(() => {
    fetchArticoli
      .then((data) => { setArticoli(data); })
  }, []);

  useEffect(() => {
    fetchUM
      .then(res => setUm(res))
  }, [])

  useEffect(() => {
    promiseTipiRilevazione
      .then(data => { setTipiRilevazioni(data) })
  }, [])

  useEffect(() => {
    if(tipiRilevazioni && articoloModificato) {
      const ril = tipiRilevazioni.filter(r => r.tag !== "Intervento")
      let rilA = []
      for(let i = 0; i < ril.length; i++) {
        let tag = ril[i].tag as TagTipoRIlevazioneArticolo
        if (tag.articolo === articoloModificato.codiceInternoArticolo) { // TODO: verificare
          rilA.push(ril[i])
        }
      }
      setTipiRilevazioniArt(rilA)
    }
  }, [tipiRilevazioni])

  useEffect(() => {
    if (tipiRilevazioniArt && articoloModificato) {
      let ril : Rilevazione[] = []
      for(let i = 0; i < tipiRilevazioniArt.length; i++) {
        ril.push({
          idRilevazione: "",
          id: articoloModificato.idArticoloIngressoIntervento || "",
          tag: 'articoloIngresso',
          tipoRilevazione: tipiRilevazioniArt[i],
          posizione: 0,
          valore: "",
          unitaDiMisura: "",
          note: ""
        })
      }
      let tmp = 
        coltura?.interventi
          .find(a => a.idIntervento === idIntervento)?.articoliIngresso
          .find(b => b.idArticoloIngressoIntervento === articoloModificato.idArticoloIngressoIntervento)?.rilevazioni
      if (tmp !== undefined && tmp.length !== 0) {
        setRilevazioni(tmp)
      } else { setRilevazioni(ril) }
    }
  }, [tipiRilevazioniArt])

  useEffect(() => {
    if (articoloIngresso && articoloIngresso.codiceInternoArticolo) {
      fetchInformazioniArticolo(articoloIngresso.codiceInternoArticolo)
        .then(res => setInfoArt(res))
    }
  }, [])

  useEffect(() => {
    fetchArticoli
      .then(res => setArticoli(res))
  }, [])

  useEffect(() => {
    if (articoloIngresso && articoloIngresso.codiceInternoArticolo)
      fetchFornitore(articoloIngresso.codiceFornitore)
        .then(res => setFornitori(res))
  }, [articoloIngresso])

  const handleFornitoreSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const rsFornitore = e.target.value
    const fornitore = infoArt.find(i => i.ragioneSociale === rsFornitore)
    if (fornitore) {
      setCodiceFornitoreSelezionato(fornitore.codiceFornitore)
      setCostoUnitario(fornitore.costo ?? 0)
    } else {
      setCodiceFornitoreSelezionato("")
      setCostoUnitario(0)
    }
  };

  const handleTextboxChange = (fn: (v: ArticoloIngressoIntervento) => void) => {
    setArticoloModificato(produce(articoloModificato, fn));
  };

  const handleUMSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const um : string = e.target.value
    if (um !== "0") {
      handleTextboxChange((f : ArticoloIngressoIntervento) => { f.unitaDiMisuraAcquisto = um})
    }
  }

  const { addToast } = useToasts();
  
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!articoloModificato) return
    
    const fornitore = fornitori.find(a => a.codiceFornitore === codiceFornitoreSelezionato)
    
    if (articoloIngresso && codiceFornitoreSelezionato && fornitore && _.not(_.isEmpty(codiceFornitoreSelezionato))) {
      const nuovoArticoloIn: ArticoloIngressoIntervento = {
        idArticoloIngressoIntervento: articoloModificato.idArticoloIngressoIntervento 
      , codiceInternoArticolo: articoloModificato.codiceInternoArticolo
      , codiceArticolo: articoloModificato.codiceArticolo
      , descrizioneArticolo: articoloModificato.descrizioneArticolo
      , codiceFornitore: codiceFornitoreSelezionato
      , nomeFornitore: fornitore.ragioneSociale 
      , dataUtilizzo: articoloModificato.dataUtilizzo
      , unitaDiMisuraAcquisto: articoloModificato.unitaDiMisuraAcquisto
      , quantita: articoloModificato.quantita
      , costoUnitario: articoloModificato.costoUnitario
      , lotto: articoloModificato.lotto
      , giorniCarenza: articoloModificato.giorniCarenza
      , costoTotale: articoloModificato.costoTotale
      , rilevazioni: rilevazioni // []
      };
      
      const newStateColtura: ColturaModel | undefined = produce(coltura, c => {
        if(c) {
          const index = c.interventi
            .find(colt => colt.idIntervento === idIntervento)?.articoliIngresso
            .findIndex(art => art.idArticoloIngressoIntervento === articoloIngresso.idArticoloIngressoIntervento)
          if (index !== undefined) {
            const x = c.interventi.find(s => s.idIntervento === idIntervento)
            if (x) {
              x.articoliIngresso[index] = nuovoArticoloIn
            }
          }
        }
      });
      if (newStateColtura !== undefined) {
        postColtura(newStateColtura).then(e => { 
          if(!e.isError){
            onSubmit(newStateColtura)
            dispatch({type: "COLTURA_EDIT", coltura: newStateColtura })
            dispatch({ 
              type:"INTERVENTO_EDIT", 
              intervento: newStateColtura.interventi.find(c => c.idIntervento === idIntervento)!
            })
            dispatch(navigateBack())
          }
        })
      }
    }
  };

  const formBody =
    articoloModificato && (
      <form onSubmit={submitForm} className="has-450-max-width">
        <span>
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
          <span className="subtitle has-text-grey">{articoloModificato && articoloModificato.descrizioneArticolo}</span>
        </span>
        <br />
        <br />

        <BasicInput
          label="Giorni di carenza"
          type="number"
          value={articoli && articoli.find(a => a.codiceInternoArticolo === articoloModificato.codiceInternoArticolo)?.giorniCarenza || 0}
          readOnly={true}
        />

        <BasicDropdown label={"Fornitore"} defaultValue={fornitori.find(a => a.codiceFornitore === articoloModificato.codiceFornitore)?.ragioneSociale || ""} values={infoArt.map(x => x.ragioneSociale)} onSelection={(e) => { handleFornitoreSelection(e); }} />

        <BasicInput
          label="Lotto"
          value={articoloModificato.lotto || ""}
          readOnly={false}
          onChange={e => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.lotto = e.target.value; }); }}
        />
        
        <BasicInput
          label="Costo unitario"
          type="number"
          value={costoUnitario || articoloModificato.costoUnitario}
          readOnly={false}
          onChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.costoUnitario = Number(e.target.value) })}}
        />

        <DateInput
          label="Data utilizzo"
          defaultValue={articoloModificato.dataUtilizzo}
          onDayChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.dataUtilizzo = moment(e).format('YYYY-MM-DD') }) }} 
        />

        <div className="columns">
          <div className="column">
            <BasicInput
              label="Quantità"
              value={articoloModificato.quantita || 0}
              readOnly={false}
              type="number"
              onChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.quantita = Number(e.target.value); }); }}
            />
          </div>
          <div className="column">
              <BasicInput
              label="Unità di misura"
              value={articoloModificato.unitaDiMisuraAcquisto}
              readOnly={true}
            />
            {/* <BasicDropdown label={"Unità di misura"} defaultValue={articoloModificato.unitaDiMisuraAcquisto} values={um.map(x => x.descrizione).filter(x => x !== articoloModificato.unitaDiMisuraAcquisto)} onSelection={(e) => { handleUMSelection(e) }} /> */}
          </div>
        </div>

        <div className="field" style={{ display: 'flex', justifyContent: 'right' }}>
          <p className="control">
            <button type="submit" className="button is-success" >
              Applica
            </button>
          </p>
        </div>
      </form>
    );

  const handleApplyRilevazioni = (e: { [index: string]: any; }[]) => {
    const ril: Rilevazione[] =
      e.map((rilevazione, i) => {
        const valore = rilevazione?.valore != null ? rilevazione.valore : ""
        const unitaDiMisura = rilevazione?.unitaDiMisura != null ? rilevazione.unitaDiMisura : ""
        return {
          idRilevazione: rilevazioni[i].idRilevazione !== "" ? rilevazioni[i].idRilevazione : uuid.v4(),
          tipoRilevazione: tipiRilevazioniArt[i],
          valore,
          tag: 'articoloIngresso' as TagRilevazione,
          posizione: 0,
          note: '',
          id: articoloModificato?.idArticoloIngressoIntervento || "",
          unitaDiMisura
        } as Rilevazione
      })
    if (ril) {
      const newStateColtura: ColturaModel | undefined = produce(coltura, c => {
        for(let i = 0; i < ril.length; i++) {
          let arr = c?.interventi
            .find(i => i.idIntervento === idIntervento)?.articoliIngresso
            .find(a => a.idArticoloIngressoIntervento === articoloModificato?.idArticoloIngressoIntervento)?.rilevazioni
          let index: number | undefined = arr?.findIndex(e => e.tipoRilevazione.idTipoRilevazione === ril[i].tipoRilevazione.idTipoRilevazione)
          if (index === -1) {
            arr?.push(ril[i])
          } else {
            if (arr !== undefined && index !== undefined) {
              arr[index] = {...ril[i], idRilevazione: arr[index].idRilevazione }
            }
          }
        }
      })
      postColtura(newStateColtura).then(e => {
        if(!e.isError){
          dispatch({ type: "COLTURA_EDIT", coltura: newStateColtura! })
          dispatch({ type: "INTERVENTO_EDIT", intervento: newStateColtura?.interventi.find(i => i.idIntervento === idIntervento)! })
          addToast('Rilevazioni aggiornate correttamente', { appearance: 'success', autoDismiss: true })
        }
      })
    }
  }

  return (
    <>
      {formBody}
      {rilevazioni !== undefined &&  rilevazioni.length !== 0 && (
        <CustomTable
          headers={["tipoRilevazione.descrizione", "tipoRilevazione.tipoValore" , { key:"valore", value: "editable"}, "tipoRilevazione.unitaDiMisura", "tipoRilevazione.obbligatoria"]}
          rows={rilevazioni}
          title="Rilevazioni"
          editable={true}
          onApply={handleApplyRilevazioni}
          onCreate={() => {}}
          onDelete={() => {}}
          onUpdate={() => {}}
        />
      )}
    </>
  );
};

const fetchArticoli = fetchObjects(`${ID_AZIENDA}/${PUNTO_AZIENDA}/articoli`);
const fetchFornitori = fetchObjects(`${ID_AZIENDA}/fornitori`);
const fetchInformazioniArticolo = (id: string) => fetchObjects(`${ID_AZIENDA}/costiArticolo?codice=${id}`);

type InfoArticolo = {
  codiceFornitore: string
  ragioneSociale : string
  costo : number
}

export const NuovoArticoloIngresso: React.FC<{ onCreate: (_ : ColturaModel) => void }> = ({ onCreate }) => {
  const [articoli, setArticoli] = useState<Articolo[]>([])
  const [fornitori, setFornitori] = useState<Fornitore[]>([])
  const [articoloSelezionato, setArticoloSelezionato] = useState<Articolo | undefined>()
  const [codiceFornitoreSelezionato, setCodiceFornitoreSelezionato] = useState<string>("")

  const [articolo, setArticolo] = useState<ArticoloIngressoInterventoNew>(initialStateArticoloIngressoIntervento)
  const [, setError] = useState("")

  const stateColtura = useSelector(state => state.present.nuovoArticoloIngresso)
  const dispatch = useDispatch<AllActions>()
  const idIntervento = useSelector(state => state.present.idIntervento)
  const interventoCorrente = useSelector(state => state.present.interventoSelectedRow)
  const [postColtura, colturaPostata] = usePost(`colture/${stateColtura?.idReparto}`)

  useEffect(() => {
    fetchArticoli
      .then((data: Articolo[]) => {
        if (interventoCorrente) {
          const filtroTipoArticolo = interventoCorrente.tipoIntervento.tipiArticoloIngresso
          if (filtroTipoArticolo.length === 0) {
            setArticoli(data)
            return
          }

          const arts: Articolo[] = []
          data.map(d => {
            for (const f of filtroTipoArticolo) {
              if (d.tipoArticolo === f) {
                arts.push(d)
              }
            }
          })
          setArticoli(arts)
        }      
      })
      .catch((err) => { setError(err) })
  }, [interventoCorrente])

  const [infoArt, setInfoArt] = useState<InfoArticolo[]>([])
  const [costoUnitario, setCostoUnitario] = useState<number>(0)
  const [giorniDiCarenza, setGiorniDiCarenza] = useState<number>(0)
  const [um, setUm] = useState("")

  useEffect(() => {
    if (articoloSelezionato && articoloSelezionato.codiceInternoArticolo) {
      fetchInformazioniArticolo(articoloSelezionato.codiceInternoArticolo)
        .then(res => setInfoArt(res))
    }
  }, [articoloSelezionato])

  useEffect(() => {
    fetchFornitori
      .then((data) => { setFornitori(data) })
      .catch((err) => { setError(err) })
  }, [])

  const handleArticoloSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tmp = e.target.value.split('-')[0].trimEnd()
    const art =
      _.find(
        _.propEq("codiceArticolo", tmp)
        , articoli)
    if (art) {
      setArticoloSelezionato(art);
      setGiorniDiCarenza(art.giorniCarenza || 0)
      setUm(art.unitaDiMisuraAcquisto || "")
    } else {
      setArticoloSelezionato(undefined);
      setInfoArt([])
      setCostoUnitario(0)
      setGiorniDiCarenza(0)
    }
  }

  const handleFornitoreSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const rsFornitore = e.target.value

    const fornitore = infoArt.find(i => i.ragioneSociale === rsFornitore)
    if (fornitore) {
      setCodiceFornitoreSelezionato(fornitore.codiceFornitore)
      setCostoUnitario(fornitore.costo ?? 0)
    } else {
      setCodiceFornitoreSelezionato("")
      setCostoUnitario(0)
    }
  }

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fornitore = fornitori.find(a => a.codiceFornitore === codiceFornitoreSelezionato)

    const _costoUnitario = articolo.costoUnitario !== 0 ? articolo.costoUnitario : costoUnitario

    if (articoloSelezionato && fornitore && _.not(_.isEmpty(codiceFornitoreSelezionato))) {
      const idArticoloIngressoIntervento = uuid.v4();
      const nuovoArticoloIn: ArticoloIngressoIntervento = {
        idArticoloIngressoIntervento
      , codiceInternoArticolo: articoloSelezionato.codiceInternoArticolo
      , codiceArticolo: articoloSelezionato?.codiceArticolo ?? ""
      , descrizioneArticolo: articoloSelezionato.descrizione
      , codiceFornitore: codiceFornitoreSelezionato
      , nomeFornitore: fornitore.ragioneSociale
      , dataUtilizzo: articolo.dataUtilizzo
      , unitaDiMisuraAcquisto: articoloSelezionato.unitaDiMisuraAcquisto
      , quantita: articolo.quantita
      , costoUnitario: _costoUnitario
      , lotto: articolo.lotto
      , giorniCarenza: articoloSelezionato.giorniCarenza
      , costoTotale: _costoUnitario * articolo.quantita
      , rilevazioni: []
      };

      if (stateColtura) {
        const indexIntervento = stateColtura.interventi.findIndex(colt => colt.idIntervento === idIntervento)
        if(indexIntervento === -1){ return; }
        const newIntervento = produce(stateColtura.interventi[indexIntervento], i => {
          i.articoliIngresso.push({ ...nuovoArticoloIn })
        })
        const newStateColtura: ColturaModel = produce(stateColtura, c => {
          c.interventi[indexIntervento] = newIntervento
        });
        
        postColtura(newStateColtura).then(e => { 
          if(!e.isError){
            onCreate(stateColtura)
            setArticolo(initialStateArticoloIngressoIntervento);
            dispatch({ type: "COLTURA_EDIT", coltura: newStateColtura })
            dispatch({ type: "INTERVENTO_EDIT", intervento: newIntervento })
            dispatch(navigateBack())
          }
        });
      }
    }
  };

  const handleTextboxChange = (fn: (v: ArticoloIngressoIntervento) => void) => {
    setArticolo(produce(articolo, fn));
  };

  return (<>
    <ErrorModal prom={colturaPostata} />
    <form onSubmit={submitForm} className="has-450-max-width">

      <span>
        <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
        <span className="subtitle has-text-grey">Aggiungi un articolo in ingresso</span>
      </span>

      <br />
      <br />
      <BasicDropdown 
        label={"Articolo"} 
        defaultValue="Seleziona articolo" 
        values={articoli.map(x => `${x.codiceArticolo} - ${x.descrizione}`)} 
        onSelection={(e) => { handleArticoloSelection(e); }} 
      />

      {/* <Typeahead options={articoli.map(a => a.descrizione)} label="Articolo" placeholder="Seleziona articolo" onSelection={e => { handleArticoloSelection(e)}} /> */}
      <BasicInput
        label="Giorni di carenza"
        value={giorniDiCarenza}
        readOnly={true}
      />

      <BasicDropdown label={"Fornitore"} defaultValue="Seleziona fornitore" values={infoArt.map(x => x.ragioneSociale)} onSelection={(e) => { handleFornitoreSelection(e); }} />

      <BasicInput
        label="Lotto"
        value={articolo.lotto || ""}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.lotto = e.target.value; }); }}
      />

      <BasicInput
        label="Costo unitario"
        type="number"
        value={articolo.costoUnitario !== 0 ? articolo.costoUnitario : costoUnitario}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.costoUnitario = Number(e.target.value) })}}
      />

      <DateInput
        label="Data utilizzo"
        onDayChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.dataUtilizzo = moment(e).format('YYYY-MM-DD') }) }} 
        defaultValue={moment().toDate()}
      />
      
      <div className="columns">
        <div className="column">
          <BasicInput
            label="Quantità"
            value={articolo.quantita || 0}
            readOnly={false}
            type="number"
            onChange={(e) => { handleTextboxChange((p: ArticoloIngressoIntervento) => { p.quantita = Number(e.target.value); }); }}
          />
        </div>
        <div className="column">
          <BasicInput
            label="Unità di misura"
            value={um}
            readOnly={true}
          />
          {/* <BasicDropdown label={"Unità di misura"} defaultValue="Seleziona UM" values={um.map(x => x.descrizione)} onSelection={(e) => { handleUMSelection(e) }} /> */}
        </div>
      </div>

      <div className="field is-right">
        <p className="control">
          <button type="submit" className="button is-success" >
            Salva articolo
            </button>
        </p>
      </div>
    </form>
  </>);
};
