import { faChevronDown, faClock, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPath, navigate } from "hookrouter";
import React, { useEffect, useState } from "react";
import "./QDCSidebarButton.css";
import Collapsible from "react-collapsible";
import logo from '../../../Assets/logo-app.png'
import { User } from "../../../Reducers/User";

type UserRole = 
  | "operatore_gestionale"
  | "operatore_stanza"
  | "unita_produttiva"
  | "azienda"

const QDCSidebarButton: React.FC = props => {
  const classes = (path: string): string => {
    return getPath() === path ? "active-element has-text-weight-bold" : "";
  };

  const [role, setRole] = useState("")
  const [user, setUser] = useState({} as User)

  useEffect(() => {
    const userrole = localStorage.getItem('userrole')
    const roles = localStorage.getItem('roles')

    const u = localStorage.getItem('user')
    if (u != null && u !== "") {
      setUser(JSON.parse(u))
    }

    if (userrole && roles) {
      if (userrole.indexOf('azienda') > -1) {
        setRole('azienda')
      } else if (roles.indexOf('operatore_stanza') > -1) {
        setRole(`operatore_stanza`)
      } else if (roles.indexOf('operatore_gestionale') > -1) {
        setRole(`operatore_gestionale`)
      } else setRole('')
    }
  }, [])

  return (
    <>
      {(role === "operatore_gestionale" || role === "azienda") && (
        <ul className="menu-list bordered-menu">
          <li>
            <span>
              <figure style={{ display: 'inline-flex', verticalAlign: 'middle' }} className="image is-32x32">
                <img src={logo} alt="logo"/>
              </figure>
              <span className="is-size-5 has-10-margin-left">
                ZEUS Farm
              </span>
            </span>
            <ul>
              <li>
                <a href="/dashboard" className={classes("/dashboard")}>
                  Gestione piano colturale
                </a>
              </li>
              {role === "operatore_gestionale" && (
                <Collapsible
                  open={true}
                  trigger={
                    <li className="horizontal-menu-item">
                      <a href="/personale" className={classes("/personale")}>
                        Configurazione
                      </a>
                      <FontAwesomeIcon
                        className="is-sidebar-listitem-icon is-clickable is-icon-aligned-right"
                        icon={faChevronDown}
                      />
                    </li>
                  }
                  className=""
                >
                  <ul>
                    <li>
                      <a href="/articoli" className={classes("/articoli")}>
                        Articoli
                      </a>
                    </li>
                    <li>
                      <a href="/personale" className={classes("/personale")}>
                        Personale
                      </a>
                    </li>
                    <li>
                      <a href="/macchine" className={classes("/macchine")}>
                        Macchinari
                      </a>
                    </li>
                    <li>
                      <a href="/interventi" className={classes("/interventi")}>
                        Interventi
                      </a>
                          </li>
                    <Collapsible
                      open={true}
                      trigger={
                        <li className="horizontal-menu-item">
                          <a href="/rilevazioniarticolo">Rilevazioni</a>
                          <FontAwesomeIcon
                            className="is-sidebar-listitem-icon is-clickable is-icon-aligned-right"
                            icon={faChevronDown}
                          />
                        </li>
                      }
                      className=""
                    >
                      <ul>
                        <li className="has-no-border-left">
                          <a
                            href="/rilevazioniarticolo"
                            className={classes("/rilevazioniarticolo")}
                          >
                            Rilevazioni per articolo
                          </a>
                        </li>
                        <li className="has-no-border-left">
                          <a
                            href="/rilevazioniintervento"
                            className={classes("/rilevazioniintervento")}
                          >
                            Rilevazioni per intervento
                          </a>
                        </li>
                      </ul>
                    </Collapsible>
                  </ul>
                </Collapsible>  
              )}
            </ul>
          </li>
        </ul>

      )}
      {role === "operatore_stanza" && (
        <>
          <ul className="has-margin-top-bottom-10">
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faTools}
                  size="lg"
                  color="#219537"
                  className="has-30-width"
                />
                <span className="is-size-5 has-10-margin-left">
                  <a href="/quickoperation">Interventi rapidi</a>
                </span>
              </span>
            </li>
          </ul>
          <ul className="has-margin-top-bottom-10">
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faClock}
                  size="lg"
                  color="#219537"
                  className="has-30-width"
                  />
                <span className="is-size-5 has-10-margin-left">
                  <a href="/timemanagement">Gestione ingressi/uscite</a>
                </span>
              </span>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default QDCSidebarButton;
