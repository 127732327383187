import { useState, useReducer, ReducerState, Reducer } from "react";
import { useDispatch as useDispatch_, useSelector as useSelector_ } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import * as Redux from "redux";
import * as _ from "ramda";
import { RootState } from "../../Reducers/Root";
import { Route, ROUTES } from "../../Reducers/Route";
/**
 * @summary useState that can be reset to the default value
 * @param def the default value
 */
const useResetState = <T>(def: T) => {
  const [state, setState] = useState(def);
  return [state, setState, () => { setState(def); }] as const;
};

type ResetReducer<S, A> = (def: S) => Reducer<S, A>;

/**
 * @summary a different version of useReducer that lets you get the default value as a parameter to the reducer itself
 * @param reducer the reducer to use
 * @param def the default initial value
 */
const useReducerWithReset = <S, A>(reducer: ResetReducer<S, A>, def: S) => {
  const [state, dispatch] = useReducer(reducer(def), def);
  return [state, dispatch] as const;
};

export type Action<T> = (t: T) => T;


export const useDispatch: <Act extends Redux.Action> () => Dispatch<Act> = () => {
  const dispatch = useDispatch_();
  return dispatch;
};

export const useSelector = <T>(
  getter: (_: RootState) => T,
  equalityFn?: (left: T, right: T) => boolean) => {
  return useSelector_(getter, equalityFn);
}; 

export const useRoute = (route: Route) => {
  return useSelector(x => x.present.currentRoute === route)
}

type RouteDictionary = {
  readonly [P in Route]: boolean
}

function fromPairs<K extends string, V>(pairs: _.KeyValuePair<K, V>[]): Readonly<Record<K,V>> {
  return _.fromPairs(pairs) as any
}

export const useRoutes = () => {
  const currentRoute = useSelector(x => x.present.currentRoute)
  const h = ROUTES.map(route => {
    return [route, route === currentRoute] as _.KeyValuePair<Route, boolean>
  })
  return fromPairs(h)
}