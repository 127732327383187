import * as _ from 'ramda'
import produce from 'immer'
import { Reducer } from 'redux'
import { createAction } from '@reduxjs/toolkit'
import { parentRoute } from '../Utilities/Routing'

export const HOME = 'HOME'
export const REPARTO = 'REPARTO'
export const UNITA_PRODUTTIVA_VUOTA = 'UNITA_PRODUTTIVA_VUOTA'
export const NUOVA_UNITA_PRODUTTIVA = 'NUOVA_UNITA_PRODUTTIVA'
export const NUOVO_REPARTO = 'NUOVO_REPARTO'
export const COLTURA = 'COLTURA'
export const INTERVENTO = 'INTERVENTO'
export const NUOVO_INTERVENTO = 'NUOVO_INTERVENTO'
export const NUOVA_COLTURA = 'NUOVA_COLTURA'
export const ARTICOLO_INGRESSO = 'ARTICOLO_INGRESSO'
export const NUOVO_ARTICOLO_INGRESSO = 'NUOVO_ARTICOLO_INGRESSO'
export const ARTICOLO_USCITA = 'ARTICOLO_USCITA'
export const NUOVO_ARTICOLO_USCITA = 'NUOVO_ARTICOLO_USCITA'
export const MACCHINA = 'MACCHINA'
export const NUOVA_MACCHINA = 'NUOVA_MACCHINA'
export const PERSONA = 'PERSONA'
export const NUOVA_PERSONA = 'NUOVA_PERSONA'
export const RILEVAZIONE = 'RILEVAZIONE'
export const NUOVA_RILEVAZIONE = 'NUOVA_RILEVAZIONE'

export const ROUTES : Route[] = [
  HOME,
  REPARTO,
  UNITA_PRODUTTIVA_VUOTA,
  NUOVA_UNITA_PRODUTTIVA,
  NUOVO_REPARTO,
  COLTURA,
  INTERVENTO,
  NUOVO_INTERVENTO,
  NUOVA_COLTURA,
  ARTICOLO_INGRESSO,
  NUOVO_ARTICOLO_INGRESSO,
  ARTICOLO_USCITA,
  NUOVO_ARTICOLO_USCITA,
  MACCHINA,
  NUOVA_MACCHINA,
  PERSONA,
  NUOVA_PERSONA,
  RILEVAZIONE,
  NUOVA_RILEVAZIONE,
]


export type Route 
  = typeof HOME
  | typeof REPARTO
  | typeof UNITA_PRODUTTIVA_VUOTA
  | typeof NUOVA_UNITA_PRODUTTIVA
  | typeof NUOVO_REPARTO
  | typeof COLTURA
  | typeof INTERVENTO
  | typeof NUOVO_INTERVENTO
  | typeof NUOVA_COLTURA
  | typeof ARTICOLO_INGRESSO
  | typeof NUOVO_ARTICOLO_INGRESSO
  | typeof ARTICOLO_USCITA
  | typeof NUOVO_ARTICOLO_USCITA
  | typeof MACCHINA
  | typeof NUOVA_MACCHINA
  | typeof PERSONA
  | typeof NUOVA_PERSONA
  | typeof RILEVAZIONE
  | typeof NUOVA_RILEVAZIONE;

export type State = {
  currentRoute: Route
}

export type Action = {
  type: 'NAVIGATE',
  payload: Route
} | {
  type: 'NAVIGATE_BACK'
}

export const defaultState = { currentRoute: 'HOME' as const }
export const reducer: Reducer<State, Action> = (state, payload) => {
  if(state === undefined) return defaultState;
  switch(payload.type){
    case 'NAVIGATE':
      return { ...state, currentRoute: payload.payload }
    case 'NAVIGATE_BACK':
      const pr = parentRoute(state.currentRoute)
      if (pr === undefined) throw new Error(`Route is invalid, tried going back from: ${state.currentRoute}`)
      return { ...state, currentRoute: pr }
    default: return state;
  }
}
export function navigate(r : Route): Action { 
  return { type: 'NAVIGATE', payload: r }
}
export function navigateBack(): Action {
  return { type: 'NAVIGATE_BACK' }
}