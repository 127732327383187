import React, { useState, useEffect } from 'react'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColturaModel, InterventoModel } from '../../Azienda/Coltura/Model'
import { RepartoModel } from '../../Azienda/Model'
import { isValidDateRange } from '../../../Utilities/Functions'
import * as _ from 'ramda'

const ProduzioneRapida: React.FC<{
  colture: ColturaModel[]
  reparti: RepartoModel[]
  interventoSelezionato: (i: InterventoModel) => void
  interventoModificato?: InterventoModel
}> = ({ colture, reparti, interventoSelezionato, interventoModificato }) => {
  
  useEffect(() => {
    if (!_.isNil(interventoModificato)) {
      setInterventoAttivo(interventoModificato.idIntervento ?? "")
      interventoSelezionato(interventoModificato)
    }
  }, [interventoModificato])
  
  const getInterventi = (colt: ColturaModel) => {
    return colt.interventi.filter(i => i.tipoIntervento.utilizzoPersone && i.tipoIntervento.articoliUscita && isValidDateRange(i))
  }

  const getReparto = (c: ColturaModel) =>
    reparti.find(r => r.idReparto === c.idReparto)

  const [interventoAttivo, setInterventoAttivo] = useState("")
  const handleClickIntervento = (intervento: InterventoModel) => {
    setInterventoAttivo(intervento.idIntervento ?? "")
    interventoSelezionato(intervento)
  }

  const tileClasses = (i: InterventoModel) => 
    i.idIntervento === interventoAttivo ? 'tile is-child notification is-success' : 'tile is-child notification'

  return (
    <>
      <h1 className="title is-5 has-text-centered">
        <FontAwesomeIcon icon={faLeaf} size="1x" className="has-10-margin-right" />
        Produzione
      </h1>
      <div className="is-scrollable">
        {colture.map(coltura => (
          getInterventi(coltura).map((intervento, i) => (
            <div className="tile is-ancestor" key={i} onClick={_ => handleClickIntervento(intervento)}>
              <div className="tile">
                <div className="tile is-parent">
                  <article className={tileClasses(intervento)}>
                    {/* <p className="title is-4 is-marginless">{getReparto(coltura)?.nomeReparto}</p> */}
                    <p className="subtitle is-marginless has-text-weight-bold">{getReparto(coltura)?.nomeReparto}</p>
                    <p className="subtitle is-marginless">{coltura.descrizione}</p>
                    <div className="content is-marginless">{intervento.tipoIntervento.descrizione}</div>
                  </article>
                </div>
              </div>
            </div>
          ))
        ))}
      </div>
    </>
  )
}

export default ProduzioneRapida