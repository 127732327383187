import React, { useState, useEffect } from 'react';
import { PersonaIntervento, Persona as PersonaModel, PersonaInterventoNew, initialStatePersonaIntervento, ColturaModel } from '../../Coltura/Model';
import { fetchObjects, BasicDropdown, BasicInput, postObject, ErrorModal, ID_UNITA } from '../../../../Utilities/SharedComponent';
import { useDispatch, useSelector } from '../../../../Utilities/SharedHooks/State';
import { UNDO_ACTION, UNDO } from '../../../../Reducers/Root';
import * as _ from 'ramda';
import produce from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import DateInfo from '../../../../Components/DatesInfo/DateInfo';
import moment from 'moment';
import { usePost } from '../../../../Utilities/SharedHooks/Sync';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import * as uuid from "uuid"
import { finalizeRestore } from '../../../../Utilities/Redux/Restore';
import { navigateBack } from '../../../../Reducers/Route';
registerLocale('it', it)

const fetchPersone = fetchObjects(`persone/${ID_UNITA}`);

export const Persona: React.FC = () => {
  const persona = useSelector(x => x.present.persona);
  const dispatch = useDispatch();
  const coltura = useSelector(x => x.present.coltura)
  const idIntervento = useSelector(x => x.present.idIntervento)

  const [persone, setPersone] = useState<PersonaModel[]>();
  const [personaModificata, setPersonaModificata] = useState(persona)
  const [tempoMin, setTempoMin] = useState<number>(0)
  const [tempo, setTempo] = useState<string>("")
  const [postColtura, colturaPostata] = usePost(`colture/${coltura?.idReparto}`)

  useEffect(() => {
    fetchPersone
      .then((data) => { setPersone(data)})
  }, []);

  const calcolaTempo = () => {
    const s = moment(personaModificata?.dataInizio)
    const e = moment(personaModificata?.dataFine)
    if (!_.isNil(s) && !_.isNil(e)) {
      const diff = e.diff(s)
      const m = moment.duration(e.diff(s))
      setTempoMin(m.asMinutes())
      setTempo(moment(diff).utc().format('HH:mm'))
    }
  }

  useEffect(() => {
    calcolaTempo()
  }, [personaModificata?.dataFine, personaModificata?.dataInizio])

  const handleTextboxChange = (fn: (v: PersonaIntervento) => void) => {
    setPersonaModificata(produce(personaModificata, fn));
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const persona = persone?.find(x => x.idPersona === personaModificata?.idPersona)

    if (persona && personaModificata) {
      const nuovaPersona: PersonaIntervento = {
        idPersonaIntervento: personaModificata.idPersonaIntervento
      , idPersona: persona.idPersona
      , dataInizio: personaModificata.dataInizio
      , dataFine: personaModificata.dataFine
      , tempo: tempoMin// personaModificata.tempo
      , costoOrario: personaModificata.costoOrario
      , costoTotale: personaModificata.costoTotale
      , nome: personaModificata.nome
      };
      
      if(coltura){
        const index = coltura.interventi.findIndex(s => s.idIntervento === idIntervento)
        const newIntervento = produce(coltura.interventi[index], i => {
          const indexArt = i.persone.findIndex(s => s.idPersona === persona.idPersona)
          i.persone[indexArt] = nuovaPersona
        })
        const newStateColtura: ColturaModel = produce(coltura, c => {
          c.interventi[index] = newIntervento
        });
        if (newStateColtura !== undefined) {
          postColtura(newStateColtura).then(e => {
            if(!e.isError){
              dispatch({type: "COLTURA_EDIT", coltura: newStateColtura })
              dispatch({ type: "INTERVENTO_EDIT", intervento: newIntervento })
              dispatch(navigateBack())
            }
          })
        }
      }
    }
  };

  const formBody =
    persona && personaModificata && (
      <form onSubmit={submitForm} className="has-450-max-width">
        <span>
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
        <span className="subtitle has-text-grey">{persona.nome}</span>
        </span>

        <br/>
        <br/>

        <span>Tempo di intervento</span>
      
        <div className="columns">
          <div className="column is-one-fifth" style={{ margin: 'auto'}}>
            <span>Dal</span>
          </div>
          <div className="column">
            <DatePicker
              selected={new Date(personaModificata.dataInizio || moment().format('YYYY-MM-DD'))}
              onChange={d => { handleTextboxChange((p: PersonaIntervento) => { p.dataInizio = d?.toISOString() || moment().toISOString() })}}
              timeFormat="HH:mm"
              timeIntervals={1}
              locale="it"
              dateFormat="d MMMM, yyyy h:mm aa"
              className="input"
              shouldCloseOnSelect={false}
              showTimeInput
            />
          </div>
          <div className="column is-one-fifth" style={{ margin: 'auto'}}>
            <span>Al</span>
          </div>
          <div className="column">
            <DatePicker
              selected={new Date(personaModificata.dataFine || moment().format('YYYY-MM-DD'))}
              onChange={d => { handleTextboxChange((p: PersonaIntervento) => { p.dataFine = d?.toISOString() || moment().toISOString() })}}
              timeFormat="HH:mm"
              timeIntervals={1}
              locale="it"
              dateFormat="d MMMM, yyyy h:mm aa"
              className="input"
              shouldCloseOnSelect={false}
              showTimeInput
            />
          </div>
        </div>

        <BasicInput 
          label="Tempo/h" 
          value={tempo} 
          readOnly={true} 
        />
        
        <BasicInput 
          label="Costo orario" 
          value={persona.costoOrario.toFixed(2)} 
          readOnly={true} 
        />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Applica
            </button>
          </p>
        </div>
      </form>
    );

  return (
    <>{formBody}</>
  );
};

export const NuovaPersona: React.FC<{ onCreate: (_ : ColturaModel) => void }> = ({ onCreate }) => {
  const [persone, setPersone] = useState<PersonaModel[]>([]);
  const [persona, setPersona] = useState<PersonaInterventoNew>(initialStatePersonaIntervento);
  const [personaSelezionata, setPersonaSelezionata] = useState<PersonaModel>();
  const [error, setError] = useState("");

  const stateColtura = useSelector(state => state.present.nuovaPersona);
  const idIntervento = useSelector(state => state.present.idIntervento);
  const dispatch = useDispatch();
  const [postColtura, colturaPostata] = usePost(`colture/${stateColtura?.idReparto}`)

  useEffect(() => {
    fetchPersone
      .then((data) => { setPersone(data); })
      .catch((err) => { setError(err); });
  }, []);

  const handleMacchinaSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tmp = e.target.value.split('-')[1].trimStart()
    const pers =
      _.find(
        _.propEq("nomePersona", tmp)
        , persone);
    if (pers) {
      setPersonaSelezionata(pers);
    } else setPersonaSelezionata(undefined)
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (personaSelezionata) {
      const nuovaPersona: PersonaIntervento = {
          idPersonaIntervento: uuid.v4()
        , idPersona: personaSelezionata?.idPersona ?? persone[0].idPersona
        , dataInizio: persona.dataInizio
        , dataFine: persona.dataFine
        , tempo: persona.tempo
        , costoOrario: personaSelezionata.costoOrario
        , costoTotale: Number(((persona.tempo / 60) * personaSelezionata.costoOrario).toFixed(2))
        , nome: personaSelezionata.nomePersona
      };
      
      if (stateColtura) {
        const index = stateColtura.interventi.findIndex(s => s.idIntervento === idIntervento)
        const newStateIntervento = produce(stateColtura.interventi[index], i => {
          i.persone.push({ ...nuovaPersona })
        })
        const newStateColtura: ColturaModel = produce(stateColtura, c => {
          c.interventi[index] = newStateIntervento
        });
        postColtura(newStateColtura).then(_ => { 
          if(!_.isError){
            onCreate(stateColtura)
            dispatch({ type: "COLTURA_EDIT", coltura: newStateColtura })
            dispatch({ type: "INTERVENTO_EDIT", intervento: newStateIntervento })
            dispatch(navigateBack())
          }
        });
        
      }
    }
  };

  const [tempo, setTempo] = useState<string>("")
  const calcolaTempo = () => {
    const s = moment(persona.dataInizio)
    const e = moment(persona.dataFine)
    if (!_.isNil(s) && !_.isNil(e)) {
      const diff = e.diff(s)
      const m = moment.duration(e.diff(s))
      setPersona(produce(persona, (x: PersonaInterventoNew) => { x.tempo = m.asMinutes()}))
      setTempo(moment(diff).utc().format('HH:mm'))
    }
  }

  useEffect(() => {
    calcolaTempo()
  }, [persona.dataFine, persona.dataInizio])

  const handleTextboxChange = (fn: (v: PersonaInterventoNew) => void) => {
    setPersona(produce(persona, fn));
  };

  return (
    <form onSubmit={submitForm} className="has-450-max-width">
      <ErrorModal prom={colturaPostata} />
      <span><FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" /><span className="subtitle has-text-grey">Aggiungi un persona</span></span>
      <br />
      <br />

      <BasicDropdown label={"Persone"} defaultValue="Seleziona persona" values={persone.map(x => `${x.codicePersona} - ${x.nomePersona}`)} onSelection={(e) => { handleMacchinaSelection(e); }} />

      <span>Tempo di intervento</span>
      
      <div className="columns">
        <div className="column is-one-fifth" style={{ margin: 'auto'}}>
          <span>Dal</span>
        </div>
        <div className="column">
          <DatePicker
            selected={new Date(persona.dataInizio || moment().format('YYYY-MM-DD'))}
            onChange={d => { handleTextboxChange((p: PersonaInterventoNew) => { p.dataInizio = d?.toISOString() || moment().toISOString() })}}
            timeFormat="HH:mm"
            timeIntervals={1}
            locale="it"
            dateFormat="d MMMM, yyyy h:mm aa"
            className="input"
            shouldCloseOnSelect={false}
            showTimeInput
          />
        </div>
        <div className="column is-one-fifth" style={{ margin: 'auto'}}>
          <span>Al</span>
        </div>
        <div className="column">
          <DatePicker
            selected={new Date(persona.dataFine || moment().format('YYYY-MM-DD'))}
            onChange={d => { handleTextboxChange((p: PersonaInterventoNew) => { p.dataFine = d?.toISOString() || moment().toISOString() })}}
            timeFormat="HH:mm"
            timeIntervals={1}
            locale="it"
            dateFormat="d MMMM, yyyy h:mm aa"
            className="input"
            shouldCloseOnSelect={false}
            showTimeInput
          />
        </div>
      </div>

      <BasicInput
        label="Tempo (h)"
        value={tempo}
        readOnly={true}
      />
      
      <BasicInput
        label="Costo orario"
        value={personaSelezionata?.costoOrario.toFixed(2) || 0}
        readOnly={true}
      />

      <div className="field is-right">
        <p className="control">
          <button type="submit" className="button is-success" >
            Salva persona
          </button>
        </p>
      </div>

    </form>
  );
};
