import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const ActionButtons: React.FC<{
  clickConferma: () => void
  clickLogOperazioni: () => void
}> = ({ clickConferma, clickLogOperazioni }) => {
  return (
    <div className="buttons has-100-height">
      <button className="button is-success is-fullwidth is-medium" onClick={clickConferma}>
        Conferma
        <FontAwesomeIcon icon={faCheck} className="has-10-margin-left"/>
      </button>
      <button className="button is-info is-fullwidth is-medium" onClick={clickLogOperazioni}>
        Ultime operazioni
      </button>
    </div>
  )
}

export default ActionButtons