import React, { useState, useEffect, ChangeEvent } from "react";
import "./DateInfo.css";
import moment from "moment";
const DateInfo: React.FC<{
  from: string;
  to: string;
  title: string;
  onFromChanged: (value: ChangeEvent<HTMLInputElement>) => void;
  onToChanged: (value: ChangeEvent<HTMLInputElement>) => void;
}> = ({ from, to, title, onFromChanged, onToChanged }) => {
  return (<div className="columns">
    <div className="column is-one-fifth"> <span>{title}</span> </div>
    <div className="column"><div className="field has-addons">
      <p className="control">
        <a className="button is-static has-30-fixed-width">Dal</a>
      </p>
      <p className="control has-fullwidth">
        <input type="date" className="input" value={moment(from).format('YYYY-MM-DD')}
          onChange={onFromChanged} />
      </p>
    </div></div>
    <div className="column"><div className="field has-addons">
      <p className="control">
        <a className="button is-static has-30-fixed-width">Al</a>
      </p>
      <p className="control has-fullwidth">
        <input type="date" className="input" value={moment(to).format('YYYY-MM-DD')}
          onChange={onToChanged} />
      </p>
    </div></div>
  </div>);
};
export default DateInfo;