import React from "react";
import { slide as Menu, State } from "react-burger-menu";

const Burger: React.FC<{
  outerContainerId: string
  pageWrapId: string
  isOpen: boolean
  onStateChange(state: State): void,
}> = (props) => {
  return (
    <Menu width={350} isOpen={!props.isOpen} customBurgerIcon={false} {...props}>
      {props.children}
    </Menu>
  );
};

export default Burger;
