import { InterventoModelNew } from './../Containers/Azienda/Coltura/Model';
import * as _ from "ramda";
import produce from "immer";
import { Reducer } from "redux";
import { ColturaModel, InterventoModel } from "../Containers/Azienda/Coltura/Model";

export interface State {
  colturaSelectedRow: ColturaModel | null;
  nuovoIntervento?: ColturaModel;
}

export const defaultState: State = { colturaSelectedRow: null };

export type Action = {
  type: 'COLTURA_EDIT',
  coltura: ColturaModel
} | {
  type: 'REMOVE_INTERVENTO'
  id: string
} | {
  type: 'NUOVO_INTERVENTO'
  colturaRow: ColturaModel
} | {
  type: 'EDIT_INTERVENTO'
  colturaRow: ColturaModel
  intervento: InterventoModel
} ;


export const reducer: Reducer<State, Action> = (state, action) => {
  if (state === undefined) {
    return { colturaSelectedRow: null };
  }

  switch (action.type) {
    case 'COLTURA_EDIT':
      return { ...state, colturaSelectedRow: action.coltura };
    case 'REMOVE_INTERVENTO':
      if(state.colturaSelectedRow) {
        return {
          ...state,
          colturaSelectedRow: {
            ...state.colturaSelectedRow,
            interventi: state.colturaSelectedRow.interventi.filter(x => x.idIntervento !== action.id)
          }
        }
      } else return state
    case 'NUOVO_INTERVENTO':
      if (state.colturaSelectedRow !== null) {
        return {
          ...state,
          // colturaSelectedRow: null,
          nuovoIntervento: state.colturaSelectedRow
        };
      } else return state;
    case 'EDIT_INTERVENTO':
      return produce(state, ({ colturaSelectedRow }) => {
        if (colturaSelectedRow) {
          colturaSelectedRow.interventi = 
            colturaSelectedRow.interventi.map(x => x.idIntervento !== action.intervento.idIntervento ? x : action.intervento);
        }
      });
    default: return state;
  }
};

