import {
  faPlus,
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import {
  TipoIntervento,
  TipoRilevazione,
  ModalitaIntervento
} from "../Azienda/Coltura/Model";
import Navbar from "../Navbar/Navbar";
import * as uuid from "uuid"
import {
  BasicInput,
  fetchObjects,
  BasicCheckbox,
  BasicDropdown,
  ErrorModal,
  ID_AZIENDA,
  ID_UNITA,
  deleteObject
} from "../../Utilities/SharedComponent";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import produce from "immer";
import "./Interventi.css";
import { usePost } from "../../Utilities/SharedHooks/Sync";
import Modali, { useModali } from "modali";
import MultiSelect from "react-multi-select-component";

const promiseTipiIntervento = fetchObjects(`tipointervento/${ID_UNITA}`);

const Interventi: React.FC = () => {
  const [tipiIntervento, setTipiIntervento] = useState<TipoIntervento[]>([]);
  const [interventoAttivo, setInterventoAttivo] = useState({} as TipoIntervento);
  const [nuovoIntervento, setNuovoIntervento] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Carico le tipologie di intervento configurate dall'utente
    promiseTipiIntervento
      .then(data => {
        setTipiIntervento(data);
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  const handleNuovoIntervento = () => {
    setNuovoIntervento(true);
    setInterventoAttivo({} as TipoIntervento);
  };

  const handleClickIntervento = (e: TipoIntervento) => {
    setInterventoAttivo(e);
    setNuovoIntervento(false);
  };

  const onDeleteRilevazione = (idRilevazione: string) => {
    const newIntervento = produce(interventoAttivo, iAttivo => {
      iAttivo.rilevazioniAssociate = iAttivo.rilevazioniAssociate.filter(ril => ril.idTipoRilevazione !== idRilevazione);
    });
    setInterventoAttivo(newIntervento);
  };
  const onModifyIntervento = (intervento: TipoIntervento) => {
    setInterventoAttivo(intervento);
  }
  const onConfirmIntervento = (intervento: TipoIntervento) => {
    setTipiIntervento(ts => {
      if(ts){
        const index = ts.findIndex(t => t.idTipoIntervento === intervento.idTipoIntervento)
        const newTs = [...ts.slice(0, index), intervento, ...ts.slice(index + 1)]
        return newTs
      } else return ts
    })
  }

  const onAddRilevazione = (rilevazione: TipoRilevazione) => {
    const newIntervento = produce(interventoAttivo, iAttivo => {
      iAttivo.rilevazioniAssociate.push(rilevazione);
    });
    setInterventoAttivo(newIntervento);
  };

  const Body = (
    <>
      <section className="section">
        <div className="columns">
          <div className="column is-one-quarter custom-card-light is-cm-fullheight">
            <span className="is-sidebar-listitem has-text-grey">
              Tipi intervento
            </span>
            {tipiIntervento === undefined && <LoadingSpinner />}

            {tipiIntervento &&
              tipiIntervento.map((d: TipoIntervento, i: number) => {
                return (
                  <span
                    className="is-sidebar-child-listitem"
                    key={i}
                    onClick={() => {
                      handleClickIntervento(d);
                    }}
                  >
                    {d.descrizione}
                  </span>
                );
              })}
            <span
              className="is-sidebar-child-listitem has-text-link"
              onClick={handleNuovoIntervento}
            >
              <FontAwesomeIcon
                className="is-sidebar-listitem-icon"
                icon={faPlus}
                size="sm"
              />
              Nuovo intervento
            </span>
          </div>
          {_.not(_.isEmpty(interventoAttivo)) && (
            <div className="column custom-card-light has-animation">
              <AnagraficaIntervento
                intervento={interventoAttivo}
                onDeleteRilevazione={onDeleteRilevazione}
                onAddRilevazione={onAddRilevazione}
                onModifyIntervento={onModifyIntervento}
                onConfirm={onConfirmIntervento}
              />
            </div>
          )}
          {nuovoIntervento && (
            <div className="column custom-card-light has-animation">
              <NuovoIntervento />
            </div>
          )}
        </div>
      </section>
    </>
  );
  return (
    <div id="outer-container">
      <Navbar
        outerId="outer-container"
        pageWrap="page-wrap"
        breadcrumbs={["Quaderno di campagna", "Interventi"]}
      />
      <div id="page-wrap">{Body}</div>
    </div>
  );
};

const NuovoIntervento: React.FC = () => {
  const [postTipoIntervento, tiPostato] = usePost<Omit<TipoIntervento, "idTipoIntervento">>(`tipointervento/${ID_UNITA}`);

  const defaultIntervento = {
    // idAzienda: ID_AZIENDA ?? "",
    descrizione: "",
    articoliIngresso: false,
    articoliUscita: false,
    utilizzoMacchine: false,
    utilizzoPersone: false,
    rilevazioni: false,
    registraBlockChain: false,
    tipiArticoloIngresso: [],
    tipiArticoloUscita: [],
    costoFisso: 0,
    modalita: "",
    rilevazioniAssociate: []
  };

  type LightTipoIntervento = Omit<TipoIntervento, "idTipoIntervento">;
  const [nuovoIntervento, setNuovoIntervento] = useState<LightTipoIntervento>(
    defaultIntervento
  );
  const handleTexboxChange = (fn: (v: TipoIntervento) => void) => {
    setNuovoIntervento(produce(nuovoIntervento, fn));
  };

  const [tipoArticoloIngresso, setTipoArticoloIngresso] = useState<{ label: string, value: string}[]>([])
  const [tipoArticoloUscita, setTipoArticoloUscita] = useState<{ label: string, value: string}[]>([])

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const _nuovoTipoIntervento = {
      idTipoIntervento: uuid.v4(),
      // idAzienda: ID_AZIENDA ?? "",
      descrizione: nuovoIntervento.descrizione,
      articoliIngresso: nuovoIntervento.articoliIngresso,
      articoliUscita: nuovoIntervento.articoliUscita,
      utilizzoMacchine: nuovoIntervento.utilizzoMacchine,
      utilizzoPersone: nuovoIntervento.utilizzoPersone,
      rilevazioni: nuovoIntervento.rilevazioni,
      registraBlockChain: nuovoIntervento.registraBlockChain,
      tipiArticoloIngresso: tipoArticoloIngresso.map(a => a.value),
      tipiArticoloUscita: tipoArticoloUscita.map(a => a.value),
      costoFisso: nuovoIntervento.costoFisso,
      modalita: nuovoIntervento.modalita,
      rilevazioniAssociate: []
    };

    setNuovoIntervento(_nuovoTipoIntervento);
    postTipoIntervento(_nuovoTipoIntervento);
  };

  return (
    <>
      <ErrorModal prom={tiPostato} />
      <form onSubmit={handleFormSubmit}>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicInput
                label="Descrizione"
                value={nuovoIntervento.descrizione || ""}
                readOnly={false}
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.descrizione = e.target.value;
                  });
                }}
              />
            </div>

            <div className="field is-horizontal">
              <BasicInput
                label="Costo fisso"
                value={nuovoIntervento.costoFisso || ""}
                readOnly={false}
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.costoFisso = Number(e.target.value);
                  });
                }}
              />
            </div>

            <div className="field is-horizontal">
              <BasicDropdown
                label={"Modalità"}
                defaultValue="Seleziona modalità"
                values={["Standard", "Fitosanitari"]}
                onSelection={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.modalita = e.target.value as ModalitaIntervento;
                  });
                }}
              />
            </div>
            
            <p>Filtro tipo articolo ingresso</p>
            <MultiSelect
              options={
                [
                  { label: "Materia prima", value: "MateriaPrima" },
                  { label: "Semilavorato", value: "Semilavorato" },
                  { label: "Prodotto finito", value: "ProdottoFinito" },
                  { label: "Imballo", value: "Imballo" },
                  { label: "Fitosanitario", value: "Fitosanitario" },
                  { label: "Raffinatura", value: "Raffinatura" }
                ]
              }
              value={tipoArticoloIngresso}
              onChange={setTipoArticoloIngresso}
              labelledBy={"Tipo articolo"}
              disableSearch={true}
              hasSelectAll={false}
              overrideStrings={{
                "selectSomeItems": "Seleziona filtro",
                "allItemsAreSelected": "Tutti",
                "selectAll": "Seleziona tutto",
                "search": "Cerca"
              }}
            />
            <br/>
            <p>Filtro tipo articolo uscita</p>
            <MultiSelect
              options={
                [
                  { label: "Materia prima", value: "MateriaPrima" },
                  { label: "Semilavorato", value: "Semilavorato" },
                  { label: "Prodotto finito", value: "ProdottoFinito" },
                  { label: "Imballo", value: "Imballo" },
                  { label: "Fitosanitario", value: "Fitosanitario" },
                  { label: "Raffinatura", value: "Raffinatura" }
                ]
              }
              value={tipoArticoloUscita}
              onChange={setTipoArticoloUscita}
              labelledBy={"Tipo articolo"}
              disableSearch={true}
              hasSelectAll={false}
              overrideStrings={{
                "selectSomeItems": "Seleziona filtro",
                "allItemsAreSelected": "Tutti",
                "selectAll": "Seleziona tutto",
                "search": "Cerca"
              }}
            />
          </div>
        </div>

        <br />
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicCheckbox
                label="Articoli in ingresso"
                checked={nuovoIntervento.articoliIngresso}
                clsName="has-fixed-width"
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.articoliIngresso = e.target.checked;
                  });
                }}
              />
              <BasicCheckbox
                label="Articoli in uscita"
                checked={nuovoIntervento.articoliUscita}
                clsName="has-fixed-width"
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.articoliUscita = e.target.checked;
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicCheckbox
                label="Macchine"
                checked={nuovoIntervento.utilizzoMacchine}
                clsName="has-fixed-width"
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.utilizzoMacchine = e.target.checked;
                  });
                }}
              />
              <BasicCheckbox
                label="Persone"
                checked={nuovoIntervento.utilizzoPersone}
                clsName="has-fixed-width"
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.utilizzoPersone = e.target.checked;
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicCheckbox
                label="Rilevazioni"
                checked={nuovoIntervento.rilevazioni}
                clsName="has-fixed-width"
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.rilevazioni = e.target.checked;
                  });
                }}
              />
              <BasicCheckbox
                label="Registra Blockchain"
                checked={false}
                clsName="has-fixed-width"
                onChange={e => {
                  handleTexboxChange((p: TipoIntervento) => {
                    p.registraBlockChain = e.target.checked;
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Salva tipo intervento
            </button>
          </p>
        </div>
      </form>
    </>
  );
};

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      L'intervento selezionato verrà eliminato permanentemente.
    </Modali.Modal>
  );
};

const promiseTipiRilevazione = fetchObjects(`tiporilevazione/${ID_UNITA}`);

const AnagraficaIntervento: React.FC<{
  intervento: TipoIntervento;
  onDeleteRilevazione: (idRilevazione: string) => void;
  onAddRilevazione: (rilevazione: TipoRilevazione) => void;
  onModifyIntervento: (intervento : TipoIntervento) => void;
  onConfirm: (intervento: TipoIntervento) => void;
}> = ({ intervento, onDeleteRilevazione, onAddRilevazione, onModifyIntervento, onConfirm }) => {
  const handleToggleIntervento = (key : "articoliIngresso" | "articoliUscita" | "utilizzoMacchine" | "utilizzoPersone" | "rilevazioni" | "registraBlockChain") => () => {
    onModifyIntervento(produce(intervento, i => {
      i[key] = !i[key];
    }));
  }

  const handleChangeModalita = (mod : ModalitaIntervento) => {
    onModifyIntervento(produce(intervento, i => {
      i.modalita = mod;
    }));
  }
  const handleChangeDescrizione = (descr : string) => {
    onModifyIntervento(produce(intervento, i => {
      i.descrizione = descr;
    }));
  }
  const handleChangeCosto = (costo : string) => {
    const costonum = costo === "" ? 0 : Number(costo);
    onModifyIntervento(produce(intervento, i => {
      i.costoFisso = costonum;
    }));
  }

  const handleChangeTipoArticoloIngresso = (e: { label: string, value: string }[]) => {

    onModifyIntervento(produce(intervento, i => {
      i.tipiArticoloIngresso = e.map(a => a.value)
    }))
  }

  const handleChangeTipoArticoloUscita = (e: { label: string, value: string }[]) => {

    onModifyIntervento(produce(intervento, i => {
      i.tipiArticoloUscita = e.map(a => a.value)
    }))
  }

  const fieldsetAnagrafica = (
    <>
      <fieldset>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicInput
                label="Descrizione"
                value={intervento.descrizione ?? ""}
                readOnly={false}
                onChange={e => {handleChangeDescrizione(e.target.value)}}
              />
            </div>

            <div className="field is-horizontal">
              <BasicInput
                label="Costo fisso"
                value={intervento.costoFisso ?? ""}
                readOnly={false}
                onChange={e => { handleChangeCosto(e.target.value)}}
              />
            </div>

            <div className="field is-horizontal">
              <BasicDropdown
                label={"Modalità"}
                defaultValue={intervento.modalita}
                values={["Standard", "Fitosanitari"]}
                onSelection={(e) => { handleChangeModalita(e.target.value as ModalitaIntervento)}}
              />
            </div>

            <p>Filtro tipo articolo ingresso</p>
            <MultiSelect
              options={
                [
                  { label: "Materia prima", value: "MateriaPrima" },
                  { label: "Semilavorato", value: "Semilavorato" },
                  { label: "Prodotto finito", value: "ProdottoFinito" },
                  { label: "Imballo", value: "Imballo" },
                  { label: "Fitosanitario", value: "Fitosanitario" },
                  { label: "Raffinatura", value: "Raffinatura" }
                ]
              }
              value={intervento.tipiArticoloIngresso?.map((i: string) => { return { label: i, value: i }})}
              onChange={handleChangeTipoArticoloIngresso}
              labelledBy={"Tipo articolo"}
              disableSearch={true}
              hasSelectAll={false}
              overrideStrings={{
                "selectSomeItems": "Seleziona filtro",
                "allItemsAreSelected": "Tutti",
                "selectAll": "Seleziona tutto",
                "search": "Cerca"
              }}
            />
            <br/>
            <p>Filtro tipo articolo uscita</p>
            <MultiSelect
              options={
                [
                  { label: "Materia prima", value: "MateriaPrima" },
                  { label: "Semilavorato", value: "Semilavorato" },
                  { label: "Prodotto finito", value: "ProdottoFinito" },
                  { label: "Imballo", value: "Imballo" },
                  { label: "Fitosanitario", value: "Fitosanitario" },
                  { label: "Raffinatura", value: "Raffinatura" }
                ]
              }
              value={intervento.tipiArticoloUscita?.map((i: string) => { return { label: i, value: i }})}
              onChange={handleChangeTipoArticoloUscita}
              labelledBy={"Tipo articolo"}
              disableSearch={true}
              hasSelectAll={false}
              overrideStrings={{
                "selectSomeItems": "Seleziona filtro",
                "allItemsAreSelected": "Tutti",
                "selectAll": "Seleziona tutto",
                "search": "Cerca"
              }}
            />
          </div>
        </div>
        <br />
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicCheckbox
                label="Articoli in ingresso"
                clsName="has-fixed-width"
                checked={intervento.articoliIngresso}
                onChange={handleToggleIntervento("articoliIngresso")}
              />
              <BasicCheckbox
                label="Articoli in uscita"
                clsName="has-fixed-width"
                checked={intervento.articoliUscita}
                onChange={handleToggleIntervento("articoliUscita")}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicCheckbox
                label="Macchine"
                clsName="has-fixed-width"
                checked={intervento.utilizzoMacchine}
                onChange={handleToggleIntervento("utilizzoMacchine")}
              />
              <BasicCheckbox
                label="Persone"
                clsName="has-fixed-width"
                checked={intervento.utilizzoPersone}
                onChange={handleToggleIntervento("utilizzoPersone")}
              />
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-horizontal">
              <BasicCheckbox
                label="Rilevazioni"
                clsName="has-fixed-width"
                checked={intervento.rilevazioni}
                onChange={handleToggleIntervento("rilevazioni")}
              />
              <BasicCheckbox
                label="Blockchain"
                clsName="has-fixed-width"
                checked={false}
                onChange={handleToggleIntervento("registraBlockChain")}
              />
            </div>
          </div>
        </div>
      </fieldset>
      <br />
      <br />
    </>
  );

  const [newRow, setNewRow] = useState(false);

  const [localTipiRilevazioni, setLocalTipiRilevazioni] = useState<TipoRilevazione[] | undefined>();
  const [tipiRilevazioni, setTipiRilevazioni] = useState<TipoRilevazione[] | undefined>();

  const [postTipoIntervento, tipoInterventoPostato] = usePost<TipoIntervento>(`tipointervento/${ID_UNITA}`);

  useEffect(() => {
    // Carico le tipologie di rilevazioni per intervento configurate dall'utente
    promiseTipiRilevazione
      .then(data => {
        setTipiRilevazioni(data);
        filterRilevazioni(data);
      })
  }, []);

  useEffect(() => {
    filterRilevazioni();
  }, [tipiRilevazioni, intervento.rilevazioniAssociate]);

  const filterRilevazioni = (rils? : TipoRilevazione[]) => {
    const rilsVere = rils ?? tipiRilevazioni;
    setLocalTipiRilevazioni(
      rilsVere?.filter(
        x => x.tag === "Intervento" && !intervento.rilevazioniAssociate.some(ril => ril.idTipoRilevazione === x.idTipoRilevazione)
      )
    );
  };

  const handlePulsanteNuovo = () => {
    setNewRow(true);
  };

  const pulsantiTabella = (
    <div className="button-area">
      <div className="column text-is-aligned-right">
        <button
          className="button is-primary is-white-button"
          onClick={() => {}}
        >
          Elimina
        </button>

        <button
          className="button is-primary is-white-button"
          onClick={handlePulsanteNuovo}
        >
          Nuovo
        </button>
      </div>
    </div>
  );

  const deleteIntervento = () => {
    if(intervento.idTipoIntervento) {
      deleteObject(`tipointervento/${intervento.idTipoIntervento}`)
      toggleDeleteModal()
    }
  }
  
  const [tipoRilevazioneAttiva, setTipoRilevazioneAttiva] = useState<TipoRilevazione>();

  const [tipoRilevazioneAttivaDestra,setTipoRilevazioneAttivaDestra] = useState<TipoRilevazione>();

  function handleAggiungiRilevazione() {
    if (tipoRilevazioneAttiva !== undefined) {
      onAddRilevazione(tipoRilevazioneAttiva);
      setTipoRilevazioneAttiva(undefined);
    }
  }
  function handleRimuoviRilevazione() {
    if (tipoRilevazioneAttivaDestra && tipoRilevazioneAttivaDestra.idTipoRilevazione) {
      onDeleteRilevazione(tipoRilevazioneAttivaDestra.idTipoRilevazione)
    }
  }

  function submitForm() {
    postTipoIntervento(intervento);
    onConfirm(intervento);
  }

  const tabellaRilevazioni = (
    <>
      <div className="columns">
        <div className="column has-border">
          <p className="title is-5">Rilevazioni disponibili</p>
          {localTipiRilevazioni?.map((val: TipoRilevazione, i) => {
            return (
              <p key={i}>
                <span
                  className={`tag is-medium ${tipoRilevazioneAttiva === val ? "is-info" : "is-light"}`}
                  onClick={() => setTipoRilevazioneAttiva(val)}
                >
                  {val.descrizione}
                </span>
              </p>
            );
          })}
        </div>
        <div className="column is-2">

          <button style={{ marginLeft: '0'}} type="button" className="button is-fullwidth is-success" onClick={() => handleAggiungiRilevazione()} >
            <span>AGGIUNGI</span>
            <span className="icon">
              <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable is-icon-aligned-right" icon={faChevronRight} />
            </span>
          </button>

          <br />
          <br />

          <button style={{ marginLeft: '0'}} type="button" className="button is-fullwidth is-danger" onClick={() => handleRimuoviRilevazione()} >
            <span className="icon">
              <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable is-icon-aligned-right" icon={faChevronLeft} />
            </span>
            <span>RIMUOVI</span>
          </button>

        </div>
        <div className="column has-border">
          <p className="title is-5">Rilevazioni associate</p>
          {intervento.rilevazioniAssociate.map((val, i) => {
            return (
              <p key={i}>
                <span
                  className={`tag is-medium ${
                    tipoRilevazioneAttivaDestra === val
                      ? "is-info"
                      : "is-light"
                  }`}
                  onClick={() => setTipoRilevazioneAttivaDestra(val)}
                >
                  {val.descrizione}
                </span>
              </p>
            );
          })}
        </div>
      </div>
    </>
  );

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deleteIntervento()}
      />,
    ],
  });

  return (
    <>
      <ErrorModal prom={tipoInterventoPostato} />
      <form>
        {fieldsetAnagrafica}
        {intervento.rilevazioni && tabellaRilevazioni}
        <div className="field is-right">
          <p className="control">
            <button type="button" onClick={submitForm} className="button is-success">
              Conferma modifiche
            </button>
          </p>
        </div>
        <div className="field is-right">
          <p className="control">
            <button type="button" onClick={_ => toggleDeleteModal()} className="button is-danger">
              Elimina
            </button>
          </p>
        </div>
        <ModalDelete modal={deleteModal} />
      </form>
    </>
  );
};

export default Interventi;
