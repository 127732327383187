import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import CustomTable, { TableSubject } from '../CustomTable/CustomTable'
import { fetchObjects, ID_AZIENDA, ID_UNITA, BasicInput, postObject, BasicCheckbox, DateInput, BasicDropdown } from '../../Utilities/SharedComponent'
import { RepartoModel, UnitaProduttiva } from '../Azienda/Model'
import { Articolo } from '../Azienda/Coltura/Model'
import Modali, { useModali } from "modali"
import { restartable } from '../../Utilities/Async'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import * as _ from 'ramda'

const promiseUnitaProduttiva = () => fetchObjects(`${ID_AZIENDA}/unitaproduttive?idUnita=${ID_UNITA}`)
const promiseUnitaProduttive = () => fetchObjects(`${ID_AZIENDA}/unitaproduttive`)
const promiseArticoli = (cod: string) => restartable(() => fetchObjects(`${ID_AZIENDA}/${cod}/articoli`))

const ModalModificaArticolo: React.FC<{
  modal: Modali.ModalHook
  articolo: Articolo
  onPost: () => void
}> = ({ modal, articolo, onPost }) => {
  return (
    <Modali.Modal {...modal}>
      <ArticoloModifica articolo={articolo} onPost={onPost} />
    </Modali.Modal>
  )
}

interface CoeffPerStanza {
  key?: number
  idReparto: string
  coefficiente: number
}
interface ModificaArticolo { 
  codiceInternoArticolo: string
  coefficienteConversione: number
  coeffPerStanza: CoeffPerStanza[]
  dataInizio: string
  dataFine: string
}

const ArticoloModifica: React.FC<{ articolo: Articolo, onPost: () => void }> = ({ articolo, onPost }) => {
  
  const { addToast } = useToasts()

  const [articoloModificato, setArticoloModificato] = useState<Articolo>(articolo)
  const [aggiornaPregresso, setAggiornaPregresso] = useState(false)
  const [coefficientePregresso, setCoefficientePregresso] = useState(articoloModificato.coefficienteConversione)

  const [dataInizio, setDataInizio] = useState("")
  const [dataFine, setDataFine] = useState("")

  const [reparti, setReparti] = useState<RepartoModel[]>([])
  useEffect(() => {
    promiseUnitaProduttiva()
      .then((data: UnitaProduttiva[]) => { 
        if (data.length !== 0) {
          const _reparti = data[0].reparti
          setReparti(_reparti)
        }
      })
  }, [])

  const [coeffPerStanza, setCoeffPerStanza] = useState<CoeffPerStanza[]>([{
    key: 0, idReparto: "", coefficiente: coefficientePregresso
  }])

  const [err, setErr] = useState(false)
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if (aggiornaPregresso) {
      
      const goahead = _.all(a => a !== "")(coeffPerStanza.map(a => a.idReparto))

      if (goahead) {

        const toPost: ModificaArticolo = {
          codiceInternoArticolo: articolo.codiceInternoArticolo,
          coefficienteConversione: coefficientePregresso,
          coeffPerStanza: coeffPerStanza.map(c => ({ idReparto: c.idReparto, coefficiente: c.coefficiente })),
          dataInizio: dataInizio ?? moment(),
          dataFine: dataFine ?? moment()
        }
  
        postObject(`${ID_UNITA}/articoli/modifica?pregresso=true`, toPost)
        onPost()
        addToast('Coefficiente aggiornato correttamente', { appearance: 'success', autoDismiss: true })
      } else setErr(true)

      
    } else {
      const toPost = {
        codiceInternoArticolo: articolo.codiceInternoArticolo,
        coefficienteConversione: articoloModificato.coefficienteConversione,
        coeffPerStanza: []
      }
      postObject(`${ID_UNITA}/articoli/modifica?pregresso=false`, toPost)
      onPost()
      addToast('Coefficiente aggiornato correttamente', { appearance: 'success', autoDismiss: true })
    }
  }

  const handleChangeCoefficiente = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = Number(e.target.value)
    setArticoloModificato({...articoloModificato, coefficienteConversione: n })
  }

  const addRowCoeffPerStanza = () => {
    const last = _.last(coeffPerStanza)
    if (last) {
      setCoeffPerStanza([...coeffPerStanza, {
        key: last.key! + 1,
        idReparto: "",
        coefficiente: coefficientePregresso
      }])
    }
  }

  const handleCoeffChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    let updatedCoeff = [...coeffPerStanza]
    for (let item of updatedCoeff) {
      if (item.key === i) {
        item.coefficiente = Number(e.target.value)
      }
    }
    setCoeffPerStanza(updatedCoeff)
  }

  const handleCoeffSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>, i: number) => {
    let updatedCoeff = [...coeffPerStanza]
    const rep = reparti.find(r => r.nomeReparto === e.target.value)
    if (rep) {
      for (let item of updatedCoeff) {
        if (item.key === i) {
          item.idReparto = rep.idReparto ?? ""
          setErr(false)
        }
      }
      setCoeffPerStanza(updatedCoeff)
    }
  }

  const body = (
    <form onSubmit={submitForm}>
      <BasicInput 
        label="Codice" 
        value={articolo.codiceArticolo} 
        readOnly={true} 
        onChange={() => { }} 
      />

      <BasicInput 
        label="Descrizione" 
        value={articolo.descrizione} 
        readOnly={true} 
        onChange={() => { }} 
      />

      <BasicInput 
        label="UM" 
        value={articolo.unitaDiMisuraVendita} 
        readOnly={true} 
        onChange={() => { }} 
      />

      <BasicInput 
        label="Coeff. conversione" 
        value={articoloModificato.coefficienteConversione} 
        readOnly={false} 
        type="number"
        onFocus={e => e.target.select()}
        onChange={handleChangeCoefficiente} 
      />
      
      <h1 style={{ fontWeight: 700, fontSize: '1.2rem', color: '#4a4a4a' }}>Rettifica coefficiente</h1>
      <hr style={{ marginTop: '5px' }} />

      <BasicCheckbox
        label=""
        checked={aggiornaPregresso}
        onChange={e => setAggiornaPregresso(!aggiornaPregresso)}
      />
      {aggiornaPregresso && (
        <>
          <DateInput
            label="Dal"
            defaultValue={dataInizio}
            onDayChange={(e) => setDataInizio(e.toISOString() ) } 
          />
    
          <DateInput
            label="Al"
            defaultValue={dataFine}
            onDayChange={(e) => setDataFine(e.toISOString() ) } 
          />

          <button className="button is-pulled-right" type="button" onClick={_ => addRowCoeffPerStanza()}>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </button>
          
          <br/><br/>

          {coeffPerStanza.map((obj, i) => (
            <div className="field is-horizontal" key={i}>
              <div className="field-body">
                <div className="field" style={{ zIndex: 0 }}>
                  <BasicDropdown 
                    defaultValue="Seleziona reparto"
                    values={reparti.map(r => r.nomeReparto)}
                    onSelection={e => handleCoeffSelectionChange(e, i)}
                  />
                </div>

                <div className="field">
                  <BasicInput 
                    value={coeffPerStanza[i].coefficiente} 
                    readOnly={false} 
                    type="number"
                    onChange={e => handleCoeffChange(e, i)} 
                  />
                </div>
              </div>
            </div>
          ))}

        </>
      )}

      {err && (
        <small style={{ marginRight: 10 }} className="has-text-danger">
          Verificare che tutte le righe aggiunte abbiano un reparto selezionato
        </small>
      )}
      <div className="field is-right">
        <p className="control">
          <button type="submit" className="button is-success">
            Applica
          </button>
        </p>
      </div>
    </form>
  )

  return body
}

const Articoli: React.FC = () => {

  // const [upCod, setUpCod] = useState("")
  const [onpost, setOnpost] = useState(false)
  const [modificaArticoloModal, setModificaArticoloModal] = useModali({
    title: "Modifica articolo",
    animated: true,
    closeButton: true
  })
  
  const [articoli, setArticoli] = useState<Articolo[]>([])
  const [filteredArticoli, setFilteredArticoli] = useState<Articolo[]>([])

  useEffect(() => {
    fetchArticoli()
  }, [])

  async function fetchArticoli() {
    const ups: UnitaProduttiva[] = await promiseUnitaProduttive()

    const cod = ups.find(x => x.idUnitaProduttiva === ID_UNITA)?.codiceUnitaProduttiva

    if (cod) {
      promiseArticoli(cod)
        .then((art: Articolo[]) => {
          setArticoli(art)
          setFilteredArticoli(art)
        })
    }
  }

  useEffect(() => {
    fetchArticoli()
  }, [onpost])

  useEffect(() => {
    handleChangeTipoProdotto(tipoProdottoSelezionato)
  }, [articoli])

  const [articoloSelezionato, setArticoloSelezionato] = useState<Articolo>({} as Articolo)
 
  const handleClickArticolo = (e: TableSubject) => {
    const tmp = e as Articolo
    if (tmp) {
      setArticoloSelezionato(tmp)
      setModificaArticoloModal()
    }
  }

  const tipoProdotto = ["Tutti","Raffinatura", "Fitosanitario", "Imballo", "ProdottoFinito", "Semilavorato", "MateriaPrima"]

  const [tipoProdottoSelezionato, setTipoProdottoSelezionato] = useState("Tutti")

  const handleChangeTipoProdotto = (t: string) => {
    setTipoProdottoSelezionato(t)
    if (t === "Tutti") {
      setFilteredArticoli(articoli)
    } else setFilteredArticoli(articoli.filter(a => a.tipoArticolo === t))
  }

  const body = (
    <section className="section">
      <div className="columns">
        <div className="column is-full custom-card-light is-cm-fullheight">

          {articoli.length > 0 && (
            <div className="control">
              {tipoProdotto.map((t, i) => (
                <label key={i} className="radio">
                  <input checked={t === tipoProdottoSelezionato} type="radio" name="tipo_prodotto" style={{ marginRight: '5px' }} onChange={_ => handleChangeTipoProdotto(t)} />
                  {t}
                </label>
              ))}
            </div>
          )}
          <CustomTable
            classes="tablePersonale"
            headers={["codiceArticolo", "descrizione", "unitaDiMisuraVendita", "coefficienteConversione"]}
            rows={filteredArticoli}
            title="Tabella articoli"
            footer={false}
            onUpdate={handleClickArticolo}
          />
        </div>

        <ModalModificaArticolo modal={modificaArticoloModal} articolo={articoloSelezionato} onPost={() => setOnpost(!onpost)} />
      </div>
    </section>
  )

  return (
    <div id="outer-container">
      <Navbar
        outerId="outer-container"
        pageWrap="page-wrap"
        breadcrumbs={["Quaderno di campagna", "Persone"]}
      />
      <div id="page-wrap">{body}</div>
    </div>
  )
}

export default Articoli