import moment from "moment";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SentenceCase, fetchObjects, ID_UNITA } from "../../Utilities/SharedComponent";
import "./CustomTable.css";
import {
  faCircle,
  faPencilAlt,
  faTrash,
  faPlus,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import { Persona } from "../Azienda/Coltura/Model";

//N: temporary hack to allow more table elements;
// tslint:disable-next-line: no-any
export type TableElement = number | boolean | string | TableSubject | any;

export interface TableSubject {
  [key: string]: TableElement;
}

const walkSegments = (
  el: TableSubject,
  [cur, ...others]: string[]
): TableElement => {
  if (!others || others.length === 0) {
    return el[cur];
  }

  // N: it's safe and valid, but the types don't quite line up
  // tslint:disable-next-line: no-any
  return walkSegments(el[cur], others);
};

const getKey = (header: Header) =>
  typeof header === "object" ? header.key : header;

// tslint:disable-next-line: no-any
function isValidType(x: any): x is null | string | boolean | number {
  return (
    x === null ||
    typeof x === "string" ||
    typeof x === "boolean" ||
    typeof x === "number"
  );
}

// tslint:disable-next-line: no-any
function isNumber(x: any): x is number {
  return typeof x === "number";
}

type Header = string | { key: string; value: string; items?: string[] };
// type Attribute = "currency" | "status"

const ActionButtons: React.FC<{
  onCreate?: () => void;
  onUpdate: (value: TableSubject) => void;
  onDelete?: (value: TableSubject) => void;
  selectedElement: TableSubject;
}> = ({ onCreate, onDelete, onUpdate, selectedElement }) => {
  return (
    <>
      <div className="column text-is-aligned-right">
        {onDelete && (
          <button
            className="button is-primary is-white-button"
            onClick={() => {
              _.not(_.isEmpty(selectedElement)) && onDelete(selectedElement);
            }}
          >
            <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faTrash} />
            Elimina
          </button>
        )}
        <button
          className="button is-primary is-white-button"
          onClick={() => {
            _.not(_.isEmpty(selectedElement)) && onUpdate(selectedElement);
          }}
        >
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faPencilAlt} />
          Modifica
        </button>
        {onCreate && (
          <button
            className="button is-primary is-white-button"
            onClick={onCreate}
          >
            <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faPlus} />
            Nuovo
          </button>
        )}
      </div>
    </>
  );
};

const ApplyButton: React.FC<{ onApply: () => void; }> = ({ onApply }) => {
  return (
    <div className="column text-is-aligned-right">
      <button className="button is-primary is-white-button" onClick={onApply}>
        <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faCheck} />
        Applica
      </button>
    </div>
  );
};

type EditableItems = {
  [index: string]: any;
}[]

const fetchPersone = fetchObjects(`persone/${ID_UNITA}`)

const CustomTable: React.FC<{
  rows: TableSubject[];
  headers: Header[];
  title: string;
  footer?: boolean;
  editable?: boolean;
  classes?: string;
  onCreate?: () => void;
  onUpdate: (value: TableSubject) => void;
  onDelete?: (value: TableSubject) => void;
  onApply?: (items : EditableItems) => void;
}> = ({
  rows,
  headers,
  title,
  footer,
  onCreate,
  onDelete,
  onUpdate,
  editable,
  classes,
  onApply
}) => {
  const [selectedElement, setSelectedElement] = useState({} as TableSubject);
  footer = footer ?? true;
  editable = editable ?? false;
  const tableList = rows.map(row => {
    const pairs : [string, any][] = headers.map(header => {
      const key = getKey(header);
      return [key, row[key]]
    });
    const res = _.fromPairs(pairs);
    return res;
  })
  
  const [tableState, setTableState] = useState(tableList);
  
  const handleInputChange = (row : number, col : string) => (e : any) => {
    const newState = produce(tableState, state => {
      state[row][col] = e.target.value;
    });
    setTableState(newState);
  }

  const [persone, setPersone] = useState<Persona[]>([])
  useEffect(() => {
    fetchPersone
      .then(res => setPersone(res))
      .catch(e => console.log(e))
  }, [])
  
  function setBodyCustomAttributes(
    content: string | number,
    header: Header,
    rowIndex? : number
  ): JSX.Element {
    let body = content.toString();
    if (typeof header === "string") return <>{body}</>;
    const attribute = header.value;
    const items = header.items
    
    if (attribute === "area" && typeof content === "number") {
      return <>{body + " mq"}</>;
    }
  
    function numberToMinutes(n: number) {
      const h = Math.floor(n / 60)
      const m = n % 60

      return `${h}:${Math.round(m)}`
    }

    if (attribute === "time" && typeof content === "number") {
      // const t = moment.utc().startOf('day').add({ minutes: Number(body) }).format('H:mm')

      const _t = numberToMinutes(Number(body))

      return <>{_t}</>
    }
    
    if (attribute === "currency" && typeof content === "number") {
      body = (Math.round(content * 100) / 100).toFixed(2).toString();
      return <>{body + " €"}</>;
    }
    
    if (attribute === "editable" && rowIndex !== undefined ) {
      return <input className="editable-input" type="text" value={tableState[rowIndex][getKey(header)]} onChange={handleInputChange(rowIndex, getKey(header))}/>;
    }

    if (attribute === "persona" && rowIndex !== undefined) {
      return <>{
        persone.find(p => p.idPersona === body) ? `${persone.find(p => p.idPersona === body)?.codicePersona} - ${persone.find(p => p.idPersona === body)?.nomePersona}` : ""
      }</>
    }

    if (attribute === "fixednumber" && typeof content === "number") {
      return <>{Number(body).toFixed(1).toString()}</>
    }

    if (attribute === "selectable" && rowIndex !== undefined ) {
      return (
        <select onChange={handleInputChange(rowIndex, getKey(header))}>
          {items?.map(item => (
            <option value={item}>{item}</option>
          )
          )}
        </select>
      )
    }

    if (attribute === "status") {
      const attribClass =
        "status-" + body.toLocaleLowerCase().replace(" ", "-");
      return (
        <span>
          <span className={"status-list " + attribClass}>
            <FontAwesomeIcon
              className="is-sidebar-listitem-icon"
              icon={faCircle}
            />
          </span>
          <span className="status-text">{SentenceCase(body)}</span>
        </span>
      );
    }
    return <>{body}</>;
  }

  const tableClasses = 
    `parent ${classes === undefined ? "" : classes }`

  if (rows.length === 0)
    return (
      <div className={tableClasses}>
        <div className="title-area">
          <span className="title is-5 has-grey-color">{title}</span>
        </div>
        <div className="button-area">
          <div className="column text-is-aligned-right">
            <button
              className="button is-primary is-white-button"
              onClick={onCreate}>
              <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faPlus} />
              Nuovo
            </button>
          </div>
        </div>
        <div className="table-container has-animation">
          <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                {headers.map((header, i) => {
                  const key = typeof header === "object" ? header.key : header;
                  const lastSegment = _.last(key.split(".")) ?? "";
                  return (
                    <th key={i} className="table-cell-dark has-grey-color">
                      <abbr title={SentenceCase(lastSegment)}>
                        {" "}
                        {SentenceCase(lastSegment)}
                      </abbr>
                    </th>
                  );
                })}
              </tr>
            </thead>
          </table>
          <h1 className="has-text-centered has-text-grey">
            Nessuna informazione disponibile
          </h1>
        </div>
      </div>
    );

  return (
    <div className={tableClasses}>
      <div className="title-area">
        <span className="title is-5 has-grey-color">{title}</span>
      </div>
      <div className="button-area">
        {!editable ? (
          <ActionButtons
            onCreate={onCreate}
            onDelete={onDelete}
            onUpdate={onUpdate}
            selectedElement={selectedElement}
          />
        ) : (
          <ApplyButton onApply={() => onApply?.(tableState)} />
        )}
      </div>
      <div className="table-container has-animation">
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              {headers.map((header, i) => {
                const key = getKey(header);
                const lastSegment = _.last(key.split(".")) ?? "";
                return (
                  <th key={i} className="table-cell-dark has-grey-color">
                    <abbr title={SentenceCase(lastSegment)}>
                      {" "}
                      {SentenceCase(lastSegment) === "Id Persona" ? "Nome persona" : SentenceCase(lastSegment)}
                    </abbr>
                  </th>
                );
              })}
            </tr>
          </thead>
          {footer && (
            <tfoot>
              <tr>
                {headers.map((header: Header, index: number) => {
                  const key = getKey(header);

                  const time = _.reduce((a, b) => a + Number(b[key]), 0, rows)

                  return isNumber(rows[0][key]) ? (
                    <td className="table-cell-dark text-is-aligned-right has-grey-color ">
                      {setBodyCustomAttributes(
                        time,
                        header
                      )}
                    </td>
                  ) : (
                    <td className="table-cell-dark has-grey-color"></td>
                  );
                })}
              </tr>
            </tfoot>
          )}
          <tbody>
            {rows.map((el, i) => (
              <tr
                className="has-animation"
                onDoubleClick={() => {
                  _.not(_.isEmpty(selectedElement)) &&
                    onUpdate(selectedElement);
                }}
              >
                {headers.map(header => {
                  const key = typeof header === "object" ? header.key : header;
                  const element = walkSegments(el, key.split(".")) ?? "";
                  if (isValidType(element) && element !== null) {
                    const date = moment(element as string, moment.ISO_8601);
                    const body =
                      typeof element === "string" && date.isValid()
                        ? date.format("DD/MM/YYYY")
                        : element;
                    const alignment =
                      typeof body === "number"
                        ? "has-text-right"
                        : "has-text-left";
                    const active = _.equals(el, selectedElement)
                      ? "isActive"
                      : "";
                    const className = `${alignment} ${active}`;

                    //DF setting custom attributes to body
                    
                    const res = typeof body === "boolean" ? (
                      <td
                        className={className}
                        onClick={() => {
                          setSelectedElement(el);
                        }}
                      >
                        <input type="checkbox" readOnly checked={body} />
                      </td>
                    ) : (
                      <td
                        className={className}
                        onClick={() => {
                          setSelectedElement(el);
                        }}
                      >
                        {setBodyCustomAttributes(body, header, i)}
                      </td>
                    );
                    return res;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomTable;
