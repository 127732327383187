import { navigate } from "hookrouter"
import React, { useState, useEffect } from "react"
import logo from "../../Assets/logo-zeuslab.png"
import "./LoginForm.css"
import { AllActions } from "../../Reducers/Root"
import { useDispatch } from "../../Utilities/SharedHooks/State"
import { parseJwt, BASE_URL } from "../../Utilities/SharedComponent"

interface KeycloakResponse {
  access_token: string
  expires_in: number
  "not-before-policy": number
  refresh_expires_in: number
  refresh_token: string
  scope: string
  session_state: string
  token_type: string
}

interface UserData {
  nomeAzienda: string
  username: string
  password: string
  rememberMe: boolean
}

export interface LoginRes {
  azienda?: string
  unitaProduttiva?: string
}

const LoginForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState("")
  const [user, setUser] = useState<UserData>({
    nomeAzienda: "",
    username: "",
    password: "",
    rememberMe: false,
  })

  const dispatch = useDispatch<AllActions>()

  const validateForm = () => {
    return user.username.length > 0 && user.password.length > 0
  }

  useEffect(() => {
    const rememberMeData = localStorage.getItem("rememberMeData")
    if (rememberMeData != null && rememberMeData !== "") {
      const data: UserData = JSON.parse(rememberMeData)
      if (data.rememberMe) {
        setUser(data)
      }
    }
  }, [])

  const handleNomeAziendaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nomeAzienda: string = e.target.value
    setUser({ ...user, nomeAzienda })
  }

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username: string = e.target.value
    setUser({ ...user, username })
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password: string = e.target.value
    setUser({ ...user, password })
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    let urlencoded = new URLSearchParams()
    urlencoded.append("client_id", "quadernodicampagna")
    urlencoded.append("username", user.username)
    urlencoded.append("password", user.password)
    urlencoded.append("grant_type", "password")
    urlencoded.append("scope", "profile")
    urlencoded.append("response_type", "code")

    const isdev = window.location.hostname.includes("staging")
    const localhost = window.location.hostname.startsWith("localhost")

    let url = ""

    if (localhost || isdev) {
      // STAGING
      // urlencoded.append("client_secret", "47f838a3-e119-4806-a2ed-50e3316536ab")
      url = `https://auth.staging.futurelab.cloud/auth/realms/${user.nomeAzienda}/protocol/openid-connect/token`
    } else {
      // PRODUCTION
      // urlencoded.append("client_secret", "7954654d-fb11-4610-884e-33777f978487")
      url = `https://auth.futurelab.cloud/auth/realms/${user.nomeAzienda.toUpperCase()}/protocol/openid-connect/token`
    }

    const handleResponse = (res: Response) => {
      if (res.ok) {
        return res
      } else {
        if (res.status === 401) {
          setErr(`Il tuo utente non è abilitato all'accesso in quest'area.`)
          throw res.statusText
        } else {
          setErr("Si è verificato un errore, si prega di riprovare più tardi")
          throw res.statusText
        }
      }
    }

    const getRoles = (token: string) => {
      const decData = parseJwt(token)
      if (decData != null) {
        if (decData.resource_access.quadernodicampagna) {
          const roles = decData.resource_access.quadernodicampagna.roles
          const userRole = decData.realm_access.roles
          localStorage.setItem("roles", JSON.stringify(roles))
          localStorage.setItem("userrole", JSON.stringify(userRole))
        } else {
          const userRole = decData.realm_access.roles
          localStorage.setItem("userrole", JSON.stringify(userRole))
        }
      }
    }

    const getLoginData = async (token: string) => {
      const url = BASE_URL()
      const res = await fetch(`${url}/login`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      const json = await res.json()
      if (json != null) {
        const loginRes = json as LoginRes
        if (loginRes != null) {
          localStorage.setItem("logindata", JSON.stringify(loginRes))
          setIsLoading(false)
        }
      }
    }

    const saveRememberMe = () => {
      const userData: UserData = {
        nomeAzienda: user.nomeAzienda,
        username: user.username,
        password: user.password,
        rememberMe: user.rememberMe,
      }
      localStorage.setItem("rememberMeData", JSON.stringify(userData))
    }

    const parseJson = (response: Response) => response.json()

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
      redirect: "follow",
    })
      .then(handleResponse)
      .then(parseJson)
      .then(async (res) => {
        const kcRes: KeycloakResponse = res
        const _user = {
          username: user.username,
          token: kcRes.access_token,
        }
        await getLoginData(_user.token)
        localStorage.setItem("user", JSON.stringify(_user))
        getRoles(_user.token)
        saveRememberMe()
        dispatch({ type: "USER_LOGIN", user: _user })
        navigate("/dashboard")
      })
      .catch((_) => setIsLoading(false))
  }

  const baseClassButton = "button is-success has-no-margin login-button"
  const classesButton = isLoading
    ? `${baseClassButton} is-loading`
    : `${baseClassButton}`

  return (
    <body className="login-body">
      <section className="section is-fullheight has-150-margin-top">
        <div className="columns is-centered is-mobile">
          <div className="column custom-card-rounded login-card">
            <form onSubmit={handleFormSubmit}>
              <figure className="image is-256x256 is-image-centered">
                <img src={logo} alt="logo-zeuslab" />
              </figure>

              <div className="field">
                {/* <label className="label">Nome azienda</label> */}
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Nome azienda"
                    value={user.nomeAzienda}
                    onChange={handleNomeAziendaChange}
                  />
                </div>
              </div>

              <div className="field">
                {/* <label className="label">Username</label> */}
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Username"
                    value={user.username}
                    onChange={handleUsernameChange}
                  />
                </div>
              </div>

              <div className="field">
                {/* <label className="label">Password</label> */}
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    placeholder="Password"
                    value={user.password}
                    onChange={handlePasswordChange}
                  />
                </div>
              </div>
              {err !== "" && (
                <small className="is-block has-text-centered has-text-danger">
                  {err}
                </small>
              )}

              <label className="checkbox">
                <input
                  type="checkbox"
                  style={{ marginRight: "5px" }}
                  checked={user.rememberMe}
                  onChange={(_) =>
                    setUser({ ...user, rememberMe: !user.rememberMe })
                  }
                />
                Ricordami
              </label>

              <div className="field">
                <p className="control">
                  <button
                    type="submit"
                    className={classesButton}
                    disabled={!validateForm()}
                  >
                    Entra
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </body>
  )
}

export default LoginForm
