import produce from "immer";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { BasicDropdown, BasicInput, fetchObjects, deleteObjectAtId, ErrorModal, sortByCaseInsensitive, ID_UNITA, ID_AZIENDA } from "../../Utilities/SharedComponent";
import { TipoRilevazione, UnitaMisura } from "../Azienda/Coltura/Model";
import Navbar from "../Navbar/Navbar";
import CustomTable, { TableSubject } from "../CustomTable/CustomTable";
import Modali, { useModali } from "modali";
import { restartable } from "../../Utilities/Async";
import { usePost } from "../../Utilities/SharedHooks/Sync";

const promiseTipiRilevazione = restartable(() => fetchObjects(`tiporilevazione/${ID_UNITA}`));
const fetchUm = fetchObjects(`${ID_AZIENDA}/unitamisura`)

const ModalRilevazioniInterventoNuovo: React.FC<{
  modal: Modali.ModalHook;
  onSubmit: () => void
}> = ({ modal, onSubmit }) => {
  return (
    <Modali.Modal {...modal}>
      <RilevazioniInterventoNuovo onSubmit={onSubmit} />
    </Modali.Modal>
  );
};

const ModalRilevazioniInterventoAnagrafica: React.FC<{
  modal: Modali.ModalHook
  tipoRilevazione: TipoRilevazione | undefined
}> = ({ modal, tipoRilevazione }) => {
  return (
    <Modali.Modal {...modal}>
      {tipoRilevazione && (
        <RilevazioniInterventoAnagrafica tipoRilevazione={tipoRilevazione} />
      )}
    </Modali.Modal>
  );
};

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      La rilevazione selezionata verrà eliminata permanentemente.
    </Modali.Modal>
  );
};

const RilevazioniIntervento: React.FC = () => {
  const [tipiRilevazioni, setTipiRilevazioni] = useState<TipoRilevazione[] | undefined>();
  const [localTipiRilevazioni, setLocalTipiRilevazioni] = useState<TipoRilevazione[] | undefined>();
  const [locaTipoRilevazioneAttivo, setLocalTipoRilevazioneAttivo] = useState<TipoRilevazione | undefined>();
  const [toDelete, setToDelete] = useState({} as TipoRilevazione)
  const [error, setError] = useState("");

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deleteRilevazioneIntervento()}
      />,
    ],
  });

  const [nuovoTipoInterventoModal, setNuovoTipoInterventoModal] = useModali({
    title: "Nuovo tipo rilevazione (intervento)",
    animated: true,
    closeButton: true,
    onHide: () => { promiseTipiRilevazione.restart(); }
  });

  const [editTipoInterventoModal, setEditTipoInterventoModal] = useModali({
    title: "Modifica tipo rilevazione (intervento)",
    animated: true,
    closeButton: true,
    onHide: () => { promiseTipiRilevazione.restart(); }
  });

  useEffect(() => {
    // Carico le tipologie di rilevazioni per intervento configurate dall'utente
    promiseTipiRilevazione
      .then(data => {
        setTipiRilevazioni(data);
      })
      .catch(err => {
        setError(err);
      });

  }, [promiseTipiRilevazione._current]);

  useEffect(() => {
    setLocalTipiRilevazioni(
      sortByCaseInsensitive<TipoRilevazione>("descrizione", tipiRilevazioni?.filter(x => x.tag === "Intervento"))
    );
  }, [tipiRilevazioni]);

  const handleDeleteRilevazione = (value: TableSubject) => {
    const tipoRilevazione = value as TipoRilevazione;
    if (tipoRilevazione && tipoRilevazione.idTipoRilevazione) {
      setToDelete(tipoRilevazione)
    }
  };

  const deleteRilevazioneIntervento = () => {
    const tmpTipiRilevazioni = tipiRilevazioni?.filter(x => x.idTipoRilevazione !== toDelete.idTipoRilevazione);
    setTipiRilevazioni(tmpTipiRilevazioni);
    deleteObjectAtId("tiporilevazione", toDelete.idTipoRilevazione);
    toggleDeleteModal()
  }

  const updateRilevazione = (e: TableSubject) => {
    const tmp = e as TipoRilevazione;
    if (tmp) {
      setLocalTipoRilevazioneAttivo(tmp);
    }
    setEditTipoInterventoModal();
  };

  const Body = (
    <>
      <section className="section">
        <div className="columns">
          <div className="column is-full custom-card-light is-cm-fullheight">
            <CustomTable
              classes="tableRilevazioniArticolo"
              headers={["descrizione", "tipoValore", "obbligatoria"]}
              rows={localTipiRilevazioni || []}
              title="Tabella rilevazioni per intervento"
              onCreate={setNuovoTipoInterventoModal}
              onUpdate={updateRilevazione}
              onDelete={ e => { handleDeleteRilevazione(e); toggleDeleteModal() }}
            />
          </div>

          <ModalRilevazioniInterventoNuovo modal={nuovoTipoInterventoModal} onSubmit={() => { promiseTipiRilevazione.restart(); }} />
          <ModalRilevazioniInterventoAnagrafica modal={editTipoInterventoModal} tipoRilevazione={locaTipoRilevazioneAttivo} />
        </div>
      </section>
      <ModalDelete modal={deleteModal} />
    </>
  );

  return (
    <div id="outer-container">
      <Navbar
        outerId="outer-container"
        pageWrap="page-wrap"
        breadcrumbs={["Quaderno di campagna", "Rilevazioni per intervento"]}
      />
      <div id="page-wrap">{Body}</div>
    </div>
  );
};

const RilevazioniInterventoAnagrafica: React.FC<{
  tipoRilevazione: TipoRilevazione;
}> = ({ tipoRilevazione }) => {
  const [tipoRilevazioneModificato, setTipoRilevazioneModificato] = useState(tipoRilevazione);
  const [postTipoRilevazione, tipoRilevazionePostato] = usePost<TipoRilevazione>(`tiporilevazione/${ID_UNITA}`);
  const [um, setUm] = useState<UnitaMisura[]>([])

  useEffect(() => {
    fetchUm
      .then(res => setUm(res))
  }, [])

  const handleTexboxChange = (fn: (v: TipoRilevazione) => void) => {
    setTipoRilevazioneModificato(
      produce(tipoRilevazioneModificato, fn)
    );
  };

  const handleTipoValoreSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTipoRilevazioneModificato(
      produce(tipoRilevazioneModificato, v => {
        // N: Safe cause it's a dropdown
        // tslint:disable-next-line: no-any
        v.tipoValore = e.target.value as any;
      })
    );
  };
  
  const handleUMSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTipoRilevazioneModificato(produce(tipoRilevazioneModificato, v => {
      v.unitaDiMisura = e.target.value as string
    }))
  }
  
  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    const _nuovoTipoRilevazioneIntervento: TipoRilevazione = {
      idTipoRilevazione: tipoRilevazione.idTipoRilevazione,
      // idAzienda: ID_AZIENDA ?? "",
      descrizione: tipoRilevazioneModificato.descrizione,
      tag: "intervento",
      tipoValore: tipoRilevazioneModificato.tipoValore,
      obbligatoria: tipoRilevazioneModificato.obbligatoria,
      unitaDiMisura: tipoRilevazioneModificato.unitaDiMisura
    };
    postTipoRilevazione(_nuovoTipoRilevazioneIntervento);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <BasicInput label="Descrizione" value={tipoRilevazioneModificato.descrizione} readOnly={false} onChange={e => { handleTexboxChange((p: TipoRilevazione) => { p.descrizione = e.target.value; })}} />
        <BasicDropdown label={"Valore"} defaultValue={tipoRilevazioneModificato.tipoValore} values={["string", "dataOra", "boolean", "decimal"]} onSelection={handleTipoValoreSelection} />
        {(tipoRilevazioneModificato.tipoValore === "Decimal" || tipoRilevazioneModificato.tipoValore === "DataOra") && (
              <BasicDropdown label={"Unità di misura"} defaultValue={tipoRilevazioneModificato.unitaDiMisura} values={um.map(x => x.descrizione)} onSelection={handleUMSelection} />
            )}
        <div className="field-label is-flex">
          <label className="label has-10-padding has-text-grey has-text-weight-normal">
            Obbligatorio
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="checkbox-input"
                type="checkbox"
                id="c1"
                name="c-group"
                checked={tipoRilevazioneModificato.obbligatoria}
                onChange={e => {
                  handleTexboxChange((p: TipoRilevazione) => {
                    p.obbligatoria = e.target.checked;
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Applica
            </button>
          </p>
        </div>
      </form>
    </>
  );
};

type LightTipoRilevazione = Omit<TipoRilevazione, "idTipoRilevazione">;

const RilevazioniInterventoNuovo: React.FC<{ onSubmit?: (_: LightTipoRilevazione) => void }> = ({ onSubmit }) => {
  const initialStateTipoRilevazioneIntervento: LightTipoRilevazione = {
    // idAzienda: ID_AZIENDA ?? "",
    descrizione: "",
    tag: "intervento",
    tipoValore: "string",
    obbligatoria: false,
    unitaDiMisura: ""
  };

  const defaultTipoRilevazioneIntervento: LightTipoRilevazione = {
    // idAzienda: ID_AZIENDA ?? "",
    descrizione: "",
    tag: "intervento",
    tipoValore: "string",
    obbligatoria: false,
    unitaDiMisura: ""
  };
  const [um, setUm] = useState<UnitaMisura[]>([])

  useEffect(() => {
    fetchUm
      .then(res => setUm(res))
  }, [])

  const [nuovoTipoRilevazioneIntervento, setNuovoTipoRilevazioneIntervento] = useState<LightTipoRilevazione>(defaultTipoRilevazioneIntervento);
  const [postTipoRilevazione, tipoRilevazionePostato] = usePost<LightTipoRilevazione>(`tiporilevazione/${ID_UNITA}`);

  const handleTexboxChange = (fn: (v: TipoRilevazione) => void) => {
    setNuovoTipoRilevazioneIntervento(
      produce(nuovoTipoRilevazioneIntervento, fn)
    );
  };

  const handleTipoValoreSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNuovoTipoRilevazioneIntervento(
      produce(nuovoTipoRilevazioneIntervento, v => {
        // N: Safe cause it's a dropdown
        // tslint:disable-next-line: no-any
        v.tipoValore = e.target.value as any;
      })
    );
  };

  const handleUMSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNuovoTipoRilevazioneIntervento(produce(nuovoTipoRilevazioneIntervento, v => {
      v.unitaDiMisura = e.target.value as string
    }))
  }

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    const _nuovoTipoRilevazioneIntervento: LightTipoRilevazione = {
      // idAzienda: ID_AZIENDA ?? "",
      descrizione: nuovoTipoRilevazioneIntervento.descrizione,
      tag: "intervento",
      tipoValore: nuovoTipoRilevazioneIntervento.tipoValore,
      obbligatoria: nuovoTipoRilevazioneIntervento.obbligatoria,
      unitaDiMisura: nuovoTipoRilevazioneIntervento.unitaDiMisura
    };
    setNuovoTipoRilevazioneIntervento(_nuovoTipoRilevazioneIntervento);
    postTipoRilevazione(_nuovoTipoRilevazioneIntervento);
    setNuovoTipoRilevazioneIntervento(initialStateTipoRilevazioneIntervento);
    onSubmit?.(_nuovoTipoRilevazioneIntervento);
  };

  return (
    <>
      <ErrorModal prom={tipoRilevazionePostato} />
      <form onSubmit={handleFormSubmit}>
        <BasicInput label="Descrizione" value={nuovoTipoRilevazioneIntervento.descrizione} readOnly={false} onChange={e => { handleTexboxChange((p: TipoRilevazione) => { p.descrizione = e.target.value; }); }} />
        <BasicDropdown label={"Valore"} defaultValue="Seleziona tipo valore" values={["string", "dataOra", "boolean", "decimal"]} onSelection={handleTipoValoreSelection} />

        {(nuovoTipoRilevazioneIntervento.tipoValore === "decimal" || nuovoTipoRilevazioneIntervento.tipoValore === "dataOra") && (
          <BasicDropdown label={"Unità di misura"} defaultValue="Seleziona UM" values={um.map(x => x.descrizione)} onSelection={handleUMSelection} />
        )}

        <div className="field-label is-flex">
          <label className="label has-10-padding has-text-grey has-text-weight-normal">
            Obbligatorio
        </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="checkbox-input"
                checked={nuovoTipoRilevazioneIntervento.obbligatoria}
                type="checkbox"
                id="c1"
                name="c-group"
                onChange={e => {
                  handleTexboxChange((p: TipoRilevazione) => {
                    p.obbligatoria = e.target.checked;
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Salva e aggiungi
            </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default RilevazioniIntervento;
