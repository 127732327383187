import React, { useEffect, useState } from 'react'
import { PersonaIntervento } from '../../Azienda/Coltura/Model'
import moment from 'moment'
import * as _ from 'ramda'

const StoricoCheckIn: React.FC<{
  persone: PersonaIntervento[]
}> = ({ persone }) => {
  
  const unsafeByData = _.descend(_.prop('dataFine'))

  const [personale, setPersonale] = useState<PersonaIntervento[]>([])
  useEffect(() => {
    const xp = _.sort(unsafeByData, persone)
    setPersonale(xp)
  }, [persone])

  return (
    <div>
      <h1 className="subtitle">Storico ingressi</h1>
      {personale.length > 0 ? personale.map((persona, i) => (
        <div className="tile is-ancestor" key={i}>
          <div className="tile">
            <div className="tile is-parent">
              <article className="tile is-child notification">
                <p className="subtitle is-marginless">{persona.nome}</p>
                <p><b>Ingresso:</b> {moment(persona.dataInizio).format('DD MMMM YY, HH:mm:ss')}</p>
                <p><b>Uscita:</b> {moment(persona.dataFine).format('DD MMMM YY, HH:mm:ss')}</p>
                <p><b>Durata:</b> ~ {Math.floor(moment.duration(moment(persona.dataFine).diff(moment(persona.dataInizio))).asMinutes())} minuti</p>
              </article>
            </div>
          </div>
        </div>
      )) : (
        <h1 className="subtitle is-6">Nessuno storico da visualizzare</h1>
      )}
    </div>
  )
}

export default StoricoCheckIn