import { produce } from "immer";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import {
  BasicInput,
  fetchObjects,
  deleteObjectAtId,
  ErrorModal,
  BasicDropdown,
  postPhoto,
  fetchPhoto,
  sortByCaseInsensitive,
  ID_UNITA
} from "../../Utilities/SharedComponent";
import "../../Utilities/Utils.css";
import { Persona, Sesso } from "../Azienda/Coltura/Model";
import Navbar from "../Navbar/Navbar";
import { restartable } from "../../Utilities/Async";
import CustomTable, { TableSubject } from "../CustomTable/CustomTable";
import Modali, { useModali } from "modali";
import { usePost, useFetch } from "../../Utilities/SharedHooks/Sync";
import { ToastProvider, useToasts } from 'react-toast-notifications'
import Pagination from "../../Components/Pagination/Pagination";

const promisePersone = restartable(() => fetchObjects(`persone/${ID_UNITA}`));

const ModalNuovaPersona: React.FC<{
  modal: Modali.ModalHook;
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      <NuovaPersona />
    </Modali.Modal>
  );
};

const ModalModificaPersona: React.FC<{
  modal: Modali.ModalHook;
  persona: Persona;
}> = ({ modal, persona }) => {
  return (
    <Modali.Modal {...modal}>
      <AnagraficaPersona persona={persona} />
    </Modali.Modal>
  );
};

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      La persona selezionata verrà eliminata permanentemente.
    </Modali.Modal>
  );
};

const Persone: React.FC = () => {
  const [persone, setPersone] = useState<Persona[] | undefined>();
  const [personaAttiva, setPersonaAttiva] = useState({} as Persona);
  const [toDelete, setToDelete] = useState({} as Persona)
  const [error, setError] = useState("");

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deletePersona()}
      />,
    ],
  });

  const [nuovaPersonaModal, setNuovaPersonaModal] = useModali({
    title: "Nuova persona",
    animated: true,
    closeButton: true,
    onHide: () => { promisePersone.restart(); }
  });

  const [modificaPersonaModal, setModificaPersonaModal] = useModali({
    title: "Modifica persona",
    animated: true,
    closeButton: true,
    onHide: () => { promisePersone.restart(); }
  });

  useEffect(() => {
    promisePersone
      .then(data => {
        setPersone(data);
      })
      .catch(err => {
        setError(err);
      });
  }, [promisePersone._current]);

  const handleClickPersona = (e: TableSubject) => {
    const tmp = e as Persona;
    if (tmp) {
      setPersonaAttiva(tmp);
      setModificaPersonaModal();
    }
  };

  const handleDeletePersona = (e: TableSubject) => {
    const tmp = e as Persona;
    if (tmp) {
      setToDelete(tmp)
    }
  };
  
  const deletePersona = () => {    
    const tmpPersone = persone?.filter(x => x.idPersona !== toDelete.idPersona);
    setPersone(tmpPersone);
    deleteObjectAtId("persone", toDelete.idPersona);
    toggleDeleteModal()
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [objPerPage, _] = useState(18)

  const indexLastObj = currentPage * objPerPage
  const indexFirstObj = indexLastObj - objPerPage
  const currentObjs = persone && persone.slice(indexFirstObj, indexLastObj)

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const Body = persone && (
    <>
      <ToastProvider>
        <section className="section">
          <div className="columns">
            <div className="column is-full custom-card-light is-cm-fullheight">
              <CustomTable
                classes="tablePersonale"
                headers={["codicePersona", "nomePersona", "sesso", { key: "costoOrario", value: "currency" }]}
                rows={persone || []}
                title="Tabella personale"
                footer={false}
                onCreate={setNuovaPersonaModal}
                onUpdate={handleClickPersona}
                onDelete={ e => { handleDeletePersona(e); toggleDeleteModal()} }
              />
              {/* <Pagination objPerPage={objPerPage} totalObj={persone.length} paginate={paginate} /> */}
            </div>

            <ModalNuovaPersona modal={nuovaPersonaModal} />
            <ModalModificaPersona modal={modificaPersonaModal} persona={personaAttiva} />
          </div>
        </section>
        <ModalDelete modal={deleteModal} />
      </ToastProvider>
    </>
  );
  return (
    <div id="outer-container">
      <Navbar
        outerId="outer-container"
        pageWrap="page-wrap"
        breadcrumbs={["Quaderno di campagna", "Persone"]}
      />
      <div id="page-wrap">{Body}</div>
    </div>
  );
};

function setField<T, K extends keyof T>(fn: (React.Dispatch<React.SetStateAction<T>>), obj: T, key: K, value: any) {
  fn(produce(obj, (x: T) => { x[key] = value }))
};

const NuovaPersona: React.FC = () => {
  const defaultPersona = {
    codicePersona: "",
    nomePersona: "",
    costoOrario: 0,
    sesso: "NS" as Sesso
  };

  const [persona, setPersona] = useState(defaultPersona);
  const [photo, setPhoto] = useState<any>(null)
  const [postPersona, personaPostata] = usePost<Omit<Persona, "idPersona">>(`persone/${ID_UNITA}`);
  const { addToast } = useToasts();
  
  const handleSessoSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPersona(
      produce(persona, v => {
        v.sesso = e.target.value as any;
      })
    );
  };

  const onChangePhoto = (e : any) => {
    e.preventDefault();
    const file = e.target.files[0]
    setPhoto(file)    
  }

  const postProfilePhoto = (idPersona: any) => {
    let formData = new FormData()
    if (photo != null && photo != undefined && idPersona) {
      formData.append('image', photo, photo.name);
      postPhoto(`foto/persona/${idPersona}`, formData)
        .then(_ => addToast('Persona salvata correttamente', { appearance: 'success', autoDismiss: true }))
    }
  }

  const submit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    setPersona(defaultPersona);
    postPersona(persona)
      .then(idPersona => {
        postProfilePhoto(idPersona);
      })

    addToast('Persona salvata correttamente', { appearance: 'success', autoDismiss: true })
  };

  return (
    <>
      <ErrorModal prom={personaPostata} />
      <form onSubmit={submit}>
        {photo != null && photo != undefined && (
          <figure className="image is-200x200 figure-profile-picture">
            <img src={URL.createObjectURL(photo)} alt="profile-picture"/>
          </figure>
        )}
        <div className="file file-input-upload figure-profile-picture-input">
          <label className="file-label">
            <input className="file-input" type="file" name="resume" accept="image/*" onChange={onChangePhoto}/>
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">
                Immagine
              </span>
            </span>
            <span className="file-name">
              {photo && photo.name || "Selezionare un'immagine"}
            </span>
          </label>
        </div>

        <BasicInput 
          label="Codice" 
          value={persona.codicePersona} 
          readOnly={false} 
          onChange={e => setField(setPersona, persona, "codicePersona", e.target.value) } 
        />
        
        <BasicInput 
          label="Nome" 
          value={persona.nomePersona} 
          readOnly={false} 
          onChange={e => setField(setPersona, persona, "nomePersona", e.target.value) } 
        />

        <BasicDropdown 
          label={"Sesso"} 
          defaultValue="Seleziona sesso" 
          values={["F", "M"]} 
          onSelection={ handleSessoSelection } 
        />
        
        <BasicInput 
          label="Costo orario" 
          type="number" 
          value={persona.costoOrario} 
          readOnly={false} 
          onChange={e => setField(setPersona, persona, "costoOrario", Number(e.target.value)) } 
        />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Salva e aggiungi
          </button>
          </p>
        </div>
      </form>
    </>
  );
};

const AnagraficaPersona: React.FC<{
  persona: Persona;
}> = ({ persona }) => {
  const [personaModificata, setPersonaModificata] = useState(persona)
  const [postPersona, personaPostata] = usePost<Persona>(`persone/${ID_UNITA}`);
  const [photo, setPhoto] = useState<any>(null)
  const [newPhoto, setNewPhoto] = useState<any>(null)
  const { addToast } = useToasts()

  // useEffect(() => {
  //   fetchPhoto(`foto/persona/${persona.idPersona}`)
  //     .then(res => {
  //       if(!(res === "Immagine non trovata"))
  //       setPhoto(res)
  //     })
  // })

  const handleSessoSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    
    setPersonaModificata(
      produce(personaModificata, v => {
        v.sesso = e.target.value as any;
      })
    );
  };

  const onChangePhoto = (e : any) => {
    e.preventDefault();
    const file = e.target.files[0]
    setNewPhoto(file)    
  }

  const postProfilePhoto = (idPersona: any) => {
    let formData = new FormData();
    if (newPhoto != null && newPhoto != undefined && idPersona) {
      formData.append('image', newPhoto, newPhoto.name);

      postPhoto(`foto/persona/${idPersona}`, formData)
        .then(_ => addToast('Persona salvata correttamente', { appearance: 'success', autoDismiss: true }))
    }
  }

  const submit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    postPersona(personaModificata)
      .then(idPersona => {
        addToast('Persona aggiornata correttamente', { appearance: 'success', autoDismiss: true })
        if(newPhoto !== undefined) {
          postProfilePhoto(idPersona);
        }
      })
  };

  return (
    <>
      <ErrorModal prom={personaPostata} />
      <form onSubmit={submit}>
        {newPhoto != null ? (
          <figure className="image is-200x200 figure-profile-picture">
            <img className="is-rounded" src={URL.createObjectURL(newPhoto)} alt="profile-picture"/>
          </figure>
        ) : (
          photo != null ? (
            <figure className="image is-200x200 figure-profile-picture">
              <img className="is-rounded" src={photo} alt="profile-picture"/>
            </figure>
          ) : (<small className="no-photo-message">Nessuna foto inserita</small>)
        )}
          <div className="file file-input-upload figure-profile-picture-input">
            <label className="file-label">
              <input className="file-input" type="file" name="resume" accept="image/*" onChange={onChangePhoto}/>
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  Immagine
                </span>
              </span>
              <span className="file-name">
                {photo ? 'Immagine selezionata' : "Selezionare un'immagine"} 
              </span>
            </label>
          </div>

        <BasicInput 
          label="Codice" 
          value={personaModificata.codicePersona} 
          readOnly={false} 
          onChange={() => {}} 
        />
        
        <BasicInput 
          label="Nome" 
          value={personaModificata.nomePersona} 
          readOnly={false} 
          onChange={e => { setField(setPersonaModificata, personaModificata, "nomePersona", e.target.value) }} 
        />
        
        <BasicDropdown 
          label={"Sesso"} 
          defaultValue={persona.sesso} 
          // values={["F", "M"].filter(s => s !== persona.sesso)} 
          values={["F", "M"]} 
          onSelection={ handleSessoSelection } 
        />

        <BasicInput 
          label="Costo orario" 
          type="number" 
          value={personaModificata.costoOrario.toFixed(2)} 
          readOnly={false} 
          onChange={e => setField(setPersonaModificata, personaModificata, "costoOrario", Number(e.target.value)) } 
        />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Applica
          </button>
          </p>
        </div>
      </form>
    </>
  );
};
export default Persone;
