import * as Coltura from './Coltura';
import * as Intervento from './SelectedIntervento';
import * as RepartoAttivo from './RepartoAttivo';
import * as NuovoReparto from './NuovoReparto';
import * as UnitaProduttivaAttiva from './UnitaProduttiva';
import * as ArticoloIn from './ArticoloIngresso';
import * as ArticoloOut from './ArticoloUscita';
import * as Macchine from './Macchine';
import * as Persone from './Persone';
import * as Rilevazioni from './Rilevazioni';
import * as User from './User'
import * as Routing from './Route'

import { combineReducers } from "redux";
import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { Reducer, Action } from 'redux';
import { mergeNullsR } from '../Utilities/Functions';
import reduceReducers, { anyList } from '../Utilities/Redux/reduceReducers';
import undoable, { ActionTypes, StateWithHistory, excludeAction } from "redux-undo";
import { restorable, RestoreActions, restoreActions } from '../Utilities/Redux/Restore';
import thunk from "redux-thunk";

export const UNDO = "UNDO" as const;
export const REDO = "REDO" as const;
export const JUMP = "JUMP" as const;
export const JUMP_TO_PAST = "JUMP_TO_PAST" as const;
export const JUMP_TO_FUTURE = "JUMP_TO_FUTURE" as const;
export const CLEAR_HISTORY = "CLEAR_HISTORY" as const;
export type UNDO_ACTION = { type: typeof UNDO };
export type REDO_ACTION = { type: typeof REDO };
export type JUMP_ACTION = { type: typeof JUMP };
export type JUMP_TO_PAST_ACTION = { type: typeof JUMP_TO_PAST };
export type JUMP_TO_FUTURE_ACTION = { type: typeof JUMP_TO_FUTURE };
export type CLEAR_HISTORY_ACTION = { type: typeof CLEAR_HISTORY };

type UndoActions
  = UNDO_ACTION
  | REDO_ACTION
  | JUMP_ACTION
  | JUMP_TO_FUTURE_ACTION
  | JUMP_TO_PAST_ACTION
  | CLEAR_HISTORY_ACTION;

export type AllActions
  = Coltura.Action
  | Intervento.Action
  | RepartoAttivo.Action
  | NuovoReparto.Action
  | UnitaProduttivaAttiva.Action
  | ArticoloIn.Action
  | ArticoloOut.Action
  | Macchine.Action
  | Persone.Action
  | Rilevazioni.Action
  | User.Action
  | UndoActions
  | RestoreActions
  | Routing.Action

export type State
  = Coltura.State
  & Intervento.State
  & RepartoAttivo.State
  & NuovoReparto.State
  & UnitaProduttivaAttiva.State
  & ArticoloIn.State
  & ArticoloOut.State
  & Macchine.State
  & Persone.State
  & Rilevazioni.State
  & User.State
  & Routing.State;

export const reducer: Reducer<State, AllActions> = reduceReducers<State, AllActions>(
  {
    ...Coltura.defaultState
  , ...Intervento.defaultState
  , ...RepartoAttivo.defaultState
  , ...NuovoReparto.defaultState
  , ...UnitaProduttivaAttiva.defaultState
  , ...ArticoloIn.defaultState
  , ...ArticoloOut.defaultState
  , ...Macchine.defaultState
  , ...Persone.defaultState
  , ...Rilevazioni.defaultState
  , ...User.defaultState
  , ...Routing.defaultState
  },
  ...anyList(
    Intervento.reducer
  , Coltura.reducer
  , RepartoAttivo.reducer
  , NuovoReparto.reducer
  , UnitaProduttivaAttiva.reducer
  , ArticoloIn.reducer
  , ArticoloOut.reducer
  , Macchine.reducer
  , Persone.reducer
  , Rilevazioni.reducer
  , User.reducer
  , Routing.reducer
  )
);

export type RootState = StateWithHistory<State>
export const store: EnhancedStore<RootState, AllActions> = configureStore({
  reducer: restorable(undoable(reducer, {
    undoType: UNDO,
    redoType: REDO,
    jumpType: JUMP,
    jumpToFutureType: JUMP_TO_FUTURE,
    jumpToPastType: JUMP_TO_PAST,
    clearHistoryType: CLEAR_HISTORY,
    filter: excludeAction(restoreActions),
    debug: false
  })),
  devTools: true
});
