import { faMapMarkerAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { RepartoModel, UnitaProduttiva } from "./Model";
import NuovaUnitaProduttiva from "./UnitaProduttiva/NuovaUnitaProduttiva";
import NuovoReparto from "./Reparti/NuovoReparto";
import { ColturaModel, ArticoloUscitaIntervento, MacchinaIntervento, PersonaIntervento, Rilevazione as RilevazioneModel, InterventoModel, ArticoloIngressoIntervento, initialStateColtura } from "./Coltura/Model";
import NuovaColtura from "./Coltura/NuovaColtura";
import Reparto from "./Reparti/Reparto";
import UnitaProduttivaVuota from "./UnitaProduttiva/UnitaProduttiva";
import { ArticoloIngresso, NuovoArticoloIngresso } from "./Intervento/ArticoloIngresso/ArticoloIngresso";
import { ArticoloUscita, NuovoArticoloUscita } from "./Intervento/ArticoloUscita/ArticoloUscita";
import { Macchina, NuovaMacchina } from "./Intervento/Macchina/Macchina";
import { Persona, NuovaPersona } from "./Intervento/Persona/Persona";
import { Rilevazione, NuovaRilevazione } from "./Intervento/Rilevazione/Rilevazione";
import { TableSubject } from "../CustomTable/CustomTable";
import Intervento from "./Intervento/Intervento";
import NuovoIntervento from "./Intervento/NuovoIntervento";
import Coltura from "./Coltura/Coltura";
import { AllActions, UNDO } from "../../Reducers/Root";
import { useDispatch, useSelector, useRoute, useRoutes } from "../../Utilities/SharedHooks/State";
import { fetchObjects, ErrorModal, deleteObject, ID_AZIENDA, ID_UNITA } from "../../Utilities/SharedComponent";
import * as _ from "ramda";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import * as Redux from "react-redux";
import { usePost, useDelete } from "../../Utilities/SharedHooks/Sync";
import { produce } from "immer";
import { useToasts } from "react-toast-notifications";
import { LoginRes } from "../Login/LoginForm";
import { isRestorable } from "../../Utilities/Redux/Restore";
import { REPARTO, navigate, NUOVA_UNITA_PRODUTTIVA, Route, NUOVO_REPARTO, UNITA_PRODUTTIVA_VUOTA, NUOVO_ARTICOLO_INGRESSO, NUOVO_ARTICOLO_USCITA, NUOVA_MACCHINA, NUOVA_PERSONA, NUOVA_RILEVAZIONE, INTERVENTO, COLTURA, navigateBack, NUOVA_COLTURA, NUOVO_INTERVENTO, ARTICOLO_USCITA, PERSONA, ARTICOLO_INGRESSO } from "../../Reducers/Route";
import { useLog } from "../../Utilities/SharedHooks/Debugging";
import Modali, { useModali } from "modali"

const promiseUnitaProduttiva = (idAzienda: string, idUnita: string) => fetchObjects(`${idAzienda}/unitaproduttive?idUnita=${idUnita}`); // per unita produttive
const promiseUnitaProduttive = (idAzienda: string) => fetchObjects(`${idAzienda}/unitaproduttive`); // per aziende
const promiseColtura = (idReparto: string) => fetchObjects(`colture/${idReparto}`);

const Azienda: React.FC = () => {

  const dispatch = useDispatch<AllActions>();
  const navigateTo = (r : Route) => dispatch(navigate(r))
  // const colture = useSelector(x => x.present.coltureOriginali);
  const colturaSelectedRow = useSelector(x => x.present.colturaSelectedRow);
  const interventoSelectedRow = useSelector(x => x.present.interventoSelectedRow);
  const datiReparto = useSelector(x => x.present.repartoAttivo?.datiReparto);
  const coltureCorrenti = useSelector(x => x.present.repartoAttivo?.coltureCorrenti);
  const idUnitaProduttivaCorrente = useSelector(x => x.present.idUnitaProduttivaCorrente);
  const unitaProduttivaAttiva = useSelector(x => x.present.unitaProduttivaAttiva);
  const nuovaUnitaProduttiva = useSelector(x => x.present.nuovaUnitaProduttiva !== undefined);
  const nuovaColtura = useSelector(x => x.present.nuovaColtura);
  const nuovoIntervento = useSelector(x => x.present.nuovoIntervento);

  const modificaArticoloIngresso = useSelector(x => x.present.articoloIngresso);
  const nuovoArticoloIngresso = useSelector(x => x.present.nuovoArticoloIngresso);
  const modificaArticoloUscita = useSelector(x => x.present.articoloUscita);
  const nuovoArticoloUscita = useSelector(x => x.present.nuovoArticoloUscita);
  const nuovaMacchina = useSelector(x => x.present.nuovaMacchina);
  const modificaMacchina = useSelector(x => x.present.macchina);
  const nuovaPersona = useSelector(x => x.present.nuovaPersona);
  const modificaPersona = useSelector(x => x.present.persona);
  const nuovaRilevazione = useSelector(x => x.present.nuovaRilevazione);
  const modificaRilevazione = useSelector(x => x.present.rilevazione);

  const route = useRoutes()
  //hack for refetch
  const store = useSelector(x => x)
  const present = useSelector(x => x.present)
  const [fetchColtura, setFetchedColtura] = useState(false as boolean | string);
  const reloadColtura = (idReparto?: string) => { 
    if(idReparto == null) {
      setFetchedColtura(!fetchColtura); 
    } else {
      setFetchedColtura(idReparto);
    }
  }
  const { addToast } = useToasts();
  const [fetchUnita, setFetchedUnita] = useState(false);
  const reloadUnita = () => { setFetchedUnita(!fetchUnita); }

  const refetchColtura = (c: ColturaModel) => {
    dispatch({ type: 'COLTURA_EDIT', coltura: c, datiReparto: datiReparto!})
  }

  const [unitaProduttive, setUnitaProduttive] = useState<UnitaProduttiva[]>([]);
  const [unitaProduttiva, setUnitaProduttiva] = useState<UnitaProduttiva[] | undefined>();
  const [staticColture, setColture] = useState<ColturaModel[]>([]);
  const [error, setError] = useState("");
  const [role, setRole] = useState("")

  const [loginRes, setLoginRes] = useState<LoginRes | undefined>()

  useEffect(() => {
    const lr = localStorage.getItem('logindata')

    let _loginRes: LoginRes = {}

    if (lr !== null && lr !== "") {
      const unjson = JSON.parse(lr)
      setLoginRes(unjson)
      _loginRes = unjson
    }

    // Carico le unità produttive collegate all'azienda loggata
    const r = localStorage.getItem('userrole')
    let roles: string[] = []
    if (r != null && r !== "") {
      roles = JSON.parse(r)
    }
    const x = localStorage.getItem('roles')
    let xroles: string[] = []
    if (x != null && x !== "") {
      xroles = JSON.parse(x)
    }
    if (roles.indexOf('azienda') > -1) {
      setRole('azienda')
      // recupero la lista di unita produttive associate all'azienda
      if (_loginRes && _loginRes.azienda) {
        
        promiseUnitaProduttive(_loginRes.azienda)
          .then((data) => { setUnitaProduttive(data); })
          .catch((err) => { setError(err); });
      }

    } else if (xroles.indexOf('operatore_gestionale') > -1) {
      setRole('operatore_gestionale')
      // recupero la singola unita produttiva
      if (_loginRes && _loginRes.unitaProduttiva && _loginRes.azienda) {
        
        promiseUnitaProduttiva(_loginRes.azienda, _loginRes.unitaProduttiva)
          .then((data) => { setUnitaProduttiva(data); })
          .catch((err) => { setError(err); });
      }
    } else if (xroles.indexOf('operatore_stanza') > -1) {
      setRole('operatore_stanza')
    }
  }, []);

  useEffect(() => {
    if(unitaProduttiva) {
      const codicePunto = unitaProduttiva[0].codiceUnitaProduttiva
      localStorage.setItem('codiceunitaproduttiva', codicePunto)
    }
  }, [unitaProduttiva])

  useEffect(() => {
    if (datiReparto?.idReparto) {
      promiseColtura(datiReparto.idReparto)
        .then((data) => { setColture(data); })
        .catch((err) => { setError(err); });
    }
  }, [datiReparto]);


  /** BEGIN refetch unita_produttive */
  useEffect(() => {
    if (loginRes && loginRes.azienda) {
      promiseUnitaProduttive(loginRes.azienda)
        .then((ups : UnitaProduttiva[]) => {
          if (ups) {
            setUnitaProduttive(ups);
            const unitaConReparto =
              ups.find(up => up.reparti.map(r => r.idReparto).includes(datiReparto?.idReparto));
            const repartoDaAggiornare = unitaConReparto?.reparti?.find(r => r.idReparto == datiReparto?.idReparto);
            if (repartoDaAggiornare) {
              dispatch({ type: 'EDIT_REPARTO', reparto: repartoDaAggiornare, colture: staticColture, idUnitaProduttiva: unitaProduttivaAttiva?.idUnitaProduttiva || "" });
            }
          }
        })
        .catch((err) => { setError(err); });
    }
  }, [fetchUnita]);

  useEffect(() => {
    const unitaConReparto =
      unitaProduttive.find(up => up.reparti.map(r => r.idReparto).includes(datiReparto?.idReparto));
    const repartoDaAggiornare = unitaConReparto?.reparti?.find(r => r.idReparto == datiReparto?.idReparto);
    if (repartoDaAggiornare) {
      dispatch({ type: 'EDIT_REPARTO', reparto: repartoDaAggiornare, colture: staticColture, idUnitaProduttiva: unitaProduttivaAttiva?.idUnitaProduttiva || "" });
    }
  }, [unitaProduttive])
  /** END refetch unita_produttive */


  /** BEGIN refetch coltura */
  useEffect(() => {
    if (colturaSelectedRow) {
      promiseColtura(colturaSelectedRow.idReparto)
        .then((data) => { setColture(data); reloadColtura(); })
        .catch((err) => { setError(err); });
    }
  }, [nuovaColtura])

  useEffect(() => {
    let datiRepartoPerRefetch = datiReparto
    if (!_.isEmpty(staticColture) && datiRepartoPerRefetch) {
      dispatch({ type: 'EDIT_REPARTO', reparto: datiRepartoPerRefetch, colture: staticColture, idUnitaProduttiva: unitaProduttivaAttiva?.idUnitaProduttiva || "" });
    }
  }, [staticColture]);

  const handleClickReparto = (e: RepartoModel, d: UnitaProduttiva) => {
    dispatch({ type: 'EDIT_REPARTO', reparto: e, colture: staticColture, idUnitaProduttiva: d.idUnitaProduttiva });
    // dispatch({ type: 'SET_ROOT_RESTORER', payload: [x => x.idReparto === e.idReparto] })
    navigateTo(REPARTO)
  };

  const handleClickUnitaProduttiva = (unitaProduttiva: UnitaProduttiva) => {
    dispatch({ type: 'SET_UNITA_ATTIVA', unitaProduttiva });
    navigateTo(UNITA_PRODUTTIVA_VUOTA)
  };

  const handleClickNuovaUnitaProduttiva = () => {
    dispatch({ type: 'NUOVA_UNITA' });
    navigateTo(NUOVA_UNITA_PRODUTTIVA)
  };

  const handleClickNuovoReparto = (idUnitaProduttiva: string) => {
    dispatch({ type: 'NUOVO_REPARTO', idUnitaProduttiva });
    navigateTo(NUOVO_REPARTO)
  };

  const [postColtura, colturaPostata] = usePost<ColturaModel>(`colture/${colturaSelectedRow?.idColtura}`);

  const handleOnUpdateRowColtura = (e: TableSubject) => {
    const coltura = e as ColturaModel;
    if (coltura) {
      dispatch({ type: 'COLTURA_EDIT', coltura, datiReparto: datiReparto! });
      navigateTo(COLTURA)
      // postColtura(coltura)
      // reloadUnita()
    }
  };

  const handleUpdateRowColtura = (e: TableSubject) => {
    const coltura = e as ColturaModel
    if (coltura) {
      postColtura(coltura)
      reloadUnita()
    }
  }

  const handleOnUpdateRowIntervento = (e: TableSubject) => {
    const intervento = e as InterventoModel;
    if (intervento) {
      dispatch({ type: 'INTERVENTO_EDIT', intervento, colturaRow: colturaSelectedRow || initialStateColtura });
      navigateTo(INTERVENTO)
    }
  };
  const [deleteColtura, colturaDeleted] = useDelete('coltura');

  const handleOnDeleteRowColtura = (e: TableSubject | ColturaModel) => {
    const coltura = e as ColturaModel;
    if (coltura) {
      const tmpColture = staticColture.filter((c) => c.idColtura !== coltura.idColtura);
      setColture(tmpColture);
      dispatch({ type: "EDIT_REPARTO", colture: tmpColture, reparto: datiReparto!, idUnitaProduttiva: idUnitaProduttivaCorrente! });
      dispatch({ type: "SET_COLTURE", colture: tmpColture })
      dispatch({ type: "COLTURA_REMOVE" })
      deleteColtura(coltura.idColtura);
      dispatch(navigateBack())
      reloadColtura();
    }
  };

  const [deleteIntervento, interventoDeleted] = useDelete('intervento');
  const handleOnDeleteRowIntervento = (e: TableSubject | InterventoModel) => {
    const intervento = e as InterventoModel;
    if (intervento && colturaSelectedRow) {
      dispatch({ type: 'REMOVE_INTERVENTO', id: intervento.idIntervento ?? "" });
      deleteIntervento(intervento.idIntervento!);
      // dispatch({ type: 'COLTURA_EDIT', coltura: colturaSelectedRow, datiReparto: datiReparto! })
      dispatch(navigateBack())
      reloadColtura()
      reloadUnita()
    }
  };

  const handleOnCreateRowColtura = () => {
    dispatch({ type: 'NUOVA_COLTURA' });
    navigateTo(NUOVA_COLTURA)
  };

  const handleOnCreateRowIntervento = () => {
    dispatch({ type: 'NUOVO_INTERVENTO', colturaRow: colturaSelectedRow || initialStateColtura });
    dispatch(navigateTo(NUOVO_INTERVENTO))
    reloadColtura();
  };
  const [postIntervento, interventoPostato] = usePost<ColturaModel>(`colture/${colturaSelectedRow?.idColtura}`);

  const handleOnEditRowIntervento = (intervento: InterventoModel) => {
    const colturaConIntervento = produce(colturaSelectedRow, colt => {
      if (colt) {
        colt.interventi = colt.interventi.map(x => x.idIntervento !== intervento.idIntervento ? x : intervento);
      }
    })!;
    
    postIntervento(colturaConIntervento)
      .then(res => {
        if (res.isError) {
          
          // viene mostrato il messaggio di errore tramite l'ErrorModal
          return
        } else {
          dispatch({ type: 'COLTURA_EDIT', coltura: colturaConIntervento })
          addToast('Intervento aggiornato correttamente', { appearance: 'success', autoDismiss: true })
        } 
      })
  };
  useEffect(() => {
    if(datiReparto && idUnitaProduttivaCorrente && coltureCorrenti && colturaSelectedRow){
      const coltureModificate = coltureCorrenti.map(x => 
        x.idColtura === colturaSelectedRow?.idColtura ? 
          colturaSelectedRow :
          x
      )
      dispatch({type: "EDIT_REPARTO", reparto: datiReparto, colture: coltureModificate, idUnitaProduttiva: idUnitaProduttivaCorrente})
    }
  }, [colturaSelectedRow])
  /// ARTICOLI IN INGRESSO
  const handleOnCreateArticoliInIngresso = () => {
    dispatch({
      type: 'NUOVO_ARTICOLO_IN',
      colturaRow: colturaSelectedRow || initialStateColtura,
      idIntervento: interventoSelectedRow?.idIntervento ?? ""
    });
    navigateTo(NUOVO_ARTICOLO_INGRESSO)
  };

  const handleOnUpdateArticoliInIngresso = (e: TableSubject) => {
    const articoloIngresso = e as ArticoloIngressoIntervento;
    dispatch({ type: 'EDIT_ARTICOLO_IN', articoloIngresso, coltura: colturaSelectedRow || {} as ColturaModel, idIntervento: interventoSelectedRow?.idIntervento ?? "" });
    navigateTo(ARTICOLO_INGRESSO)
  };

  const handleOnDeleteArticoliInIngresso = (e: TableSubject) => {
    const articoliIngresso = e as ArticoloIngressoIntervento;
    if (articoliIngresso && interventoSelectedRow) {
      deleteObject(`articoloingresso/${articoliIngresso.idArticoloIngressoIntervento}`)
        .then(res => {
          if (res.ok) {
            dispatch({ type: 'DELETE_ARTICOLO_IN', id: articoliIngresso.idArticoloIngressoIntervento });
          } else toggle()
        })
        .catch(_ => toggle())
    }
  };

  /// ARTICOLI IN USCITA
  const handleOnCreateArticoliInUscita = () => {
    dispatch({
      type: 'NUOVO_ARTICOLO_OUT',
      colturaRow: colturaSelectedRow || initialStateColtura,
      idIntervento: interventoSelectedRow?.idIntervento ?? ""
    });
    navigateTo(NUOVO_ARTICOLO_USCITA)
  };

  const handleOnUpdateArticoliInUscita = (e: TableSubject) => {
    const articoloUscita = e as ArticoloUscitaIntervento;
    dispatch({ type: 'EDIT_ARTICOLO_OUT', articoloUscita, coltura: colturaSelectedRow || {} as ColturaModel, idIntervento: interventoSelectedRow?.idIntervento?? "" });
    navigateTo(ARTICOLO_USCITA)
  };

  const handleOnDeleteArticoliInUscita = (e: TableSubject) => {
    const articoloUscita = e as ArticoloUscitaIntervento;
    if (articoloUscita && interventoSelectedRow) {
      deleteObject(`articolouscita/${articoloUscita.idArticoloUscitaIntervento}`)
      dispatch({ type: 'DELETE_ARTICOLO_OUT', id: articoloUscita.idArticoloUscitaIntervento });
    }
  };

  /// MACCHINARI
  const handleOnCreateMacchinari = () => {
    dispatch({
      type: 'NUOVA_MACCHINA',
      colturaRow: colturaSelectedRow || initialStateColtura,
      idIntervento: interventoSelectedRow?.idIntervento ?? ""
    });
    navigateTo(NUOVA_MACCHINA)
  };

  const handleOnUpdateMacchinari = (e: TableSubject) => {
    const macchina = e as MacchinaIntervento;
    dispatch({ type: 'EDIT_MACCHINA', macchina, idIntervento: interventoSelectedRow?.idIntervento || "", coltura: colturaSelectedRow || {} as ColturaModel });
  };

  const handleOnDeleteMacchinari = (e: TableSubject) => {
    const macchina = e as MacchinaIntervento;
    if (macchina && interventoSelectedRow) {
      deleteObject(`macchinaintervento/${macchina.idMacchinaIntervento}`)
      dispatch({ type: 'DELETE_MACCHINA', id: macchina.idMacchinaIntervento });
    }
  };

  /// PERSONALE
  const handleOnCreatePersonale = () => {
    dispatch({
      type: 'NUOVA_PERSONA',
      colturaRow: colturaSelectedRow || initialStateColtura,
      idIntervento: interventoSelectedRow?.idIntervento ?? ""
    });
    navigateTo(NUOVA_PERSONA)
  };

  const handleOnUpdatePersonale = (e: TableSubject) => {
    const persona = e as PersonaIntervento;
    dispatch({ type: 'EDIT_PERSONA', persona, idIntervento: interventoSelectedRow?.idIntervento || "", coltura: colturaSelectedRow || {} as ColturaModel });
    navigateTo(PERSONA)
  };

  const handleOnDeletePersonale = (e: TableSubject) => {
    const persona = e as PersonaIntervento;
    if (persona && interventoSelectedRow) {
      deleteObject(`personaintervento/${persona.idPersonaIntervento}`)
      dispatch({ type: 'DELETE_PERSONA', id: persona.idPersonaIntervento });
    }
  };
  // useLog(nuovoArticoloIngresso)
  /// RILEVAZIONI
  const handleOnCreateRilevazioni = () => {
    dispatch({
      type: 'NUOVA_RILEVAZIONE',
      colturaRow: colturaSelectedRow || initialStateColtura,
      idIntervento: interventoSelectedRow?.idIntervento ?? ""
    });
    navigateTo(NUOVA_RILEVAZIONE)
  };

  const handleOnUpdateRilevazioni = (e: TableSubject) => {
    const rilevazione = e as RilevazioneModel;
    dispatch({ type: 'EDIT_RILEVAZIONE', rilevazione });
  };

  const handleOnDeleteRilevazioni = (e: TableSubject) => {
    const rilevazione = e as RilevazioneModel;
    if (rilevazione && interventoSelectedRow) {
      dispatch({ type: 'DELETE_RILEVAZIONE', id: rilevazione.idRilevazione });
    }
  };
  useEffect(() => {
  }, [datiReparto, coltureCorrenti])
  useEffect(() => {
    if(datiReparto?.idReparto) {
      promiseColtura(datiReparto.idReparto).then((coltura : ColturaModel[]) => {
        dispatch({ type: 'EDIT_REPARTO', reparto: datiReparto, colture: coltura, idUnitaProduttiva: unitaProduttivaAttiva?.idUnitaProduttiva || "" });
      })
    }
  }, [route.COLTURA])

  useEffect(() => {
    if (loginRes && loginRes.azienda) {
      if (role === "azienda") {
        promiseUnitaProduttive(loginRes.azienda).then((x : UnitaProduttiva[]) => {
          setUnitaProduttive(x)
        })
      } else if (role === "operatore_gestionale") {
        if (loginRes && loginRes.unitaProduttiva) {
          promiseUnitaProduttiva(loginRes.azienda, loginRes.unitaProduttiva).then((x : UnitaProduttiva[]) => {
            setUnitaProduttiva(x)
          })
        }
      }
    }
  }, [route.HOME, route.REPARTO])

  const [modal, toggle] = useModali({
    title: "Errore",
    animated: true,
    closeButton: true
  });

  return (
    <>
      <ErrorModal prom={interventoPostato} />
      <section className="section">
        <div className="columns">
          <div className="column is-one-quarter custom-card-light is-cm-fullheight">
            <div className="has-animation">
              {role !== "" && role === "azienda" && (
                <>
                  {unitaProduttive && unitaProduttive.map((d: UnitaProduttiva, i: number) => {
                    const reparti: RepartoModel[] = d.reparti;
                    return (
                      <>
                        <span key={i} className={`is-sidebar-listitem has-text-grey is-clickable ${unitaProduttivaAttiva?.idUnitaProduttiva === d.idUnitaProduttiva ? 'active-element' : ''}`} onClick={() => { handleClickUnitaProduttiva(d); }}><FontAwesomeIcon className="is-sidebar-listitem-icon" icon={faMapMarkerAlt} size="lg" />{d.nomeUnita}</span>
                        {reparti.map((r: RepartoModel, j: number) => {
                          return (
                            <span className={`is-sidebar-child-listitem ${datiReparto?.idReparto === r.idReparto || colturaSelectedRow?.idReparto === r.idReparto ? 'active-element' : ''}`} key={j} onClick={(e) => { handleClickReparto(r, d); }} >{r.nomeReparto}</span>
                          );
                        })}
                        <span
                          className="is-sidebar-child-listitem has-text-weight-semibold"
                          key="span-0"
                          onClick={() => { handleClickNuovoReparto(d.idUnitaProduttiva); }}>
                          <FontAwesomeIcon className="is-sidebar-listitem-icon" icon={faPlus} size="sm" />Nuovo reparto
                        </span>
                      </>
                    );
                  })}
                </>
              )}
              {role === "operatore_gestionale" && unitaProduttiva && unitaProduttiva[0] !== undefined && (
                <>
                  <>
                    <span className={`is-sidebar-listitem has-text-grey is-clickable ${unitaProduttivaAttiva?.idUnitaProduttiva === unitaProduttiva[0].idUnitaProduttiva ? 'active-element' : ''}`} onClick={() => { handleClickUnitaProduttiva(unitaProduttiva[0]); }}><FontAwesomeIcon className="is-sidebar-listitem-icon" icon={faMapMarkerAlt} size="lg" />{unitaProduttiva[0].nomeUnita}</span>
                    {unitaProduttiva[0].reparti.map((r: RepartoModel, j: number) => {
                      return (
                        <span className={`is-sidebar-child-listitem ${datiReparto?.idReparto === r.idReparto || colturaSelectedRow?.idReparto === r.idReparto ? 'active-element' : ''}`} key={j} onClick={(e) => { handleClickReparto(r, unitaProduttiva[0]); }} >{r.nomeReparto}</span>
                      );
                    })}
                    <span
                      className="is-sidebar-child-listitem has-text-weight-semibold"
                      key="span-0"
                      onClick={() => { handleClickNuovoReparto(unitaProduttiva[0].idUnitaProduttiva); }}>
                      <FontAwesomeIcon className="is-sidebar-listitem-icon" icon={faPlus} size="sm" />Nuovo reparto
                    </span>
                  </>
                </>
              )}
              {role !== "" && role === "opeartore_stanza" && (
                <></>
              )}
            </div>
            {(unitaProduttive.length < 1 && unitaProduttive === null) && (
              <span className="is-sidebar-listitem is-clickable has-text-weight-semibold" key="span_0" onClick={handleClickNuovaUnitaProduttiva}><FontAwesomeIcon className="is-sidebar-listitem-icon" icon={faPlus} size="lg" />Nuova unità produttiva</span>
            )}
          </div>

          {route.REPARTO && datiReparto && coltureCorrenti && (
            <div className="column custom-card-light has-animation">
              <Reparto
                reparto={datiReparto}
                onUpdate={handleOnUpdateRowColtura}
                colture={coltureCorrenti}
                onCreate={handleOnCreateRowColtura}
                onDelete={handleOnDeleteRowColtura}
                onRefresh={reloadUnita} />
            </div>
          )}

          { route.UNITA_PRODUTTIVA_VUOTA && unitaProduttivaAttiva && (
            <div className="column custom-card-light has-animation">
              <UnitaProduttivaVuota unitaProduttiva={unitaProduttivaAttiva} onCreate={reloadUnita} />
            </div>
          )}

          {route.NUOVA_UNITA_PRODUTTIVA && nuovaUnitaProduttiva && (
            <div className="column custom-card-light has-animation">
              <NuovaUnitaProduttiva onCreate={reloadUnita} />
            </div>
          )}
          {route.NUOVO_REPARTO && idUnitaProduttivaCorrente && (
            <div className="column custom-card-light has-animation">
              <NuovoReparto onCreate={reloadUnita} idUnitaProduttiva={idUnitaProduttivaCorrente} />
            </div>
          )}

          {route.COLTURA && colturaSelectedRow && (
            <div className="column custom-card-light has-animation">
              <Coltura 
                coltura={colturaSelectedRow} 
                onUpdate={handleOnUpdateRowIntervento}
                onCreate={handleOnCreateRowIntervento}
                onDelete={handleOnDeleteRowIntervento}
                handlePostColtura={handleUpdateRowColtura}
                handleDeleteColtura={handleOnDeleteRowColtura} />
            </div>
          )}

          {route.INTERVENTO && interventoSelectedRow && (
            <div className="column custom-card-light has-animation">
              <Intervento
                handlePostIntervento={handleOnEditRowIntervento}
                handleDeleteIntervento={handleOnDeleteRowIntervento}
                intervento={interventoSelectedRow}
                eventiArticoliInIngresso={{ handleOnCreateArticoliInIngresso, handleOnUpdateArticoliInIngresso, handleOnDeleteArticoliInIngresso }}
                eventiArticoliInUscita={{ handleOnCreateArticoliInUscita, handleOnUpdateArticoliInUscita, handleOnDeleteArticoliInUscita }}
                eventiMacchinari={{ handleOnCreateMacchinari, handleOnUpdateMacchinari, handleOnDeleteMacchinari }}
                eventiPersonale={{ handleOnCreatePersonale, handleOnUpdatePersonale, handleOnDeletePersonale }}
                eventiRilevazioni={{ handleOnCreateRilevazioni, handleOnUpdateRilevazioni, handleOnDeleteRilevazioni }}
              />
            </div>
          )}

          {route.NUOVO_INTERVENTO && nuovoIntervento && (
            <div className="column custom-card-light has-animation">
              <NuovoIntervento coltura={nuovoIntervento} onCreate={(id) => reloadColtura(id)}/>
            </div>
          )}

          {route.NUOVA_COLTURA && nuovaColtura && (
            <div className="column custom-card-light has-animation">
              <NuovaColtura idReparto={nuovaColtura} onCreate={reloadUnita} />
            </div>
          )}

          {route.ARTICOLO_INGRESSO && modificaArticoloIngresso && (
            <div className="column custom-card-light has-animation">
              <ArticoloIngresso onSubmit={c => reloadColtura(c.idReparto)} />
            </div>
          )}

          {route.NUOVO_ARTICOLO_INGRESSO && nuovoArticoloIngresso && (
            <div className="column custom-card-light has-animation">
              <NuovoArticoloIngresso onCreate={c => reloadColtura(c.idReparto)} />
            </div>
          )}

          {route.ARTICOLO_USCITA && modificaArticoloUscita && (
            <div className="column custom-card-light has-animation">
              <ArticoloUscita onSubmit={c => reloadColtura(c.idReparto)}/>
            </div>
          )}

          {route.NUOVO_ARTICOLO_USCITA && nuovoArticoloUscita && (
            <div className="column custom-card-light has-animation">
              <NuovoArticoloUscita onCreate={c => reloadColtura(c.idReparto)} />
            </div>
          )}

          {route.MACCHINA && modificaMacchina && (
            <div className="column custom-card-light has-animation">
              <Macchina />
            </div>
          )}

          {route.NUOVA_MACCHINA && nuovaMacchina && (
            <div className="column custom-card-light has-animation">
              <NuovaMacchina />
            </div>
          )}

          {route.PERSONA && modificaPersona && (
            <div className="column custom-card-light has-animation">
              <Persona />
            </div>
          )}

          {route.NUOVA_PERSONA && nuovaPersona && (
            <div className="column custom-card-light has-animation">
              <NuovaPersona onCreate={c => reloadColtura(c.idReparto)}/>
            </div>
          )}

          {route.RILEVAZIONE && modificaRilevazione && (
            <div className="column custom-card-light has-animation">
              <Rilevazione />
            </div>
          )}

          {route.NUOVA_RILEVAZIONE && nuovaRilevazione && (
            <div className="column custom-card-light has-animation">
              <NuovaRilevazione />
            </div>
          )}
        </div>
      </section>
      <Modali.Modal {...modal}>
        <p>Si sono verificati degli errori: </p>
        <br/>
        Non è possibile eliminare questo articolo, sono presenti articoli in uscita collegati.
      </Modali.Modal>
    </>
  );
};

export default Azienda;
