import * as _ from 'ramda';
import { Reducer } from 'redux';
import { Rilevazione, ColturaModel } from '../Containers/Azienda/Coltura/Model';

export type State = {
  rilevazione: Rilevazione | null
  idIntervento: string,
  nuovaRilevazione?: ColturaModel
};

export type Action = {
  type: 'NUOVA_RILEVAZIONE'
  colturaRow: ColturaModel,
  idIntervento: string
} | {
  type: 'EDIT_RILEVAZIONE'
  rilevazione: Rilevazione
};

export const defaultState: State 
  = { rilevazione: null, idIntervento: "" };

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'NUOVA_RILEVAZIONE':
      if (state.rilevazione !== null) {
        return {
          ...state,
          // rilevazione: null,
          nuovaRilevazione: payload.colturaRow,
          idIntervento: payload.idIntervento
        };
      } else return state;
    case 'EDIT_RILEVAZIONE':
      return { 
        ...state,
        rilevazione: payload.rilevazione,
        idIntervento: ""
      };
    default: return state;
  }
};
