import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faCalendarDay } from '@fortawesome/free-solid-svg-icons'

const Orologio: React.FC<{
  visualizzaStorico: () => void
  storicoAbilitato: boolean
}> = ({ visualizzaStorico, storicoAbilitato }) => {

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setInterval(() => {
      setDate(new Date())
    }, 3600)
  }, [])

  return (
    <div className="card is-fixed-bottom">
      <div className="card-content">
        <FontAwesomeIcon icon={faClock} className="has-10-margin-right" />
        {moment(date).format('HH:mm')}
        <br/>
        {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
        <FontAwesomeIcon icon={faCalendarDay} className="has-10-margin-right" />
        {moment(date).format('DD/MM/YYYY')}
        <br/>
        <a href="#" className={storicoAbilitato ? "is-link-disabled" : ""} onClick={visualizzaStorico}>Visualizza storico</a>
      </div>
    </div>
  )
}

export default Orologio