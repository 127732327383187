import React, { useState, useEffect } from 'react'
import './TimeManagement.css'
import Produzione from './produzione/produzione'
import PersonaleImpiegato from './personale/impiegato/personaleimpiegato'
import { fetchObjects, ID_AZIENDA, ID_UNITA, postObject } from '../../Utilities/SharedComponent'
import { ColturaModel, PersonaIntervento, Persona, InterventoModel } from '../Azienda/Coltura/Model'
import { RepartoModel, UnitaProduttiva } from '../Azienda/Model'
import { flatMap, assert } from '../../Utilities/Functions'
import * as _ from 'ramda'
import PersonaleDisponibile from './personale/disponibile/personaledisponibile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faUsers, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import * as uuid from "uuid"
import BurgerSchermataRapida from '../../Components/Sidebar/BurgerSchermataRapida'
import { State } from 'react-burger-menu'
import StoricoCheckIn from './storico/storicocheckin'
import Header from '../../Components/Header/Header'



const TimeManagement: React.FC = () => {
  const promiseUnitaProduttive = () => fetchObjects(`${ID_AZIENDA}/unitaproduttive`)
  const promiseColture = (idReparto?: string) => fetchObjects(`colture/${idReparto}`)
  const promisePersone = () => fetchObjects(`persone/${ID_UNITA}`)

  const [menuState, setMenuState] = useState(false)
  const [colture, setColture] = useState<ColturaModel[]>([])
  const [reparti, setReparti] = useState<RepartoModel[]>([])
  const [persone, setPersone] = useState<PersonaIntervento[]>([])
  const [personaleDisponibile, setPersonaleDisponibile] = useState<Persona[]>()
  const [personaleOccupato, setPersonaleOccupato] = useState<PersonaIntervento[]>([])
  const [interventoSelezionato, setInterventoSelezionato] = useState<InterventoModel>()

  useEffect(() => {
    getColture()
  }, [])

  // recupero tutte le colture di tutti i reparti di tutte le unità produttive associate all'azienda loggata
  async function getColture() {
    const ups: UnitaProduttiva[] = await promiseUnitaProduttive()
    if (ups) {
      const rep: RepartoModel[] = flatMap(ups, x => x.reparti)
      setReparti(rep)
  
      const colt: ColturaModel[] = _.flatten(await Promise.all(rep.map(r => promiseColture(r.idReparto))))
      const per: Persona[] = await promisePersone()
  
      // recupero le persone disponibili per l'intervento selezionato
      const persone: PersonaIntervento[] =
        flatMap(colt, coltura =>
          flatMap(coltura.interventi, intervento =>
            intervento.persone.filter(p => p.dataFine === null || p.dataFine === undefined)))
  
      const personeDisponibili = per.filter(persona => !persone.some(p => p.idPersona === persona.idPersona))
      setPersonaleDisponibile(personeDisponibili)
  
      if (!_.isEmpty(colt)) {
        setColture(colt)
      }
    }
  }

  useEffect(() => {
    refreshPersonaleOccupato()
    setPersone(interventoSelezionato?.persone ?? [])
  }, [interventoSelezionato])

  const refreshPersonaleOccupato = () => {
    const personeOccupate = interventoSelezionato?.persone.filter(persona => persona.dataFine === undefined || persona.dataFine === null || persona.dataFine === "")
    if (personeOccupate) {
      setPersonaleOccupato(personeOccupate)
    }
  }

  const handleIngressoPersona = (p: Persona) => {
    const personaIntervento: PersonaIntervento = {
      idPersonaIntervento: uuid.v4(),
      idPersona: p.idPersona,
      costoOrario: p.costoOrario,
      nome: p.nomePersona,
      dataFine: "",
      dataInizio: moment().toISOString(),
      tempo: 0,
      costoTotale: 0
    }
    assert(interventoSelezionato)

    setPersonaleDisponibile(personaleDisponibile?.filter(persona => persona.idPersona !== p.idPersona))

    // aggiorno la coltura con la nuova persona con il campo `dataInizio` valorizzato
    const coltura =
      colture.find(coltura =>
        coltura.interventi.findIndex(intervento =>
          intervento.idIntervento === interventoSelezionato?.idIntervento) > -1)
    interventoSelezionato.persone.push(personaIntervento)
    if (coltura) {
      const indexIntervento = coltura.interventi.findIndex(i => i.idIntervento === interventoSelezionato.idIntervento)
      coltura.interventi.splice(indexIntervento, 1)
      coltura.interventi.push(interventoSelezionato)
  
      postObject(`colture/${coltura.idReparto}`, coltura)
        .then(_ => refreshPersonaleOccupato())
        .catch(_ => alert('Si è verificato un errore, si prega di riprovare più tardi!'))
    }
  }
  
  const handleUscitaPersona = (p: PersonaIntervento) => {
    const s = moment(p.dataInizio)
    const e = moment()
    if (!_.isNil(e) && !_.isNil(s)) {
      const diff = e.diff(s)
      const m = moment.duration(diff)
      const personaIntervento: PersonaIntervento = {
        ...p,
        dataFine: moment().toISOString(),
        tempo: m.asMinutes()
      }  
      assert(interventoSelezionato)
  
      const idPersona = interventoSelezionato.persone.findIndex(persona => persona.idPersonaIntervento === p.idPersonaIntervento)
      if (idPersona > -1) {
        interventoSelezionato.persone.splice(idPersona, 1)
        interventoSelezionato.persone.push(personaIntervento)
    
        const coltura =
          colture.find(coltura =>
            coltura.interventi.findIndex(intervento =>
              intervento.idIntervento === interventoSelezionato?.idIntervento) > -1)
    
        postObject(`colture/${coltura?.idReparto}`, coltura)
          .then(_ => {
            refreshPersonaleOccupato()
            getColture()
          })
          .catch(_ => alert('Si è verificato un errore, si prega di riprovare più tardi!'))
      }
    }
  }

  const body = (
    <>
      <Header />
      <div className="section is-fullheight-tablet">
        <div className="columns is-fullheight-tablet">

          {/* produzione */}
          <div className="column is-one-quarter has-border-right-green">
            <Produzione 
              colture={colture} 
              reparti={reparti} 
              interventoSelezionato={i => setInterventoSelezionato(i)} 
              visualizzaStorico={() => setMenuState(!menuState)}
              />
          </div>

          {interventoSelezionato ? (
            <div className="column">
              {/* personale impiegato nella coltura */}
              <h1 className="title is-4 has-text-centered has-margin-title">
                <FontAwesomeIcon icon={faUsers} size="1x" className="has-10-margin-right" />
                Personale impiegato in questa coltura
              </h1>
              {personaleOccupato && personaleOccupato && (
                <PersonaleImpiegato personaleOccupato={personaleOccupato} uscitaPersona={handleUscitaPersona} />
              )}
              {/* <PersonaleImpiegato personaleOccupato={personaleOccupato} /> */}

              {/* personale disponibile per la coltura */}
              <h1 className="title is-4 has-text-centered has-margin-title">
                <FontAwesomeIcon icon={faTasks} size="1x" className="has-10-margin-right" />
                Personale disponibile
              </h1>
              {personaleDisponibile && (
                <PersonaleDisponibile personaleDisponibile={personaleDisponibile} ingressoPersona={handleIngressoPersona} />
              )}
            </div>
          ) : (
            <div className="column has-30-margin-top">
              <h1 className="title has-text-centered">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </h1>
              <h1 className="subtitle has-text-centered">
                Seleziona una coltura nella lista di sinistra
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  )

  const onStateChange = (burgerState: State) => {
    setMenuState(burgerState.isOpen);
  }

  return (
    <div id="outer-container">
      <BurgerSchermataRapida outerContainerId="outer-container" pageWrapId="page-wrap" isOpen={menuState} onStateChange={onStateChange} >
        <StoricoCheckIn persone={persone} />
      </BurgerSchermataRapida>
      <div id="page-wrap">
        {body}
      </div>
    </div>
  )
}

export default TimeManagement