import { ID_AZIENDA } from "../../../Utilities/SharedComponent";

export type ModalitaIntervento
  = "standard"
  | "fitosanitari";

export interface TipoIntervento {
  idTipoIntervento?: string;
  // idAzienda: string;
  descrizione: string;
  articoliIngresso: boolean;
  articoliUscita: boolean;
  utilizzoMacchine: boolean;
  utilizzoPersone: boolean;
  rilevazioni: boolean;
  registraBlockChain: boolean;
  tipiArticoloIngresso: string[];
  tipiArticoloUscita: string[];
  costoFisso: number;
  rilevazioniAssociate: TipoRilevazione[];
  modalita: string;
}

export const initialStateTipoIntervento: Omit<TipoIntervento, "idTipoIntervento"> = {
  // idAzienda: ID_AZIENDA ?? ""
  descrizione: ""
, articoliIngresso: false
, articoliUscita: false
, utilizzoMacchine: false
, utilizzoPersone: false
, rilevazioni: false
, registraBlockChain: false
, tipiArticoloIngresso: []
, tipiArticoloUscita: []
, costoFisso: 0
, rilevazioniAssociate: []
, modalita: "standard"
};

// export interface ArticoloIngressoInterventoNew {
//   idArticolo: string;
//   dataUtilizzo: string;
//   descrizione: string;
//   unitaDiMisura: string;
//   quantita: number;
//   idFornitore: string;
//   costoUnitario: number;
//   lotto: string;
//   rilevazioni: Rilevazione[];
// }

export type ArticoloIngressoInterventoNew = {
  codiceInternoArticolo: string
  codiceArticolo: string
  descrizioneArticolo: string
  codiceFornitore: string
  nomeFornitore: string
  dataUtilizzo: string
  unitaDiMisuraAcquisto: string
  quantita: number
  costoUnitario: number
  lotto: string
  giorniCarenza: number
  costoTotale: number
  rilevazioni: Rilevazione[]
}

export type ArticoloIngressoIntervento = ArticoloIngressoInterventoNew & { idArticoloIngressoIntervento: string };

export const initialStateArticoloIngressoIntervento: ArticoloIngressoInterventoNew = {
  codiceInternoArticolo: ""
, codiceArticolo: ""
, descrizioneArticolo: ""
, codiceFornitore: ""
, nomeFornitore: ""
, dataUtilizzo: ""
, unitaDiMisuraAcquisto: ""
, quantita: 0
, costoUnitario: 0
, lotto: ""
, giorniCarenza: 0
, costoTotale: 0
, rilevazioni: []
}

// export interface ArticoloUscitaInterventoNew {
//   idArticolo: string;
//   data: string;
//   descrizione: string;
//   unitaDiMisura: string;
//   quantita: number;
//   colli: number;
//   rilevazioni: Rilevazione[];
// }

export type ArticoloUscitaInterventoNew = {
  idPersona: string
  codiceInternoArticolo: string
  codiceArticolo: string
  descrizioneArticolo: string
  data: string
  unitaDiMisuraVendita: string
  quantita: number
  colli: number
  aliquota: number
  numeroListino: number
  calibro?: string
  listinoIvato: number
  coefficienteConversione: number
  rilevazioni: Rilevazione[]
}

export type ArticoloUscitaIntervento = ArticoloUscitaInterventoNew & { idArticoloUscitaIntervento: string };

export const initialStateArticoloUscitaIntervento: ArticoloUscitaInterventoNew = {
  idPersona: ""
, codiceInternoArticolo: ""
, codiceArticolo: ""
, descrizioneArticolo: ""
, data: ""
, unitaDiMisuraVendita: ""
, quantita: 0
, colli: 0
, aliquota: 0
, numeroListino: 0
, calibro: ""
, listinoIvato: 0
, coefficienteConversione: 0
, rilevazioni: []
};

export interface Macchina {
  idMacchina: string;
  codiceMacchina: string;
  costoFisso: number;
  nomeMacchina: string;
  costoOrario: number;
  matricola: string;
}

export type Sesso = "F" | "M" | "NS"

export interface Persona {
  idPersona: string;
  codicePersona: string;
  nomePersona: string;
  costoOrario: number;
  sesso: Sesso
}

export interface MacchinaInterventoNew {
  idMacchina: string;
  dataInizio: string;
  dataFine: string;
  tempo: number;
  descrizione: string;
  costoFisso: number;
  costoOrario: number;
  costoTotale: number
}

export type MacchinaIntervento = MacchinaInterventoNew & { idMacchinaIntervento: string };

export const initialStateMacchinaIntervento: MacchinaInterventoNew = {
  idMacchina: ""
, dataInizio: ""
, descrizione: ""
, dataFine: ""
, tempo: 0
, costoFisso: 0
, costoOrario: 0
, costoTotale: 0
};

export interface PersonaInterventoNew {
  idPersona: string;
  dataInizio: string;
  dataFine: string;
  tempo: number;
  nome: string;
  costoOrario: number;
  costoTotale: number
}

export type PersonaIntervento = PersonaInterventoNew & { idPersonaIntervento: string };

export const initialStatePersonaIntervento: PersonaInterventoNew = {
  idPersona: ""
, dataInizio: ""
, dataFine: ""
, nome: ""
, tempo: 0
, costoOrario: 0
, costoTotale: 0
};

export type TagRilevazione
  = "articoloIngresso"
  | "articoloUscita"
  | "intervento";

export type TagTipoRilevazioneEstesa
  = "Intervento"
  | "intervento"
  | { articolo: string };

export interface TipoRilevazione {
  idTipoRilevazione: string;
  // idAzienda: string;
  descrizione: string;
  tipoValore: 'string' | 'boolean' | 'decimal' | 'dataOra' | "Decimal" | "DataOra";
  tag: TagTipoRilevazioneEstesa;
  obbligatoria: boolean;
  unitaDiMisura: string
}

export interface RilevazioneNew {
  id: string;
  tag: TagRilevazione;
  tipoRilevazione: TipoRilevazione;
  posizione: number;
  valore: string;
  unitaDiMisura: string;
  note: string | undefined;
}

export type Rilevazione = RilevazioneNew & { idRilevazione: string };

export const initialStateRilevazione: Rilevazione = {
  idRilevazione: ""
, id: ""
, tag: "intervento"
, tipoRilevazione: {} as TipoRilevazione
, posizione: 0
, valore: ""
, unitaDiMisura: ""
, note: ""
};

export type StatoIntervento
  = "programmato"
  | "inCorso"
  | "concluso";

export interface InterventoModelNew {
  tipoIntervento: TipoIntervento;
  posizione: number;
  articoliIngresso: ArticoloIngressoIntervento[];
  articoliUscita: ArticoloUscitaIntervento[];
  macchine: MacchinaIntervento[];
  persone: PersonaIntervento[];
  rilevazioni: Rilevazione[];
  dataInizioPrevisto: string;
  dataFinePrevista: string;
  previsto: boolean;
  dataInizioReale: string;
  dataFineReale: string;
  stato: StatoIntervento;
  carenza: string;
  causaleTrattamento: string;
  annotazioniPubbliche: string;
  annotazioniPrivate: string;
  costoIntervento: number;
}

export type InterventoModel = InterventoModelNew & { idIntervento?: string; };

export const initialStateIntervento: Omit<InterventoModelNew, "idIntervento"> = {
  tipoIntervento: {} as TipoIntervento
, articoliIngresso: []
, articoliUscita: []
, macchine: []
, persone: []
, rilevazioni: []
, posizione: 0
, dataInizioPrevisto: ""
, dataFinePrevista: ""
, previsto: false
, dataInizioReale: ""
, dataFineReale: ""
, stato: "programmato"
, carenza: ""
, causaleTrattamento: ""
, annotazioniPubbliche: ""
, annotazioniPrivate: ""
, costoIntervento: 0
};

export interface Blocco {
  dataInizio : string;
  dataFine : string;
}

export interface ColturaModel {
  idColtura: string;
  idReparto: string;
  descrizione: string;
  interventi: InterventoModel[];
  dataInizioPrevisto: string;
  dataFinePrevista: string;
  stato: StatoIntervento;
  dataInizioReale: string;
  dataFineReale: string;
  superficieImpegnata: number;
  lotto: string;
  note: string;
  blocchi: Blocco[]
}

export const initialStateColtura: ColturaModel = {
  idColtura: ""
  , descrizione: ""
  , idReparto: ""
  , interventi: []
  , dataInizioPrevisto: ""
  , dataFinePrevista: ""
  , stato: "programmato"
  , dataInizioReale: ""
  , dataFineReale: ""
  , superficieImpegnata: 0
  , lotto: ""
  , note: ""
  , blocchi: []
};

// export type Articolo = {
//   idArticolo: string
//   codiceArticolo: string
//   idAzienda: string
//   descrizione: string
//   alias: string
//   tipoArticolo: string
//   giorniCarenza: number
//   unitaDiMisura: string
// };

export interface CampoLibero {
  valore: string
  nomeCampo: string
}

export type Articolo = {
  codiceInternoArticolo: string
  codiceArticolo: string
  idAzienda: string
  descrizione: string
  alias: string
  tipoArticolo: string // "Nessuno" | "Fitosanitario" | "MateriaPrima" | "ProdottoFinito"
  giorniCarenza: number
  unitaDiMisuraAcquisto: string
  unitaDiMisuraVendita: string
  aliquota: number
  numeroListino: number
  listinoIvato: number
  // dataInizio: string
  // dataFine: string
  coefficienteConversione: number
  campiLiberi?: CampoLibero[]
}

export const initialStateArticolo = {
  codiceInternoArticolo: "",
  codiceArticolo: "",
  idAzienda: "",
  descrizione: "",
  alias: "",
  tipoArticolo: "",
  giorniCarenza: 0,
  unitaDiMisuraAcquisto: "",
  unitaDiMisuraVendita: "",
  aliquota: 0,
  numeroListino: 0,
  listinoIvato: 0,
  coefficienteConversione: 0,
  campiLiberi: []
}

// export type Fornitore = {
//   idFornitore: string
//   codiceFornitore: string
//   idAzienda: string
//   ragioneSociale: string
//   alias: string
//   telefono: string
//   mail: string
//   annotazioniPubbliche: string
//   annotazioniPrivate: string
// };

export type Fornitore = {
  codiceFornitore: string
  idAzienda: string
  ragioneSociale: string
  alias: string
  telefono?: string
  mail?: string
  annotazioniPubbliche?: string
  annotazioniPrivate: string
}

export type UnitaMisura = {
  um: string
  descrizione: string
  aPeso: boolean
}