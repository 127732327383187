import React, { useEffect, useState } from 'react'
import { faArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Articolo, InterventoModel } from '../../Azienda/Coltura/Model'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import { it } from 'date-fns/locale'

const LabelColli: React.FC<{
  value: string
  data: any
  setData: (d: any) => void
  modifica: boolean
  articoloSelezionato: Articolo | undefined
  interventoSelezionato: InterventoModel | undefined
  articoloModificato?: Articolo
}> = ({ value, articoloSelezionato, modifica, data, setData, interventoSelezionato, articoloModificato }) => {

  return (
    <>
      <div className={`has-text-centered is-label-colli ${modifica ? 'has-warning-background' : ''}`}>

        <div className="field is-horizontal" style={{ marginBottom: 0, paddingBottom: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
          <div className="field-label is-normal">
            <label className="label">DATA</label>
          </div>
          {interventoSelezionato ? (
            <DatePicker 
              minimumDate={new Date(interventoSelezionato.dataInizioReale)}
              maximumDate={new Date(interventoSelezionato.dataFineReale)}
              date={data} 
              onDateChange={setData} 
              locale={it}>
              {({ inputProps, focused }) => (
                <input className={'input' + (focused ? ' -focused' : '')}
                {...inputProps}
                />
              )}
            </DatePicker>
          ) : (
            <DatePicker 

              date={data} 
              onDateChange={setData} 
              locale={it}>
              {({ inputProps, focused }) => (
                <input className={'input' + (focused ? ' -focused' : '')}
                {...inputProps}
                />
              )}
            </DatePicker>
          )}
        </div>

        <div className="field is-horizontal" style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
          <div className="field-label is-normal">
            <label className="label">COLLI</label>
          </div>
          <p className="control has-icons-left">
            <input value={value !== "" ? value : "0"} className="input is-input-colli" type="text" min="0" step="1" disabled />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faArchive} />
            </span>
          </p>
        </div>
      </div>
    <div className="has-text-centered is-label-colli-no-background">
      <div className="field is-horizontal" style={{ padding: '0' }}>
        <div className="field-label is-normal" style={{ textAlign: 'left' }}>
          <label className="label">Coefficiente conversione {articoloSelezionato?.unitaDiMisuraVendita ? articoloSelezionato?.unitaDiMisuraVendita !== "" ? `(${articoloSelezionato?.unitaDiMisuraVendita})` : "" : ""}</label>
        </div>
        {articoloModificato ? (
          <p className="control" style={{ paddingTop: "0.375em"}}>{articoloModificato.coefficienteConversione}</p>
        ) : (
          <p className="control" style={{ paddingTop: "0.375em"}}>{articoloSelezionato?.coefficienteConversione ?? 0}</p>
        )}
      </div>
    </div>
    </>
  )
}

export default LabelColli