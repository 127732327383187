import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { produce } from "immer";
import React, { useState } from "react";
import {
  BasicDropdown,
  BasicInput,
  BasicTextArea,
  ErrorModal
} from "../../../Utilities/SharedComponent";
import { ColturaModel, StatoIntervento, InterventoModel } from "./Model";
import { useDispatch } from "../../../Utilities/SharedHooks/State";
import { UNDO_ACTION, UNDO, AllActions } from "../../../Reducers/Root";
import DateInfo from "../../../Components/DatesInfo/DateInfo";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import { navigateBack } from "../../../Reducers/Route";

type ColturaModelLocal = Omit<ColturaModel, "idColtura">;

const NuovaColtura: React.FC<{ idReparto: string, onCreate: () => void }> = ({ idReparto, onCreate }) => {
  const colturaVuota: ColturaModelLocal = {
    descrizione: "",
    idReparto: "",
    interventi: [],
    dataInizioPrevisto: "",
    dataFinePrevista: "",
    stato: "programmato",
    dataInizioReale: "",
    dataFineReale: "",
    superficieImpegnata: 0,
    lotto: "",
    note: "",
    blocchi: []
  };

  const dispatch = useDispatch<AllActions>();

  const [nuovaColtura, setNuovaColtura] = useState<ColturaModelLocal>(
    colturaVuota
  );

  const [postColtura, colturaPostata] = usePost<ColturaModelLocal>(`colture/${idReparto}`);

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    const _nuovaColtura: ColturaModelLocal = {
      descrizione: nuovaColtura.descrizione,
      idReparto,
      interventi: [] as InterventoModel[],
      dataInizioPrevisto: nuovaColtura.dataInizioPrevisto || "",
      dataFinePrevista: nuovaColtura.dataFinePrevista || "",
      stato: nuovaColtura.stato,
      dataInizioReale: nuovaColtura.dataInizioReale || "",
      dataFineReale: nuovaColtura.dataFineReale || "",
      superficieImpegnata: nuovaColtura.superficieImpegnata,
      lotto: nuovaColtura.lotto,
      note: nuovaColtura.note,
      blocchi: nuovaColtura.blocchi
    };

    setNuovaColtura(_nuovaColtura);
    postColtura(_nuovaColtura).then(e => { 
      if(!e.isError){
        dispatch({ type: "ADD_COLTURA", coltura: { idColtura: "", ..._nuovaColtura } })
        dispatch(navigateBack())
        onCreate()
        setNuovaColtura(colturaVuota);
      }
    })
  };

  const handleTexboxChange = (fn: (v: ColturaModel) => void) => {
    setNuovaColtura(produce(nuovaColtura, fn));
  };

  return (
    <>
      <ErrorModal prom={colturaPostata} />
      <span>
        <FontAwesomeIcon
          className="is-sidebar-listitem-icon is-clickable"
          icon={faChevronLeft}
          onClick={() => dispatch(navigateBack())}
          size="lg"
        />
        <span className="subtitle has-text-grey">Nuova coltura</span>
      </span>
      <form onSubmit={handleFormSubmit}>
        <br />
        <div className="field is-horizontal">
          <BasicInput
            label="Descrizione"
            value={nuovaColtura.descrizione || ""}
            readOnly={false}
            onChange={e =>
              handleTexboxChange((p: ColturaModel) => {
                p.descrizione = e.target.value;
              })
            }
          />
        </div>
        <div className="columns">
          <div className="column">
            <BasicDropdown
              label={"Stato"}
              defaultValue="Seleziona stato"
              values={["programmato", "inCorso", "concluso"]}
              onSelection={e =>
                handleTexboxChange((p: ColturaModel) => {
                  p.stato = e.target.value as StatoIntervento;
                })
              }
            />
          </div>
          <div className="column">
            <BasicInput
              label="Lotto"
              value={nuovaColtura.lotto || ""}
              readOnly={false}
              onChange={e =>
                handleTexboxChange((p: ColturaModel) => {
                  p.lotto = e.target.value;
                })
              }
            />
          </div>
          <div className="column">
            <BasicInput
              label="Superficie"
              value={nuovaColtura.superficieImpegnata || ""}
              readOnly={false}
              onChange={e =>
                handleTexboxChange((p: ColturaModel) => {
                  p.superficieImpegnata = Number(e.target.value);
                })
              }
            />
          </div>
        </div>
        <div className="columns display-mobile-block">
          <div className="column">
            <DateInfo
              title="Data prevista"
              from={nuovaColtura.dataInizioPrevisto}
              to={nuovaColtura.dataFinePrevista}
              onFromChanged={e => {
                handleTexboxChange((p: ColturaModel) => {
                  p.dataInizioPrevisto = e.target.value;
                });
              }}
              onToChanged={e => {
                handleTexboxChange((p: ColturaModel) => {
                  p.dataFinePrevista = e.target.value;
                });
              }}
            ></DateInfo>
          </div>
          <div className="column">
            <DateInfo
              title="Data reale"
              from={nuovaColtura.dataInizioReale}
              to={nuovaColtura.dataFineReale}
              onFromChanged={e => {
                handleTexboxChange((p: ColturaModel) => {
                  p.dataInizioReale = e.target.value;
                });
              }}
              onToChanged={e => {
                handleTexboxChange((p: ColturaModel) => {
                  p.dataFineReale = e.target.value;
                });
              }}
            ></DateInfo>
          </div>
        </div>
        <BasicTextArea
          label="Annotazioni"
          value={nuovaColtura.note}
          readOnly={false}
          onChange={e =>
            handleTexboxChange((p: ColturaModel) => {
              p.note = e.target.value;
            })
          }
        />
        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Salva coltura
            </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default NuovaColtura;
