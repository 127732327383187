import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop } from '@fortawesome/free-solid-svg-icons'
import { fetchObjects, ID_UNITA } from '../../../../Utilities/SharedComponent'
import { PersonaIntervento, Persona } from '../../../Azienda/Coltura/Model'
import moment from 'moment'

const promisePersone = () => fetchObjects(`persone/${ID_UNITA}`)

const PersonaleImpiegato: React.FC<{
  personaleOccupato: PersonaIntervento[]
  uscitaPersona: (p: PersonaIntervento) => void
}> = ({ personaleOccupato, uscitaPersona }) => {
  
  const [persone, setPersone] = useState<Persona[]>()
  useEffect(() => {
    promisePersone()
      .then(res => setPersone(res))
  }, [])

  const getPersona = (id: string) => 
    persone?.find(p => p.idPersona === id)

  return (
    <div className="container is-scrollable-half-column">
    {personaleOccupato.map((persona, i) => persone && (
      <div className="tile is-ancestor is-marginless fade-from-right" key={i}>
        <div className="tile ">
          <div className="tile is-parent is-few-padding">
            <article className="tile is-child notification">
                <p className="is-inline has-10-margin-right">{getPersona(persona.idPersona)?.codicePersona}</p>
                <p className="is-inline has-10-margin-right">{persona.nome}</p>
                <small className="is-inline has-10-margin-right"><br />Orario di ingresso: {moment(persona.dataInizio).format('hh:mm:ss')}</small>
                <button className="button is-small is-warning is-pulled-right" onClick={_ => uscitaPersona(persona)} >
                  <span className="icon">
                    <FontAwesomeIcon icon={faStop} />   
                  </span>
                </button>
              </article>
          </div>
        </div>
      </div>
    ))}
  </div>
  )
}

export default PersonaleImpiegato