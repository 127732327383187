import * as _ from 'ramda';
import { Reducer } from 'redux';
import { ArticoloUscitaIntervento, ColturaModel } from '../Containers/Azienda/Coltura/Model';

export type State = {
  articoloUscita: ArticoloUscitaIntervento | null,
  idIntervento: string,
  nuovoArticoloUscita?: ColturaModel,
  coltura?: ColturaModel
};

export type Action = {
  type: 'NUOVO_ARTICOLO_OUT'
  colturaRow: ColturaModel,
  idIntervento: string
} | {
  type: 'EDIT_ARTICOLO_OUT'
  articoloUscita: ArticoloUscitaIntervento
  coltura: ColturaModel
  idIntervento: string
};

export const defaultState: State
  = { articoloUscita: null, idIntervento: "" };

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'NUOVO_ARTICOLO_OUT':
      return {
        ...state,
        nuovoArticoloUscita: payload.colturaRow,
        idIntervento: payload.idIntervento
      };
    case 'EDIT_ARTICOLO_OUT':
      return {
        ...state,
        articoloUscita: payload.articoloUscita,
        idIntervento: payload.idIntervento,
        coltura: payload.coltura
      };
    default: return state;
  }
};
