import { faChevronLeft, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { BasicDropdown, BasicTextArea, BasicCheckbox, fetchObjects, postObject, ErrorModal, ID_AZIENDA, ID_UNITA } from "../../../Utilities/SharedComponent";
import { InterventoModelNew, StatoIntervento, TipoIntervento, InterventoModel, initialStateTipoIntervento, initialStateIntervento, TipoRilevazione, RilevazioneNew, ColturaModel, Rilevazione } from "../Coltura/Model";
import { EventiArticoliInIngresso, EventiArticoliInUscita, EventiMacchinari, EventiPersonale, EventiRilevazioni } from "./Model";
import CustomTable from "../../CustomTable/CustomTable";
import { UNDO_ACTION, UNDO, AllActions } from "../../../Reducers/Root";
import { useDispatch, useSelector } from "../../../Utilities/SharedHooks/State";
import DateInfo from "../../../Components/DatesInfo/DateInfo";
import EditButtonGroup from "../../EditButtonGroup/EditButtonGroup";
import { useDelete } from "../../../Utilities/SharedHooks/Sync";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import { innerJoin, rightOuterJoin, leftJoin, leftOf, rightOf } from "../../../Utilities/Functions";
import Modali, { useModali } from 'modali';
import { navigateBack } from "../../../Reducers/Route";
import Pagination from "../../../Components/Pagination/Pagination";
import * as uuid from "uuid"

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      L'intervento verrà eliminato permanentemente.
    </Modali.Modal>
  );
};

const promiseTipiIntervento = fetchObjects(`tipointervento/${ID_UNITA}`);

const Intervento: React.FC<{
  intervento: InterventoModel
  eventiArticoliInIngresso: EventiArticoliInIngresso
  eventiArticoliInUscita: EventiArticoliInUscita
  eventiMacchinari: EventiMacchinari
  eventiPersonale: EventiPersonale
  eventiRilevazioni: EventiRilevazioni,
  handlePostIntervento : (intervento : InterventoModel) => void
  handleDeleteIntervento : (intervento : InterventoModel) => void
}> = ({
  intervento,
  eventiArticoliInIngresso,
  eventiArticoliInUscita,
  eventiMacchinari,
  eventiPersonale,
  eventiRilevazioni,
  handlePostIntervento,
  handleDeleteIntervento
}) => {

    const [tipiIntervento, setTipiIntervento] = useState<TipoIntervento[]>([initialStateTipoIntervento]);
    const [interventoSelected, setInterventoSelected] = useState<TipoIntervento>(initialStateTipoIntervento);
    const stateIntervento = useSelector(state => state.present.interventoSelectedRow)
    const [localIntervento, setLocalIntervento] = useState<InterventoModelNew>(intervento);
    const [error, setError] = useState(""); 

    const stateColtura = useSelector(state => state.present.colturaSelectedRow)
    const [postColtura, colturaPostata] = usePost<ColturaModel>(`colture/${stateColtura?.idReparto}`)
    const dispatch = useDispatch<AllActions>();
    useEffect(() => {
      setLocalIntervento(intervento)
    }, [intervento])
    useEffect(() => {
      const int = ( o: { colturaSelectedRow: ColturaModel } ) => {
        const ivt = o.colturaSelectedRow.interventi.find(x => x.idIntervento === intervento.idIntervento)
        return {...o, interventoSelectedRow: ivt }
      }
      // dispatch({type: 'PUSH_RESTORER', payload: int })
    }, [intervento])
    const [deleteModal, toggleDeleteModal] = useModali({
      animated: true,
      title: 'Sei sicuro?',
      buttons: [
        <Modali.Button
          label="Annulla"
          isStyleCancel
          onClick={() => toggleDeleteModal()}
        />,
        <Modali.Button
          label="Elimina"
          isStyleDestructive
          onClick={() => handleDeleteIntervento(localIntervento)}
        />
      ]
    });

    const handleUpdateForm = () => {
      handlePostIntervento(localIntervento)
      // if(stateColtura){
      //   const newColtura = produce(stateColtura, c => {
      //     const index = c.interventi.findIndex(x => x.idIntervento === stateIntervento?.idIntervento)
      //     c.interventi[index] = localIntervento
      //   })
      // }
    }

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handlePostIntervento(localIntervento);
    };

    useEffect(() => {
      promiseTipiIntervento
        .then((data) => { setTipiIntervento(data); })
        .catch((err) => { setError(err); });

    }, [intervento]);

    const [rilevazioniAssociate, setRilevazioniAssociate] = useState<TipoRilevazione[]>([])
    const [rilevazioniQualcosaltro, setrilevazioniQualcosaltro] = useState<Rilevazione[]>([])
    
    useEffect(() => {
      if (stateIntervento) {
        const _rilevazioniAssociate : TipoRilevazione[] = stateIntervento.tipoIntervento.rilevazioniAssociate
        
        setRilevazioniAssociate(_rilevazioniAssociate)

        const rilevazioneDefault = (tr : TipoRilevazione) => 
          [tr, { 
            idRilevazione: "",
            id: stateIntervento?.idIntervento!,
            tag: "intervento" as const,
            tipoRilevazione: tr,
            posizione: 0,
            valore: "",
            unitaDiMisura: "",
            note: "",
          }] as const


        const rilevazioniETipi =
          leftJoin(
            (rec: TipoRilevazione, ril) => rec.idTipoRilevazione === ril.tipoRilevazione.idTipoRilevazione,
            _rilevazioniAssociate,
            stateIntervento.rilevazioni
          ).map(([tr, ril]) => {
            if(ril === undefined){
              return rilevazioneDefault(tr);
            } else return [tr, ril] as const;
          });
        const rilevazioni = rightOf(rilevazioniETipi);
        setrilevazioniQualcosaltro(rilevazioni)
      } 
    }, [stateIntervento])

    const zipRilevazioni = (r1 : Rilevazione[], r2: RilevazioneNew[]) => {
      let res = [];
      for (let i = 0; i < r2.length; i++) {
        res[i] = { ...r1[i] || {}, ...r2[i] }
      }
      return res;
    }

    const handleChangeRilevazioni = (e: { [index: string]: any; }[]) => { 
      const rils : Rilevazione[] = 
        e.map((rilevazione, i) => {
          const valore = rilevazione?.valore != null ? rilevazione.valore : ""
          const unitaDiMisura = rilevazione?.unitaDiMisura != null ? rilevazione.unitaDiMisura : "";
          return {
            tipoRilevazione: rilevazioniAssociate[i],
            valore,
            tag: "intervento",
            posizione: 0,
            note: "",
            id: stateIntervento?.idIntervento!,
            idRilevazione: stateIntervento?.rilevazioni?.[i]?.idRilevazione ?? uuid.v4(),
            unitaDiMisura
        }})
        if (rils) {
          const interventoSelected = stateColtura?.interventi.findIndex(i => i.idIntervento === stateIntervento?.idIntervento)!
          const zipped = zipRilevazioni(stateColtura!.interventi[interventoSelected].rilevazioni, rils)
          const newIntervento =  { ...stateColtura!.interventi[interventoSelected], rilevazioni: zipped  }
          const newState : ColturaModel = {
            ...stateColtura!, 
            interventi: produce(stateColtura!.interventi, i => {
              i[interventoSelected] = newIntervento
            })
          }
          postColtura(newState).then(e => {
            if(!e.isError){
              dispatch({ type: "COLTURA_EDIT", coltura: newState })
              dispatch({ type: "INTERVENTO_EDIT", intervento: newIntervento })  
            }
          })
      }
    }

    const handleTexboxChange = (fn: (v: InterventoModelNew) => void) => {
      setLocalIntervento(produce(localIntervento, fn));
    };

    const handleTipoInterventoSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const tipoIntervento =
        _.find(
          _.propEq("descrizione", e.target.value)
          , tipiIntervento);
      if (tipoIntervento) {
        setInterventoSelected(tipoIntervento);
      }
    };

    // Paginazione tabella personale
    const [currentPagePersone, setCurrentPagePersone] = useState(1)
    const [personePerPage] = useState(9)
  
    const indexLastPersona = currentPagePersone * personePerPage
    const indexFirstPersona = indexLastPersona - personePerPage
    const currentPersone = localIntervento.persone && localIntervento.persone.slice(indexFirstPersona, indexLastPersona)

    const paginatePersone = (pageNumber: number) => {
      setCurrentPagePersone(pageNumber)
    }

    // Paginazione tabella articoli in uscita
    const [currentPageArtUsc, setCurrentPageArtUsc] = useState(1)
    const [artUscPerPage] = useState(9)
  
    const indexLastArtUsc = currentPageArtUsc * artUscPerPage
    const indexFirstArtUsc = indexLastArtUsc - artUscPerPage
    const currentArtUsc = localIntervento.articoliUscita && localIntervento.articoliUscita.slice(indexFirstArtUsc, indexLastArtUsc)

    const paginateArtUsc = (pageNumber: number) => {
      setCurrentPageArtUsc(pageNumber)
    }
    
    return (
      <>
        <ErrorModal prom={colturaPostata} />
        <span>
          <FontAwesomeIcon
            className="is-sidebar-listitem-icon is-clickable"
            icon={faChevronLeft}
            size="lg"
            onClick={() => dispatch(navigateBack())}
          />
          <span className="subtitle has-text-grey">Intervento {intervento.tipoIntervento.descrizione}</span>
        </span>

        <form onSubmit={handleFormSubmit}>
          <br />
          <EditButtonGroup onEditConfirm={handleUpdateForm} onDelete={ ()=> { toggleDeleteModal() } }/>
          <div className="columns">
            <div className="column">

              <BasicDropdown label={"Intervento"}
                defaultValue={intervento.tipoIntervento.descrizione}
                values={tipiIntervento.map((x) => x.descrizione)}
                onSelection={(e) => { handleTipoInterventoSelection(e); }} 
              />

            </div>
            <div className="column">

              <BasicDropdown label={"Stato"}
                defaultValue={intervento.stato}
                values={["Programmato", "InCorso", "Concluso"]}
                onSelection={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.stato = e.target.value as StatoIntervento; });
                }} 
              />

            </div>
            <div className="column">
              <BasicCheckbox label="Previsto"
                checked={localIntervento.previsto}
                onChange={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.previsto = e.target.checked; });
                }} />
            </div>
          </div>
          <div className="columns">
            <div className="column is-bordered-column">
              <DateInfo title="Data prevista"
                from={localIntervento.dataInizioPrevisto}
                to={localIntervento.dataFinePrevista}
                onFromChanged={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.dataInizioPrevisto = e.target.value; });
                }}
                onToChanged={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.dataFinePrevista = e.target.value; });
                }}>
              </DateInfo>
              <DateInfo title="Data reale"
                from={localIntervento.dataInizioReale}
                to={localIntervento.dataFineReale}
                onFromChanged={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.dataInizioReale = e.target.value; });
                }}
                onToChanged={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.dataFineReale = e.target.value; });
                }
                }>
              </DateInfo>
            </div>
          </div>
          <div className="columns">
            <div className="column is-bordered-column">
              <BasicTextArea
                label="Annotazioni"
                value={localIntervento.annotazioniPubbliche}
                readOnly={false}
                onChange={(e) => {
                  handleTexboxChange((p: InterventoModelNew) => { p.annotazioniPubbliche = e.target.value; });
                }} />
            </div>

          </div>
        </form>
        {
          localIntervento && localIntervento.tipoIntervento.articoliIngresso && (
            <>
              <CustomTable
                headers={["descrizioneArticolo", "lotto", "unitaDiMisuraAcquisto", "quantita", "costoTotale"]}
                rows={localIntervento.articoliIngresso}
                title="Articoli in ingresso"
                footer={false}
                onCreate={eventiArticoliInIngresso.handleOnCreateArticoliInIngresso}
                onUpdate={eventiArticoliInIngresso.handleOnUpdateArticoliInIngresso}
                onDelete={eventiArticoliInIngresso.handleOnDeleteArticoliInIngresso} />
            </>
          )
        }

        {
          localIntervento && localIntervento.tipoIntervento.articoliUscita && (
            <>
              <CustomTable
                classes="tableArticoliInUscita"
                headers={["data", { key:"idPersona", value: "persona"},"codiceArticolo", { key:"colli", value: "fixednumber"}, "unitaDiMisuraVendita", "coefficienteConversione", "quantita"]}
                rows={currentArtUsc}
                title="Articoli in uscita"
                footer={false}
                onCreate={eventiArticoliInUscita.handleOnCreateArticoliInUscita}
                onUpdate={eventiArticoliInUscita.handleOnUpdateArticoliInUscita}
                onDelete={eventiArticoliInUscita.handleOnDeleteArticoliInUscita} 
              />
              {localIntervento.articoliUscita.length > 9 && <Pagination objPerPage={artUscPerPage} totalObj={localIntervento.articoliUscita.length} paginate={paginateArtUsc} />} 
            </>
          )
        }
        <br/>
        {
          localIntervento && localIntervento.tipoIntervento.utilizzoMacchine && (
            <>
              <CustomTable
                headers={["descrizione", "dataInizio", "dataFine", { key:"tempo", value: "time"}, "costoTotale"]}
                rows={localIntervento.macchine}
                title="Macchinari"
                onCreate={eventiMacchinari.handleOnCreateMacchinari}
                onUpdate={eventiMacchinari.handleOnUpdateMacchinari}
                onDelete={eventiMacchinari.handleOnDeleteMacchinari} />
            </>
          )
        }
        
        {
          localIntervento && localIntervento.tipoIntervento.utilizzoPersone && (
            <>
              <CustomTable
                classes="tablePersone"
                headers={[{ key:"idPersona", value: "persona"}, "dataInizio", "dataFine", { key:"tempo", value: "time"}, { key:"costoTotale", value: "fixednumber" }]}
                rows={currentPersone}
                title="Personale"
                onCreate={eventiPersonale.handleOnCreatePersonale}
                onUpdate={eventiPersonale.handleOnUpdatePersonale}
                onDelete={eventiPersonale.handleOnDeletePersonale} 
              />
              {localIntervento.persone.length > 9 && <Pagination objPerPage={personePerPage} totalObj={localIntervento.persone.length} paginate={paginatePersone} />}
            </>
          )
        }

        {
          stateIntervento && rilevazioniQualcosaltro !== undefined && rilevazioniQualcosaltro.length !== 0 && (
            <>
              <CustomTable
                headers={["tipoRilevazione.descrizione", "tipoRilevazione.tipoValore" , { key:"valore", value: "editable"}, "tipoRilevazione.unitaDiMisura", "tipoRilevazione.obbligatoria"]}
                rows={rilevazioniQualcosaltro}
                title="Rilevazioni"
                editable={true}
                onApply={handleChangeRilevazioni}
                onCreate={eventiRilevazioni.handleOnCreateRilevazioni}
                onUpdate={eventiRilevazioni.handleOnUpdateRilevazioni}
                onDelete={eventiRilevazioni.handleOnDeleteRilevazioni} />
            </>
          )
        }
        <ModalDelete modal={deleteModal} />
      </>
    );
  };

export default Intervento;
