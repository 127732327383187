import React, { useState, useEffect } from 'react'
import './QuickOperation.css'
import Header from '../../Components/Header/Header'
import LabelColli from './labelcolli/labelcolli'
import Tastierino, { PadKey } from './tastierino/tastierino'
import ActionButtons from './actionbuttons/actionbuttons'
import { ColturaModel, PersonaIntervento, InterventoModel, Articolo, ArticoloUscitaIntervento, CampoLibero, Persona } from '../Azienda/Coltura/Model'
import ProduzioneRapida from './produzionerapida/produzionerapida'
import { UnitaProduttiva, RepartoModel } from '../Azienda/Model'
import { flatMap } from '../../Utilities/Functions'
import { fetchObjects, ID_AZIENDA, postObject, deleteObject, ID_UNITA } from '../../Utilities/SharedComponent'
import * as _ from 'ramda'
import PersonaleRapida from './personalerapida/personalerapida'
import CategorieRapida from './categorierapida/categorierapida'
import ArticoliRapida from './articolirapida/articolirapida'
import BurgerSchermataRapida from '../../Components/Sidebar/BurgerSchermataRapida'
import StoricoRapida from './storicorapida/storicorapida'
import { State } from 'react-burger-menu'
import moment from 'moment'

export interface OperationLog {
  idArticoloUscitaIntervento: string
  articolo: Articolo
  reparto: string
  colli: string
  intervento: InterventoModel
  persona: PersonaIntervento
  calibro: string
  dataOperazione: string
}

const promiseArticoli = (cod: string) => fetchObjects(`${ID_AZIENDA}/${cod}/articoli`)
const promiseUnitaProduttive = () => fetchObjects(`${ID_AZIENDA}/unitaproduttive`)
const promiseColture = (idReparto?: string) => fetchObjects(`colture/${idReparto}?bloccati=true`)
const promisePersone = () => fetchObjects(`persone/${ID_UNITA}`)
const promiseUltimeOperazioni = () => fetchObjects(`ultimeOperazioni/${ID_UNITA}`)

const QuickOperation: React.FC = () => {

  // visualizzo messaggio di notifica per l'operazioni avvenuta
  function showConfirmationMessage() {
    let x = document.getElementById("confirmation-message")
    x!.className = "show"
    setTimeout(function () { x!.className = x!.className.replace("show", ""); }, 3000)
  }

  const [menuState, setMenuState] = useState(false)
  const onStateChange = (burgerState: State) => {
    setMenuState(burgerState.isOpen);
  }
  
  // valore inviato dal tastierino all'input
  const [numpadValue, setNumpadValue] = useState("0")
  const sendKey = (key: PadKey) => {
    let k = key.toString()
    setNumpadValue(
      key === "CE"
        ? "0"
        : (numpadValue === "0" ? k : numpadValue + k))
  }

  const [data, setData_] = useState(new Date())
  const setData = (d : Date) => {
    setData_(d)
    // setData_(new Date(
    //   d.getFullYear(), d.getMonth(), d.getDate()
    // ))
  }
  const [colture, setColture] = useState<ColturaModel[]>([])
  const [reparti, setReparti] = useState<RepartoModel[]>([])
  const [persone, setPersone] = useState<Persona[]>([])
  const [personeIntervento, setPersoneIntervento] = useState<PersonaIntervento[]>([])
  const [ups, setUps] = useState<UnitaProduttiva[]>([])
  const [articoli, setArticoli] = useState<Articolo[]>([])
  const [filteredArticoli, setFilteredArticoli] = useState<Articolo[]>([])
  const [categorie, setCategorie] = useState<CampoLibero[]>([])

  useEffect(() => {
    fetchUltimeOperazioni()
    getColture()
  }, [])

  // useEffect(() => {
  //   const doc = document.documentElement
  //   // const root = document.getElementById("root")
  //   if (doc) {
  //     doc.requestFullscreen()
  //   }
  // }, [])

  async function getColture() {
    const ups: UnitaProduttiva[] = await promiseUnitaProduttive()

    const cod = ups.find(x => x.idUnitaProduttiva === ID_UNITA)?.codiceUnitaProduttiva

    if (cod) {
      promiseArticoli(cod)
        .then((art: Articolo[]) => {
          const a = art.filter(x => x.tipoArticolo.toLocaleLowerCase() === "prodottoFinito".toLocaleLowerCase())
          setArticoli(a)
          setFilteredArticoli(a)

          const cal = flatMap(art, x => x.campiLiberi || [])
          setCategorie(cal.filter((v, i, a) => a.findIndex(x => x.valore === v.valore) === i))
        })
    }

    setUps(ups)

    const rep: RepartoModel[] = flatMap(ups, x => x.reparti)
    setReparti(rep)

    const colt: ColturaModel[] = _.flatten(await Promise.all(rep.map(r => promiseColture(r.idReparto))))
    if (ID_UNITA) {

      const per: Persona[] = await promisePersone()
      setPersone(per)
    }
    if (!_.isEmpty(colt)) {
      setColture(colt)
    }
  }

  async function fetchUltimeOperazioni() {
    const ops: OperationLog[] = await promiseUltimeOperazioni()

    setUltimeOperazioni(ops)
  }

  const cleanup = () => {
    setCategoriaSelezionata(undefined)
    setArticoloSelezionato(undefined)
    setArticoloModificato(undefined)
    setNumpadValue("")
    setReset(!reset)
  }

  // memorizzo tutti i valori selezioanti (intervento, persona, calibro, articolo)
  const [interventoSelezionato, setInterventoSelezionato] = useState<InterventoModel>()
  const [personaSelezionata, setPersonaSelezionata] = useState<PersonaIntervento | Persona>()
  const [categoriaSelezionata, setCategoriaSelezionata] = useState<CampoLibero>()
  const [articoloSelezionato, setArticoloSelezionato] = useState<Articolo>()
  const [reset, setReset] = useState(false)

  const [error, setError] = useState("")

  const handleClickConferma = () => {
    setInModifica(false)
    if (interventoSelezionato && personaSelezionata && categoriaSelezionata && articoloSelezionato) {
      const articoloInUscita: ArticoloUscitaIntervento = {
        idArticoloUscitaIntervento: idArticoloUscitaIntervento === "" ? "" : idArticoloUscitaIntervento,
        codiceInternoArticolo: articoloSelezionato.codiceInternoArticolo,
        codiceArticolo: articoloSelezionato.codiceArticolo,
        descrizioneArticolo: articoloSelezionato.descrizione,
        data: moment(data).toISOString(),
        aliquota: articoloSelezionato.aliquota,
        listinoIvato: articoloSelezionato.listinoIvato,
        numeroListino: articoloSelezionato.numeroListino,
        coefficienteConversione: articoloSelezionato.coefficienteConversione,
        unitaDiMisuraVendita: articoloSelezionato.unitaDiMisuraVendita,
        quantita: articoloSelezionato.coefficienteConversione * Number(numpadValue),
        calibro: categoriaSelezionata.valore,
        colli: Number(numpadValue),
        rilevazioni: [],
        idPersona: personaSelezionata.idPersona
      }

      postObject(`articolouscita/${interventoSelezionato.idIntervento}`, articoloInUscita)
        .then(res => {
          if (res.status === 409) {
            // alert("Nessun composto inserito. Non è stato possibile inserire l'articolo")
            setError("Nessun composto inserito. Non è stato possibile inserire l'articolo")
          } else {
            setError("")
            showConfirmationMessage()
            fetchUltimeOperazioni()
            cleanup()
          }
        })
        .catch(_ => alert('Si è verificato un errore, si prega di riprovare più tardi!'))
      const now = new Date()
      setData(new Date(data.getFullYear(), data.getMonth(), data.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()))
      setIdArticoloUscitaIntervento("")
    }
  }

  useEffect(() => {
    if (interventoSelezionato?.persone) {
      setPersoneIntervento(interventoSelezionato.persone.filter(p => p.dataFine === null))
    }
  }, [interventoSelezionato])
  
  const [ultimeOperazioni, setUltimeOperazioni] = useState<OperationLog[]>([])
  
  // per la modifica dell'operazione dallo storico
  const [idArticoloUscitaIntervento, setIdArticoloUscitaIntervento] = useState("")
  const [interventoModificato, setInterventoModificato] = useState<InterventoModel>()
  const [personaModificata, setPersonaModificata] = useState<PersonaIntervento>()
  const [articoloModificato, setArticoloModificato] = useState<Articolo>()

  const [inModifica, setInModifica] = useState(false)

  const handleClickModifica = (op: OperationLog) => {
    setInModifica(true)
    ultimeOperazioni.splice(ultimeOperazioni.indexOf(op), 1)
    setIdArticoloUscitaIntervento(op.idArticoloUscitaIntervento)
    setPersonaModificata(op.persona)
    setInterventoModificato(op.intervento)
    setArticoloModificato(op.articolo)
    setNumpadValue(op.colli)
    setData(new Date(op.dataOperazione))

    const cat = categorie.find(c => c.valore === op.calibro)
    setCategoriaSelezionata(cat)
    setMenuState(false)
  }

  const handleClickElimina = (op: OperationLog) => {
    deleteObject(`articoloUscita/${op.idArticoloUscitaIntervento}`).then(_ => {
      fetchUltimeOperazioni()
      // ultimeOperazioni.splice(ultimeOperazioni.indexOf(op), 1)
      // setUltimeOperazioni(ultimeOperazioni.splice(ultimeOperazioni.indexOf(op), 1))
    })
  }

  useEffect(() => {
    let arts: Articolo[] = []
    for (const a of filteredArticoli) {
      if (a.campiLiberi !== undefined) {
        for (const v of a.campiLiberi) {
          if (v.valore === categoriaSelezionata?.valore) {
            arts.push(a)
            break
          }
        }
      }
    }

    setArticoli(arts)
  }, [categoriaSelezionata])
  
  const body = (
    <>
      <div id="outer-container">
        <BurgerSchermataRapida outerContainerId="outer-container" pageWrapId="page-wrap" isOpen={menuState} onStateChange={onStateChange} >
          <StoricoRapida 
            ultimeOperazioni={ultimeOperazioni}
            clickModifica={handleClickModifica}
            clickElimina={handleClickElimina} />
        </BurgerSchermataRapida>
        <div id="page-wrap">
          <Header />
          <div className="section is-fullheight-tablet has-5-margin-top has-animation">
            <div className="columns is-fullheight-tablet">
              <div className="column is-2 has-inactive-border">
                <ProduzioneRapida
                  colture={colture}
                  reparti={reparti}
                  interventoSelezionato={(i) => setInterventoSelezionato(i)}
                  interventoModificato={interventoModificato}
                  />
              </div>
              <div className="column is-2 has-inactive-border">
                <PersonaleRapida
                  interventoSelezionato={interventoSelezionato}
                  persone={persone}
                  personeIntervento={personeIntervento}
                  personaSelezionata={(p) => setPersonaSelezionata(p)}
                  personaModificata={personaModificata}
                  />
              </div>
              <div className="column is-2 has-inactive-border">
                <CategorieRapida
                  unitaProduttive={ups}
                  categorie={categorie}
                  categoriaModificata={categoriaSelezionata}
                  categoriaSelezionata={(c) => setCategoriaSelezionata(c)}
                  reset={reset} />
              </div>
              <div className="column is-2 has-inactive-border">
                <ArticoliRapida
                  categoriaSelezionata={categoriaSelezionata}
                  articoli={articoli}
                  articoloSelezionato={(a) => setArticoloSelezionato(a)}
                  articoloModificato={articoloModificato}
                  reset={reset} />
              </div>
              <div className="column is-4">
                <div className="is-vertical-30">
                  <LabelColli
                    interventoSelezionato={interventoSelezionato}
                    data={data}
                    setData={setData}
                    modifica={inModifica}
                    articoloModificato={articoloModificato}
                    articoloSelezionato={articoloSelezionato}
                    value={numpadValue} />
                </div>
                <div className="is-vertical-40">
                  <Tastierino 
                    sendKey={sendKey} />
                </div>
                <div className="is-vertical-20 has-text-centered">
                   {error !== "" && (
                    <p style={{ color: 'red' }}>{error}</p>
                   )}
                  <ActionButtons
                    clickConferma={handleClickConferma}
                    clickLogOperazioni={() => setMenuState(!menuState)} />
                </div>
              </div>
            </div>
          </div>
          <div id="confirmation-message">Invio completato con successo!</div>
        </div>
      </div>
    </>
  )

  return (
    _.not(_.isEmpty(colture))
      ? body
      : (
        <>
          <Header />
          <div className="container is-loading-bar">
            <div>
              <progress className="progress is-small is-success" max="100">15%</progress>
            </div>
          </div>
        </>
      )
  )
}

export default QuickOperation