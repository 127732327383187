import { postObject, deleteObjectAtId } from "../SharedComponent"
import { useState } from "react"

export interface FetchError {
  isError: true
  status: number
  message: string
}

export interface FetchOk<T> {
  isError: false
  result: T
}

export type FetchResult<T> = FetchOk<T> | FetchError
export type Fetch<T> = Promise<FetchResult<T>>

export const nullResponse: FetchOk<null> = { isError: false, result: null }

const fromResponse = (r: Response): Response | PromiseLike<Response> => {
  if (r.ok) {
    return r
  } else {
    return r
      .text()
      .then((text) =>
        Promise.reject({ isError: true, status: r.status, message: text })
      )
  }
}

export const useFetch = <T>(p: Promise<Response>): Fetch<T> =>
  p
    .then(fromResponse)
    // tslint:disable-next-line: use-default-type-parameter
    .then<T>((r) => r.json())
    .then<FetchOk<T>>((r) => ({ isError: false, result: r }))
    .catch<FetchError>((err) => {
      return err
    })

export const usePost = <T>(
  url: string
): [(body: T) => Fetch<null>, Fetch<null> | null] => {
  const [st, setSt] = useState<Fetch<null> | null>(null)
  return [
    (body) => {
      const promise = postObject<T>(url, body)
      return promise
        .then(fromResponse)
        .then((r) => {
          setSt(Promise.resolve(nullResponse))
          return r.json()
          // return nullResponse;
        })
        .catch((err: FetchError) => {
          setSt(Promise.resolve(err))
          return err
        })
    },
    st,
  ]
}

export const useDelete = (
  baseurl: string
): [(path: string) => Fetch<null>, Fetch<null> | null] => {
  const [st, setSt] = useState<Fetch<null> | null>(null)
  return [
    (path) => {
      const promise = deleteObjectAtId(baseurl, path)
      return promise
        .then(fromResponse)
        .then((r) => {
          setSt(Promise.resolve(nullResponse))
          return nullResponse
        })
        .catch((err: FetchError) => {
          setSt(Promise.resolve(err))
          return err
        })
    },
    st,
  ]
}
