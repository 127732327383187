import React, { useState, useEffect } from 'react'
import * as _ from 'ramda'
import { OperationLog } from '../QuickOperation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { fetchObjects, ID_UNITA } from '../../../Utilities/SharedComponent'
import { Persona } from '../../Azienda/Coltura/Model'
import moment from 'moment'

const promisePersone = () => fetchObjects(`persone/${ID_UNITA}`)

const StoricoRapida: React.FC<{
  ultimeOperazioni: OperationLog[]
  clickElimina: (operazione: OperationLog) => void
  clickModifica: (operazione: OperationLog) => void
}> = ({ ultimeOperazioni, clickElimina, clickModifica }) => {

  const [persone, setPersone] = useState<Persona[]>([])
  useEffect(() => {
    if (ID_UNITA) {
      promisePersone()
        .then(res => setPersone(res))
    }
  }, [])

  const [confermaEliminazione, setConfermaEliminazione] = useState<{ [key: string]: boolean }>()

  return (
    <div>
      <h1 className="subtitle">Ultime operazioni</h1>
      {ultimeOperazioni.length > 0 ? ultimeOperazioni.map((operazione, i) => (
        <div className="tile is-ancestor" key={i}>
          <div className="tile">
            <div className="tile is-parent">
              <article className="tile is-child notification">
                <p><b>Produzione:</b> {operazione.reparto}</p>
                <p><b>Intervento:</b> {operazione.intervento.tipoIntervento.descrizione}</p>
                <p><b>Codice persona:</b> {persone.find(p => p.idPersona === operazione.persona.idPersona)?.codicePersona}</p>
                <p><b>Articolo:</b> {operazione.articolo.descrizione}</p>
                <p><b>Colli:</b> {operazione.colli}</p>
                <p><b>Data:</b> {moment(operazione.dataOperazione).format("DD/MM/YYYY")}</p>
                <div className="buttons is-pulled-right">
                  {!confermaEliminazione?.[operazione.idArticoloUscitaIntervento] && (
                    <>
                      <button className="button is-danger" onClick={() => setConfermaEliminazione({ [operazione.idArticoloUscitaIntervento]: true })}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <button className="button is-warning" onClick={() => clickModifica(operazione)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </>
                  )}
                  {confermaEliminazione?.[operazione.idArticoloUscitaIntervento] && (
                    <>
                      <button className="button is-info is-oulined" onClick={() => setConfermaEliminazione({ [operazione.idArticoloUscitaIntervento]: false })}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      <button className="button is-danger" onClick={() => clickElimina(operazione)}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </>
                  )}
                </div>
              </article>
            </div>
          </div>
        </div>
      )) : (
        <h1 className="subtitle is-6">Nessuno storico da visualizzare</h1>
      )}
    </div>
  )
}

export default StoricoRapida