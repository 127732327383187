import React, { useState } from 'react'
import logo from '../../Assets/logo-zeuslab.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'

const Header: React.FC = () => {

  const [fullscreen, setFullscreen] = useState(false)

  const handleFullscreenChange = () => {
    let doc = document.documentElement    
    if (doc && fullscreen && document.fullscreen) {
      document.exitFullscreen()
    } else doc.requestFullscreen()
    setFullscreen(!fullscreen)  

  }

  return (
    <nav className="navbar has-border-bottom" role="navigation" aria-label="main navigation">
      <div className="navbar-start">
          <a className="navbar-item" href="/">
            <FontAwesomeIcon icon={faHome} size="2x" />
          </a>
          <div style={{ marginLeft: '30px' }}>
            {fullscreen ? (
              <span className="navbar-item" onClick={_ => handleFullscreenChange()}>
                <FontAwesomeIcon icon={faCompress} size="2x" />
              </span>    
            ) : (
              <span className="navbar-item" onClick={_ => handleFullscreenChange()}>
                <FontAwesomeIcon icon={faExpand} size="2x" />
              </span>
            )}
          </div>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <img src={logo} alt="logo zeuslab" />
        </div>
      </div>
    </nav>
  )
}

export default Header