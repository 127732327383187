import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Persona } from '../../../Azienda/Coltura/Model'
import * as _ from 'ramda'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { ID_UNITA, fetchObjects } from '../../../../Utilities/SharedComponent'

const PersonaleDisponibile: React.FC<{ 
  personaleDisponibile: Persona[]
  ingressoPersona: (p: Persona) => void
}> = ({ personaleDisponibile, ingressoPersona }) => {

  return (
    <div className="container is-scrollable-half-column is-deep-index">
      {personaleDisponibile.map((persona, i) => (
        <div className="tile is-ancestor is-marginless fade-from-right" key={i}>
          <div className="tile ">
            <div className="tile is-parent is-few-padding">
              <article className="tile is-child notification">
                <p className="is-inline has-10-margin-right">{persona.codicePersona}</p>
                <p className="is-inline">{persona.nomePersona}</p>
                <button className="button is-small is-success is-pulled-right" onClick={_ => ingressoPersona(persona)} >
                  <span className="icon">
                    <FontAwesomeIcon icon={faPlay} />   
                  </span>
                </button>
              </article>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PersonaleDisponibile