import * as _ from 'ramda';
import produce from 'immer';
import { Reducer } from 'redux';

export type State = {
  idUnitaProduttivaCorrente: string | null
};

export type Action = {
  type: 'NUOVO_REPARTO',
  idUnitaProduttiva: string
};

export const defaultState = { idUnitaProduttivaCorrente: null };


export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'NUOVO_REPARTO':
      return { 
        ...state,
        idUnitaProduttivaCorrente: payload.idUnitaProduttiva 
      };
    default:
      return state;
  }
};
