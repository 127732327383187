import { Route } from "../Reducers/Route"


/**
 * Routing:
 *  Home
 *  |- UnitaProduttivaVuota
 *  |- NuovoReparto
 *  |- Reparto
 *  |-|- NuovaColtura
 *  |-|- Coltura
 */


export function parentRoute(route: Route): Route | undefined {
  switch(route){
    case 'HOME': 
    return undefined;
    
    case 'REPARTO':
    case 'NUOVO_REPARTO':
    case 'UNITA_PRODUTTIVA_VUOTA':
      return 'HOME';

    case 'NUOVA_COLTURA':
    case 'COLTURA':
      return 'REPARTO';
    
    case 'NUOVO_INTERVENTO':
    case 'INTERVENTO':
      return 'COLTURA';
    
    case 'NUOVA_MACCHINA':
    case 'NUOVA_PERSONA':
    case 'NUOVA_RILEVAZIONE':
    case 'NUOVO_ARTICOLO_INGRESSO':
    case 'NUOVO_ARTICOLO_USCITA':
    case 'PERSONA':
    case 'RILEVAZIONE':
    case 'ARTICOLO_INGRESSO':
    case 'ARTICOLO_USCITA':
      return 'INTERVENTO';

    default: 
      return undefined;

  }
}