import * as _ from "ramda";
import { InterventoModel } from "../Containers/Azienda/Coltura/Model";
import { AssertionError } from "assert";

export const mergeNullsL = <T, Part = Partial<T>>(lhs: Part, rhs: T): T =>
  _.mergeWith((l, r) => l || r, lhs, rhs);

export const mergeNullsR = <T, Part = Partial<T>>(lhs: T, rhs: Part): T =>
  _.mergeWith((l, r) => r || l, lhs, rhs);


export const flatMap = <A, B>(x: A[], f: (_: A) => B[]): B[] => _.chain(f, x);

export type Option<T> = T | undefined;

export function optJoin<A, B>(f: (a: A, b: B) => Option<[A,B]>, as: A[], bs: B[]): Array<readonly [A, B]> {
  return (
    flatMap(as, a =>
    flatMap(bs, b => {
      const fab = f(a, b);
      if (fab !== undefined) return [fab];
      else return [] as any;
    }))
  );  
}

//[(a, b) | a <- as, b <- bs, f a b]
//[(a, b) for a in as for b in bs if f(a,b)]
export function innerJoin<A, B>(f: (a: A, b: B) => boolean, as: A[], bs: B[]): Array<readonly [A, B]> {
  return (
    flatMap(as, a =>
    flatMap(bs, b =>
        f(a, b) ?
          [[a, b]] as const :
          [] as any))
  );  
}

export function leftOuterJoin<A, B>(f: (a: A, b: B) => boolean, as: A[], bs: B[]): Array<A> {
  return leftOf(innerJoin(f, as, bs));
}

export function rightOuterJoin<A, B>(f: (a: A, b: B) => boolean, as: A[], bs: B[]): Array<B> {
  return rightOf(innerJoin(f, as, bs));
}
export function leftJoin<A, B>(f: (a: A, b: B) => boolean, as: A[], bs: B[]): Array<readonly [A, B | undefined]> {
  const joined = innerJoin(f, as, bs);
  const leftValues = as.map(x => [x, undefined] as const);
  return _.uniqBy(([l]) => l, [...joined, ...leftValues])
}


export const leftOf = <L, R>(xs: Array<readonly [L, R]>) : L[] => xs.map(([x, _]) => x);
export const rightOf = <L, R>(xs: Array<readonly [L, R]>) : R[] => xs.map(([_, x]) => x);

type NotNull<T> = Exclude<T, undefined>

declare global {
  interface Array<T = any> {
    whenNull(this: Array<T | undefined>, def: NotNull<T>): Array<NotNull<T>>;
  }

}
Array.prototype.whenNull = function<T>(this: Array<T | undefined>, def : T){
  return this.map(v => v === undefined ? def : v);
}

export const isValidDateRange = (int: InterventoModel) => {
  const today = new Date().setHours(0,0,0,0);
  const forecast = new Date(int.dataInizioPrevisto).setHours(0,0,0,0) <= today && new Date(int.dataFinePrevista).setHours(0,0,0,0) >= today;
  const real = new Date(int.dataInizioReale).setHours(0,0,0,0) <= today && new Date(int.dataFineReale).setHours(0,0,0,0) >= today;

  return forecast || real;
}

export function assert(condition: any, message?: string): asserts condition {
  if(!condition){
    throw new AssertionError({message})
  }
}