import React, { useState } from 'react'

interface PaginationProps {
  objPerPage: number
  totalObj: number
  paginate: (_: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ objPerPage, totalObj, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const pageNumbers: Array<number> = []
  for (let i = 1; i <= Math.ceil(totalObj / objPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav style={{float: 'right', marginTop: '10px'}} className="pagination is-small is-rounded" role="navigation" aria-label="pagination">
      <ul className="pagination-list">
        {pageNumbers.map((n, i)=> (
          <>
            <li>
              <a onClick={() => { setCurrentPage(n); paginate(n)}} className={`pagination-link ${currentPage === n ? 'is-current' : ''}`} aria-label={n.toString()}>{n}</a>
            </li>  
          </>
        ))}
      </ul>
    </nav>
  )
}

export default Pagination