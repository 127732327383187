import * as _ from 'ramda';
import produce from 'immer';
import { Reducer } from 'redux';
import { UnitaProduttiva } from '../Containers/Azienda/Model';

export type State = {
  unitaProduttivaAttiva: UnitaProduttiva | null
  nuovaUnitaProduttiva?: true
};

export const defaultState: State = { unitaProduttivaAttiva: null };

export type Action = {
  type: 'SET_UNITA_ATTIVA',
  unitaProduttiva: UnitaProduttiva
} | {
  type: 'NUOVA_UNITA'
};

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'SET_UNITA_ATTIVA':
      return { ...state, unitaProduttivaAttiva: payload.unitaProduttiva };
    case 'NUOVA_UNITA':
      return { ...state, unitaProduttivaAttiva: null, nuovaUnitaProduttiva: true };
    default:
      return state;
  }
};
