import { Reducer } from "redux";
import { createReducer, createAction } from "@reduxjs/toolkit";
import { Action } from "redux"
import produce from "immer";
import * as _ from "ramda";
import { StateWithHistory } from "redux-undo";

//a list of functions which, composed left to right, given an A, (input to first fn), make a B (output of last fn)
interface RestoreFn { 
  (_ : any): any
}
export type RestoreFns = RestoreFn[]
export type StateRestore = {
  rootRestorer : RestoreFns,
  restorer : RestoreFns
}


type SetRoot = { type: 'SET_ROOT_RESTORER', payload: RestoreFns }

type PushRestorer = { type: 'PUSH_RESTORER', payload: RestoreFn }
type PopRestorer = { type: 'POP_RESTORER' }
type ResetRestorer = { type: 'RESET_RESTORER' }
type Restore = { type: 'RESTORE', payload: any }
type DestroyAll = { type: 'DESTROY_ALL' }

export type RestoreActions = SetRoot | PopRestorer | ResetRestorer | Restore | PushRestorer | DestroyAll
export const restoreActions = [ 'SET_ROOT_RESTORER', 'POP_RESTORER', 'PUSH_RESTORER', 'RESET_RESTORER', 'RESTORE', 'DESTROY_ALL' ]
interface Restorable {
  <State extends StateWithHistory<any>,A extends Action<any>>(reducer: Reducer<State,A>): Reducer<State, Action | RestoreActions>;
}

const composeList = (fns : RestoreFns) => 
  fns.reduce((prev, curr) => x => curr(prev(x)), x => x)



function push<T>(list : T[], x : T) : T[] {
  return [...list, x]
}
function pop<T>(list : T[]) : [T, T[]] {
  return [
    list[list.length - 1], 
    list.slice(0, list.length - 1)
  ]
}
function pop_<T>(list: T[]): T[] {
  return list.slice(0, list.length-1);
}
let restorerState : StateRestore = { restorer: [], rootRestorer: [] }
let canRestore : boolean = false
export const isRestorable = () => !_.isEmpty(restorerState.restorer) && canRestore
export const finalizeRestore = () => canRestore = true
export const restorable: Restorable = <State extends StateWithHistory<any>, A extends Action<any>>(reducer : Reducer<State, A>) => {
  return (state : State | undefined, action : RestoreActions) => {
    if (state === undefined) return reducer(state, action as any);
    const modify = (x : any) => ({ ...state, ...x })
    switch(action.type) {
      case 'SET_ROOT_RESTORER':
        restorerState.rootRestorer = action.payload
        break;
      case 'PUSH_RESTORER':
        restorerState.restorer.push(action.payload);
        restorerState.restorer = _.uniqBy(g => g.name, restorerState.restorer)
        break;
      case 'POP_RESTORER':
        restorerState.restorer.pop();
      case 'RESET_RESTORER':
        restorerState.restorer = []
        break;
      case 'DESTROY_ALL':
        return {
          past: [],
          present: null,
          future: [],
          _latestUnfiltered: null,
          group: null,
          index: 0,
          limit: 0
        } as any
      case 'RESTORE':
        try {
          const f = composeList(restorerState.restorer)
          const x = f(action.payload) 
          canRestore = false
          return { ...state, present: x}
        } catch {
          return state
        }
      default: 
        return reducer(state, action);
    }
    return state;
  }
}