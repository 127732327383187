import { produce } from "immer";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { BasicInput, fetchObjects, deleteObjectAtId, ErrorModal, sortByCaseInsensitive, ID_UNITA } from "../../Utilities/SharedComponent";
import "../../Utilities/Utils.css";
import { Macchina } from "../Azienda/Coltura/Model";
import Navbar from "../Navbar/Navbar";
import Modali, { useModali } from "modali";
import CustomTable, { TableSubject } from "../CustomTable/CustomTable";
import { restartable } from "../../Utilities/Async";
import { usePost } from "../../Utilities/SharedHooks/Sync";
import { useToasts } from "react-toast-notifications";

const promiseMacchine = restartable(() => fetchObjects(`macchine/${ID_UNITA}`));

const ModalNuovaMacchina: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      <NuovaMacchina />
    </Modali.Modal>
  );
};

const ModalModificaMacchina: React.FC<{
  modal: Modali.ModalHook
  macchina: Macchina
}> = ({ modal, macchina }) => {
  return (
    <Modali.Modal {...modal}>
      <AnagraficaMacchine macchina={macchina} />
    </Modali.Modal>
  );
};

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      La macchina selezionata verrà eliminata permanentemente.
    </Modali.Modal>
  );
};

const Macchine: React.FC = () => {
  const [macchine, setMacchine] = useState<Macchina[] | undefined>();
  const [macchinaAttiva, setMacchinaAttiva] = useState({} as Macchina);
  const [toDelete, setToDelete] = useState({} as Macchina)
  const [error, setError] = useState("");

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deletePersona()}
      />,
    ],
  });

  const [nuovaMacchinaModal, setNuovaMacchinaModal] = useModali({
    title: "Nuovo macchinario",
    animated: true,
    closeButton: true,
    onHide: () => { promiseMacchine.restart(); }
  });

  const [modificaMacchinaModal, setModificaMacchinaModal] = useModali({
    title: "Modifica macchinario",
    animated: true,
    closeButton: true,
    onHide: () => { promiseMacchine.restart(); }
  });

  useEffect(() => {
    promiseMacchine
      .then((data) => { setMacchine(data); })
      .catch((err) => { setError(err); });
  }, [promiseMacchine._current]);

  const handleClickMacchina = (e: TableSubject) => {
    const tmp = e as Macchina;
    if (tmp) {
      setMacchinaAttiva(tmp);
      setModificaMacchinaModal();
    }
  };

  const handleDeleteMacchina = (e: TableSubject) => {
    const tmp = e as Macchina;
    if (tmp) {
      setToDelete(tmp)
    }
  };

  const deletePersona = () => {    
    const tmpMacchine = macchine?.filter(x => x.idMacchina !== toDelete.idMacchina);
    setMacchine(tmpMacchine);
    deleteObjectAtId("macchine", toDelete.idMacchina);
    toggleDeleteModal()
  }

  const Body = (
    <>
      <section className="section">
        <div className="columns">
          <div className="column is-full custom-card-light is-cm-fullheight">
            <CustomTable
              classes="tableMacchinari"
              headers={["codiceMacchina", "matricola", "nomeMacchina", { key: "costoFisso", value: "currency" }, { key: "costoOrario", value: "currency" }]}
              rows={sortByCaseInsensitive('codiceMacchina', macchine) || []}
              title="Tabella macchinari"
              footer={false}
              onCreate={setNuovaMacchinaModal}
              onUpdate={handleClickMacchina}
              onDelete={ e => { handleDeleteMacchina(e); toggleDeleteModal()} }
            />

          </div>

          <ModalNuovaMacchina modal={nuovaMacchinaModal} />
          <ModalModificaMacchina modal={modificaMacchinaModal} macchina={macchinaAttiva} />
        </div>
      </section>
      <ModalDelete modal={deleteModal} />
    </>
  );
  return (
    <div id="outer-container">
      <Navbar outerId="outer-container" pageWrap="page-wrap" breadcrumbs={["Quaderno di campagna", "Macchine"]} />
      <div id="page-wrap">
        {Body}
      </div>
    </div>
  );
};

function setField<T, K extends keyof T>(fn: (React.Dispatch<React.SetStateAction<T>>), obj: T, key: K, value: any) {
  fn(produce(obj, (x: T) => { x[key] = value }))
};

const NuovaMacchina: React.FC = () => {
  const defaultMacchina = {
    codiceMacchina: "",
    nomeMacchina: "",
    costoOrario: 0,
    costoFisso: 0,
    matricola: "",
  };

  const [macchina, setMacchina] = useState(defaultMacchina);
  const [postMacchina, macchinaPostata] = usePost<typeof defaultMacchina>(`macchine/${ID_UNITA}`);

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setMacchina(defaultMacchina);
    postMacchina(macchina);
  };

  return (
    <>
      <ErrorModal prom={macchinaPostata} />
      <form onSubmit={submit}>
        <BasicInput 
          label="Codice" 
          value={macchina.codiceMacchina} 
          readOnly={false} 
          onChange={e => { setField(setMacchina, macchina, "codiceMacchina", e.target.value) }} 
        />

        <BasicInput
          label="Nome" 
          value={macchina.nomeMacchina} 
          readOnly={false} 
          onChange={e => { setField(setMacchina, macchina, "nomeMacchina", e.target.value) }} 
        />

        <BasicInput
          label="Matricola" 
          value={macchina.matricola} 
          readOnly={false} 
          onChange={e => { setField(setMacchina, macchina, "matricola", e.target.value) }} 
        />
        
        <BasicInput
          label="Costo orario" 
          type="number" 
          value={macchina.costoOrario} 
          readOnly={false} 
          onChange={e => { setField(setMacchina, macchina, "costoOrario", Number(e.target.value)) } } 
        />
        
        <BasicInput
          label="Costo fisso" 
          type="number" 
          value={macchina.costoFisso} 
          readOnly={false} 
          onChange={e => { setField(setMacchina, macchina, "costoFisso", Number(e.target.value)) } } 
        />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Salva e aggiungi
          </button>
          </p>
        </div>
      </form>
    </>
  );
};

const AnagraficaMacchine: React.FC<{
  macchina: Macchina,
}> = ({ macchina }) => {
  const { addToast } = useToasts()
  
  const [macchinaModificata, setMacchinaModificata] = useState(macchina)
  const [postMacchina, macchinaPostata] = usePost<Macchina>(`macchine/${ID_UNITA}`)
  
  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    postMacchina(macchinaModificata);
    addToast('Macchina aggiornata correttamente', { appearance: 'success', autoDismiss: true })
  };

  return (
    <>
      <ErrorModal prom={macchinaPostata} />
      <form onSubmit={submit}>
      <BasicInput 
          label="Codice" 
          value={macchinaModificata.codiceMacchina} 
          readOnly={true} 
          onChange={() => { }} 
        />
 
        <BasicInput
          label="Nome" 
          value={macchinaModificata.nomeMacchina} 
          readOnly={false} 
          onChange={e => { setField(setMacchinaModificata, macchinaModificata, "nomeMacchina", e.target.value) }} 
        />

        <BasicInput
          label="Matricola" 
          value={macchinaModificata.matricola} 
          readOnly={false} 
          onChange={e => { setField(setMacchinaModificata, macchinaModificata, "matricola", e.target.value) }} 
        />
        
        <BasicInput
          label="Costo orario" 
          type="number" 
          value={macchinaModificata.costoOrario.toFixed(2)} 
          readOnly={false} 
          onChange={e => { setField(setMacchinaModificata, macchinaModificata, "costoOrario", Number(e.target.value)) } } 
        />
        
        <BasicInput
          label="Costo fisso" 
          type="number" 
          value={macchinaModificata.costoFisso.toFixed(2)} 
          readOnly={false} 
          onChange={e => { setField(setMacchinaModificata, macchinaModificata, "costoFisso", Number(e.target.value)) } } 
        />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success">
              Applica
          </button>
          </p>
        </div>
      </form>
    </>
  );
};
export default Macchine;
