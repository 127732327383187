import React, { useEffect, useState } from "react";
import { BasicInput, ErrorModal, deleteObject, postPhoto, fetchPhoto, ID_AZIENDA } from "../../../Utilities/SharedComponent";
import { UnitaProduttiva } from "../Model";
import { produce } from "immer";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import { useToasts } from 'react-toast-notifications'
import Modali, { useModali } from 'modali';
import { useDispatch } from "../../../Utilities/SharedHooks/State";
import { AllActions } from "../../../Reducers/Root";

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      L'unità produttiva verrà eliminata permanentemente.
    </Modali.Modal>
  );
};

function setField<T, K extends keyof T>(fn: (React.Dispatch<React.SetStateAction<T>>), obj: T, key: K, value: any) {
  fn(produce(obj, (x: T) => { x[key] = value }))
};

const UnitaProduttivaVuota: React.FC<{ unitaProduttiva: UnitaProduttiva, onCreate: () => void }> = ({ unitaProduttiva, onCreate }) => {
  const [unitaProduttivaModificata, setUnitaProduttivaModifata] = useState(unitaProduttiva)
  const [postUnitaProduttiva, upPostate] = usePost<UnitaProduttiva>("unitaproduttive");
  const { addToast } = useToasts();
  const dispatch = useDispatch<AllActions>();
  const [photo, setPhoto] = useState();
  const [newPhoto, setNewPhoto] = useState();

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deleteUnitaProduttiva()}
      />,
    ],
  });

  useEffect(() => {
    setUnitaProduttivaModifata(unitaProduttiva)
  }, [unitaProduttiva])

  useEffect(() => {
    fetchPhoto(`foto/unitaproduttiva/${unitaProduttiva.idUnitaProduttiva}`)
      .then(res => {
        if (!(res === "Immagine non trovata")) {
          setPhoto(res)
        } else { setPhoto(undefined) }
      })
      .catch(_ => setPhoto(undefined))
  }, [unitaProduttiva])

  const onChangePhoto = (e : any) => {
    e.preventDefault();
    const file = e.target.files[0]
    setNewPhoto(file)    
  }

  const postProfilePhoto = (idUnitaProduttiva: any) => {
    let formData = new FormData();
    if (newPhoto && idUnitaProduttiva) {
      formData.append('image', newPhoto, newPhoto.name);

      postPhoto(`foto/unitaproduttiva/${idUnitaProduttiva}`, formData)
    }
  }

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const _nuovaUnitaProduttiva: UnitaProduttiva = {
      idUnitaProduttiva: unitaProduttiva.idUnitaProduttiva
    , idAzienda: ID_AZIENDA ?? ""
    , codiceUnitaProduttiva: unitaProduttivaModificata.codiceUnitaProduttiva
    , nomeUnita: unitaProduttivaModificata.nomeUnita
    , alias: unitaProduttivaModificata.alias
    , indirizzo: unitaProduttivaModificata.indirizzo
    , cap: unitaProduttivaModificata.cap
    , citta: unitaProduttivaModificata.citta
    , provincia: unitaProduttivaModificata.provincia
    , regione: unitaProduttivaModificata.regione
    , comune: unitaProduttivaModificata.comune
    , reparti: []
    };

    postUnitaProduttiva(_nuovaUnitaProduttiva)
      .then(idUnitaProduttiva => {
        if(newPhoto !== undefined) {
          postProfilePhoto(idUnitaProduttiva)
        }
        addToast('Unità produttiva salvata correttamente', { appearance: 'success', autoDismiss: true })
      })
    onCreate()
  };

  const deleteUnitaProduttiva = () => {
    deleteObject(`unitaproduttive/${unitaProduttiva.idUnitaProduttiva}`)
       .then(_ => addToast('Unità produttiva eliminata correttamente', { appearance: 'success', autoDismiss: true }))
    toggleDeleteModal();
    onCreate()
    dispatch({ type: 'NUOVA_UNITA' });
  }

  return (
    <>
    <ErrorModal prom={upPostate} /> 
      <form onSubmit={submit}>
        <div className="columns">
          <div className="column is-bordered-column">
            <div className="title-area">
              <span className="title is-5 has-grey-color">Anagrafica</span>
            </div>
            {photo ? (
              <figure className="image is-200x200 figure-profile-picture">
                <img className="is-rounded" src={photo} alt="profile-picture"/>
              </figure>
            ): ( <small className="no-photo-message">Nessuna foto inserita</small>) }

            <div className="file file-input-upload padded-input figure-profile-picture-input">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" accept="image/*" onChange={onChangePhoto}/>
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">
                    Immagine
                  </span>
                </span>
                <span className="file-name">
                  {photo && photo.name || "Selezionare un'immagine"}
                </span>
              </label>
            </div>
                    
            <BasicInput 
              label="Nome"
              value={unitaProduttivaModificata.nomeUnita} 
              classes="padded-input"
              readOnly={true} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "nomeUnita", e.target.value) }} 
            />

            <BasicInput 
              label="Codice" 
              value={unitaProduttivaModificata.codiceUnitaProduttiva} 
              classes="padded-input"
              readOnly={true} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "codiceUnitaProduttiva", e.target.value) }} 
            />
            
            <BasicInput 
              label="Alias" 
              value={unitaProduttivaModificata.alias} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "alias", e.target.value) }} 
            />
            
          </div>
          <div className="column is-bordered-column">
            <div className="title-area">
              <span className="title is-5 has-grey-color ">Indirizzi</span>
            </div>
            <BasicInput 
              label="Indirizzo" 
              value={unitaProduttivaModificata.indirizzo} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "indirizzo", e.target.value) }} 
              />

            <BasicInput 
              label="Città" 
              value={unitaProduttivaModificata.citta} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "citta", e.target.value) }} 
              />

            <BasicInput 
              label="CAP" 
              value={unitaProduttivaModificata.cap} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "cap", e.target.value) }} 
              />

            <BasicInput 
              label="Provincia" 
              value={unitaProduttivaModificata.provincia} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "provincia", e.target.value) }} 
              />

            <BasicInput 
              label="Regione" 
              value={unitaProduttivaModificata.regione} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "regione", e.target.value) }} 
              />

            <BasicInput 
              label="Comune" 
              value={unitaProduttivaModificata.comune} 
              classes="padded-input"
              readOnly={false} 
              onChange={e => { setField(setUnitaProduttivaModifata, unitaProduttivaModificata, "comune", e.target.value) }} 
              />
          </div>
        </div>
        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Modifica
          </button>
          </p>
        </div>
        <div className="field is-right">
          <p className="control">
            <button type="button" onClick={ () => toggleDeleteModal() } className="button is-danger" >
              Elimina
          </button>
          </p>
        </div>
        <ModalDelete modal={deleteModal} />
      </form>
    </>
  );
};

export default UnitaProduttivaVuota;
