import * as _ from 'ramda';
import produce from 'immer';
import { Reducer } from 'redux';
import { RepartoModel } from '../Containers/Azienda/Model';
import { ColturaModel } from '../Containers/Azienda/Coltura/Model';

export type State = {
  repartoAttivo: { datiReparto: RepartoModel, coltureCorrenti: ColturaModel[] } | null
  coltureOriginali: ColturaModel[],
  nuovaColtura?: string
  idUnitaProduttiva?: string
};

export type Action = {
  type: 'EDIT_REPARTO',
  reparto: RepartoModel,
  colture?: ColturaModel[]
  idUnitaProduttiva: string
} | {
  type: 'NUOVA_COLTURA'
} | {
  type: 'SET_COLTURE',
  colture: ColturaModel[],
}| {
  type: 'COLTURA_REMOVE'
} | {
  type: 'ADD_COLTURA',
  coltura: ColturaModel
}

export const defaultState: State = { coltureOriginali: [], repartoAttivo: null, nuovaColtura: undefined };

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'SET_COLTURE':
      if (state.repartoAttivo === null) {
        return {
          ...state,
          repartoAttivo: null,
          coltureOriginali: payload.colture,
          nuovaColtura: undefined
        };
      } else return state;
    case 'ADD_COLTURA':
      if(state.repartoAttivo) {
        return produce(state, s => {
          s.repartoAttivo?.coltureCorrenti.push(payload.coltura)
        });
      } else return state;
    case 'EDIT_REPARTO':
      const colture = payload.colture || state.coltureOriginali;
      return {
        ...state,
        repartoAttivo: {
          datiReparto: payload.reparto,
          coltureCorrenti: colture.filter(x => x.idReparto === payload.reparto.idReparto)
        },
        coltureOriginali: colture,
        idUnitaProduttiva: payload.idUnitaProduttiva
      };
    case 'NUOVA_COLTURA':
      if (state.repartoAttivo !== null) {
        return { 
          ...state, 
          coltureOriginali: state.coltureOriginali, 
          // repartoAttivo: null, 
          nuovaColtura: state.repartoAttivo.datiReparto.idReparto 
        };

      } else return state;
      case 'COLTURA_REMOVE':
        if (state.repartoAttivo !== null) {
          return { ...state, coltureOriginali: state.coltureOriginali, repartoAttivo: state.repartoAttivo };
        } else return state;
    default:
      return state;
  }
};