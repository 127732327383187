import * as _ from 'ramda';
import { Reducer } from 'redux';
import { ArticoloIngressoIntervento, ColturaModel } from '../Containers/Azienda/Coltura/Model';

export type State = {
  articoloIngresso: ArticoloIngressoIntervento | null
  idIntervento: string
  nuovoArticoloIngresso?: ColturaModel
  coltura?: ColturaModel
};

export type Action = {
  type: 'NUOVO_ARTICOLO_IN'
  colturaRow: ColturaModel,
  idIntervento: string
} | {
  type: 'EDIT_ARTICOLO_IN'
  articoloIngresso: ArticoloIngressoIntervento,
  coltura: ColturaModel,
  idIntervento: string
};

export const defaultState: State
  = { articoloIngresso: null, idIntervento: "" };

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'NUOVO_ARTICOLO_IN':
      return {
        ...state,
        // articoloIngresso: null,
        nuovoArticoloIngresso: payload.colturaRow,
        idIntervento: payload.idIntervento
      };
    case 'EDIT_ARTICOLO_IN':
      return {
        ...state,
        articoloIngresso: payload.articoloIngresso,
        idIntervento: payload.idIntervento,
        coltura: payload.coltura
      };
    default: return state;
  }
};
