import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect, useRef } from "react";
import * as _ from "ramda";
import moment from "moment";
import { FetchError, Fetch } from "./SharedHooks/Sync";
import Modali, { useModali } from "modali";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/it';
import { Blocco } from "../Containers/Azienda/Coltura/Model";
import { RangeModifier } from "react-day-picker/types/common";
import { User } from "../Reducers/User";
import { TokenStruct } from "../Containers/Login/Model";
import { LoginRes } from "../Containers/Login/LoginForm";

// tslint:disable-next-line: no-any
export const ErrorModal: React.FC<{ prom: Fetch<any> | null }> = ({ prom }) => {
  const [modal, toggle] = useModali({
    title: "Errore",
    animated: true,
    closeButton: true
  });
  const [errors, setErrors] = useState([] as string[]);

  const openModal = () => {
    if (!modal.isShown) {
      toggle();
    }
  };
  useEffect(() => {
    prom?.then(x => {
      if (x.isError) {
        setErrors(x.message.split(';'));
        openModal();
      }
    });
  }, [prom]);
  const ret = (
    <Modali.Modal {...modal}>
      <p>Si sono verificati degli errori: </p>
      <br/>
      {errors.map(error => (
        <p> {error} </p>
      ))}
    </Modali.Modal>
  );
  return ret;

};

/**
 * Basic input element for a form
 * @param label label
 * @param value value
 * @param readOnly readOnly
 * @param onchange onChange
 */
export const BasicInput: React.FunctionComponent<{
  label?: string,
  value?: string | number
  readOnly: boolean
  type?: "text" | "number"
  classes?: string
  step?:string
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void | undefined
  placeholder?: string
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
}> = ({ 
  label = "", 
  value, 
  readOnly, 
  type = "text", 
  classes = "",
  onChange, 
  step = "any", 
  placeholder = "", 
  onFocus = () => {} 
}) => {

  return (
    <>
      <div className={`field has-addons has-fullwidth ${classes}`}>
        {label !== "" && (
          <p className="control">
            <a className="button is-static has-140-fixed-width">
              {label}
            </a>
          </p>
        )}
        <p className="control has-fullwidth">
          <input lang="en" onFocus={onFocus} step={step} className="input" type={type} value={value} readOnly={readOnly} placeholder={placeholder} onChange={(e) => { onChange?.(e); }} />
        </p>

      </div>
    </>
  );
};

export const TimeInput: React.FC<{
  label: string
  value: string | number
  readOnly: boolean
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void | undefined,
}> = ({ label, value, readOnly, onChange }) => {
  return (
    <>
    <div className={`field has-addons has-fullwidth`}>
      <p className="control">
        <a className="button is-static has-140-fixed-width">
          {label}
        </a>
      </p>
      <p className="control has-fullwidth">
        <input value={value} readOnly={readOnly} type="time" className="input" onChange={onChange} />
      </p>
    </div>
  </>
  )
}


export interface DayModifiers {
  today: boolean | undefined;
  outside: boolean | undefined;
  [other: string]: boolean | undefined;
}

const formatDate = (date: Date, format: string, _: string) => {
  return moment(date).format(format)
}

export const DateInput : React.FC<{
  label: string
  defaultValue?: string | Date
  disabledDays?: Blocco[]
  onDayChange: (day: Date, DayModifiers: DayModifiers, dayPickerInput: DayPickerInput) => void
}> = ({ 
  label,
  defaultValue,
  disabledDays,
  onDayChange
}) => {
  const d = defaultValue === "" ? "" : moment(defaultValue).format('L')

  const [disabled, setDisabled] = useState<RangeModifier[]>()

  useEffect(() => {
    if (disabledDays) {
      const tmp: RangeModifier[] = disabledDays.map(x => {
        return { 
          from : moment(x.dataFine).toDate(), 
          to:  moment(x.dataInizio).toDate() 
        }
      })
      setDisabled(tmp)
    }
  }, [])
  
  useEffect(() => {
    if (defaultValue !== "") {
      onDayChange(moment(defaultValue).toDate(), undefined as any, undefined as any);
    }
  }, [])

  return (
    <div className="field has-addons has-fullwidth">
      <p className="control">
        <a className="button is-static has-140-fixed-width">{label}</a>
      </p>
      <div className="control has-fullwidth">
        {defaultValue === "" ? (
          <DayPickerInput
            // value={d}
            onDayChange={onDayChange}
            formatDate={formatDate}
            format="L"
            placeholder={"DD/MM/YYYY"}
            dayPickerProps={{
              locale: 'it',
              localeUtils: MomentLocaleUtils,
              showWeekNumbers: true,
              disabledDays: disabled
            }}
          />
        ) : (
          <DayPickerInput
            value={d}
            onDayChange={onDayChange}
            formatDate={formatDate}
            format="L"
            placeholder={"DD/MM/YYYY"}
            dayPickerProps={{
              locale: 'it',
              localeUtils: MomentLocaleUtils,
              showWeekNumbers: true,
              disabledDays: disabled
            }}
          />
        )}
      </div>
    </div>
  )
}

export function sortByCaseInsensitive<T>(prop: string, obj: T[] | undefined) {
  if (!(_.isEmpty(obj)) && !(_.isNil(obj))) {
    return _.sortBy(_.compose(_.toLower, _.prop(prop)))(obj)
  } else return []
}

export const BasicDropdown: React.FunctionComponent<{
  label?: string
  values: string[]
  onSelection: (value: ChangeEvent<HTMLSelectElement>) => void
  defaultValue: string
}> = ({ label = "", values, onSelection, defaultValue }) => {

  return (
    <>
      <div className="field has-addons">
        {label !== "" && (
          <p className="control">
            <a className="button is-static has-140-fixed-width">{label}</a>
          </p>
        )}
        <div className="control has-fullwidth">
          <div className="select is-flex is-fullwidth ">
            <select onChange={onSelection} > 
              {_.contains(defaultValue, values) ? ( // vedi risoluzione issue #30
                <option value={defaultValue}>
                  {defaultValue}
                </option>
              ) : (
                <option value="0">
                  -- {defaultValue} --
                </option>
              )}
              {values.map((val, i) => {
                return (
                  <option key={i} value={val}>{val}</option>
                );
              })}
            </select>
          </div>
        </div>

      </div>
    </>
  );
};

export const BasicTextArea: React.FunctionComponent<{
  label: string,
  value: string | number,
  readOnly: boolean,
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void | undefined,
}> = ({ label, value, readOnly, onChange }) => {
  return (
    <>
      <div className="field-label is-flex">
        <label className="label has-10-padding has-text-grey has-text-weight-normal">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control">
            <textarea className="textarea" value={value} readOnly={readOnly} onChange={(e) => { onChange(e); }} />
          </p>
        </div>
      </div>
    </>
  );
};

export const BasicCheckbox: React.FC<{
  label: string
  checked: boolean | undefined
  clsName?: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void | undefined
  hideFieldLabel?: boolean
}> = ({ label, checked, onChange, clsName, hideFieldLabel }) => {

  const baseClassCheckbox = "label has-10-padding has-text-grey has-text-weight-normal has-text-no-wrap has-text-left";
  const classes =
    clsName ? `${baseClassCheckbox} ${clsName}` : baseClassCheckbox;

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              {hideFieldLabel ? null : (
                <p className="control">
                  <label className={classes}>{label}</label>
                </p>
              )}
            </td>
            <td>
              <p className="control">
                <input
                  className="checkbox-input is-clickable"
                  type="checkbox"
                  id="c1"
                  name="c-group"
                  checked={checked}
                  onChange={e => {
                    onChange(e);
                  }}
                />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

/* Backend interop */

// STAGING
export const STAGING_BASE_URL = "https://api.staging.futurelab.cloud/quadernodicampagna/v1"

// PRODUCTION
// export const PROD_BASE_URL = "https://api.zeuslab.it/quadernodicampagna/v1"
export const PROD_BASE_URL = "https://api.futurelab.cloud/quadernodicampagna/v1"

let user : User | undefined = undefined

export function BASE_URL() {

  if (window.location.hostname.startsWith('localhost')) {
    return STAGING_BASE_URL
  }

  const isdev = window.location.hostname.includes('staging')
  return isdev ? STAGING_BASE_URL : PROD_BASE_URL
}

const u = localStorage.getItem('user')
if (u != null && u !== "") {
  user = JSON.parse(u)
}

let loginRes: LoginRes | undefined = undefined
const lr = localStorage.getItem('logindata')
if (lr !== null && lr !== "") {
  loginRes = JSON.parse(lr)
}

export const fToken = user != undefined ? user.token : ''

export const token = `Bearer ${fToken}`

export let ID_AZIENDA = loginRes?.azienda

export let ID_UNITA = loginRes?.unitaProduttiva

export const PUNTO_AZIENDA = localStorage.getItem('codiceunitaproduttiva')

// tslint:disable-next-line: no-any
export function postObject<T>(endpoint: string, obj: T): Promise<Response> {
  const url = BASE_URL()
  return fetch(`${url}/${endpoint}`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": token
    },
    body: JSON.stringify(obj),
  });
}

export function postPhoto(endpoint: string, obj: FormData): Promise<Response> {
  const url = BASE_URL()
  return fetch(`${url}/${endpoint}`, {
    method: 'POST',
    headers: {
      "Authorization": token
    },
    body: obj
  })
}

export function fetchPhoto(endpoint: string): Promise<any> {
  const url = BASE_URL()
  return fetch(`${url}/${endpoint}`, { headers: { Authorization: token } })
    .then(res => res.blob())
    .then(blob => new Promise((res, rej) => {
      const reader = new FileReader()
      reader.onloadend = () => res(reader.result)
      reader.onerror = rej
      reader.readAsDataURL(blob)
    }))
}

export function deleteObjectAtId(endpoint: string, id: string) {
  return deleteObject(`${endpoint}/${id}`)
}

export function deleteObject(endpoint: string){
  const url = BASE_URL()
  return fetch(`${url}/${endpoint}`, {
    method: "DELETE",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": token
    }
  });
}

// tslint:disable-next-line: no-any
export function fetchObjects<T extends any = any>(endpoint: string): Promise<T> {
  
  const url = BASE_URL()
  return fetch(`${url}/${endpoint}`, { headers: { Authorization: token } })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
    });
}

export function fetchResponse(endpoint: string): Promise<Response> {
  const url = BASE_URL()
  return fetch(`${url}/${endpoint}`, { headers: { Authorization: token } });
}

export function SentenceCase(text: string): string {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const parseJwt = (token: string) => {
  try {
    if(token !== "") {
      return JSON.parse(atob(token.split('.')[1])) as TokenStruct
    }
  } catch {
    return null
  }
}
