import React, { useEffect, useState } from 'react'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PersonaIntervento, Persona, InterventoModel } from '../../Azienda/Coltura/Model'
import { fetchObjects, fetchPhoto } from '../../../Utilities/SharedComponent'
import * as _ from 'ramda'

const promiseFotoPersona = (idPersona: string) => fetchObjects(`foto/persona/${idPersona}`)

const PersonaleRapida: React.FC<{
  personeIntervento: PersonaIntervento[]
  persone: Persona[]
  personaSelezionata: (p: PersonaIntervento | Persona) => void
  personaModificata?: PersonaIntervento | Persona
  interventoSelezionato: InterventoModel | undefined
}> = ({ personeIntervento, persone, personaSelezionata, personaModificata, interventoSelezionato }) => {
  
  const [p, setP] = useState<Persona[]>()

  useEffect(() => {
    if (!_.isNil(personaModificata)) {
      setPersonaAttiva(personaModificata.idPersona)
      personaSelezionata(personaModificata)
    }
  }, [personaModificata])

  //const [foto, setFoto] = useState<{ [key: string]: any }>()
  // const [photo, setPhoto] = useState<any>(null)
  // useEffect(() => {
    // persone.forEach(persona => {
      // fetchPhoto(`foto/persona/${persona.idPersona}`)
      //   .then(src => {
      //     if (!(src === "Immagine non trovata"))
      //     setFoto({ [persona.idPersona]: src })
      //   })
    // })
  // }, [persone])

  const [personaAttiva, setPersonaAttiva] = useState("")
  const handleClickIntervento = (persona: PersonaIntervento | Persona) => {
    setPersonaAttiva(persona.idPersona ?? "")
    personaSelezionata(persona)
  }

  const tileClasses = (p: PersonaIntervento | Persona) => 
    p.idPersona === personaAttiva ? 'tile is-child notification is-success' : 'tile is-child notification'

  return (
    <>
      <h1 className="title is-5 has-text-centered">
        <FontAwesomeIcon icon={faUsers} size="1x" className="has-10-margin-right" />
        Personale
      </h1>
      <div className="is-scrollable">
        {interventoSelezionato && personeIntervento.length > 0 ? (
          personeIntervento.map((persona, i) => (
            <div className="tile is-ancestor is-marginless" key={i} onClick={_ => handleClickIntervento(persona)}>
              <div className="tile ">
                <div className="tile is-parent is-few-padding">
                  <article className={tileClasses(persona)}>
                    {/* {foto?.[persona.idPersona] && (
                      <figure className="image is-4by3 is-image-cover">
                        <img src={foto?.[persona.idPersona]} />
                      </figure>
                    )} */}
                    <p className="is-inline has-10-margin-right"><b>{persone.find(p => p.idPersona === persona.idPersona)?.codicePersona}</b></p>
                    <p className="is-inline">{persona.nome}</p>
                  </article>
                </div>
              </div>
            </div>
          ))
        ) : interventoSelezionato && persone.length > 0 ? (
          persone.map((persona, i) => (
            <div className="tile is-ancestor is-marginless" key={i} onClick={_ => handleClickIntervento(persona)}>
              <div className="tile ">
                <div className="tile is-parent is-few-padding">
                  <article className={tileClasses(persona)}>
                    {/* {foto?.[persona.idPersona] && (
                      <figure className="image is-4by3 is-image-cover">
                        <img src={foto?.[persona.idPersona]} />
                      </figure>
                    )} */}
                    <p className="is-inline has-10-margin-right"><b>{persone.find(p => p.idPersona === persona.idPersona)?.codicePersona}</b></p>
                    <p className="is-inline">{persona.nomePersona}</p>
                  </article>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h1 className="has-text-centered">Selezionare una persona</h1>
        )}
      </div>
    </>
  )
}

export default PersonaleRapida