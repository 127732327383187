import { setPath, useRoutes } from "hookrouter";
import React from "react";
import "./App.css";
import LoginForm from "./Containers/Login/LoginForm";
import Persone from "./Containers/Persone/Persone";
import Macchine from "./Containers/Macchinari/Macchine";
import Interventi from "./Containers/Interventi/Interventi";
import RilevazioniArticolo from "./Containers/Rilevazioni/RilevazioniArticolo";
import RilevazioniIntervento from "./Containers/Rilevazioni/RilevazioniIntervento";
import MainPage from "./Components/Main/Main";
import NotFound from "./Components/NotFound/NotFound";
import { Provider } from "react-redux";
import { store } from './Reducers/Root';
import { ToastProvider } from "react-toast-notifications";
import TimeManagement from "./Containers/TimeManagement/TimeManagement";
import QuickOperation from "./Containers/QuickOperation/QuickOperation";
import Articoli from "./Containers/Articoli/Articoli";

const routes = {
  "/": () => {
    setPath("/");
    return (
      <Provider store={store}>
        <LoginForm />
      </Provider>
    )
  },
  "/dashboard": () => {
    setPath("/dashboard");
    return (
      <ToastProvider>
        <Provider store={store}>
          <MainPage />
        </Provider>
      </ToastProvider>
    );
  },
  "/articoli": () => {
    setPath("articoli");
    return (
      <ToastProvider>
        <Provider store={store}>
          <Articoli />
        </Provider>
      </ToastProvider>
    )
  },  
  "/personale": () => {
    setPath("personale");
    return (
      <ToastProvider>
        <Provider store={store}>
          <Persone />
        </Provider>
      </ToastProvider>
    )
  },
  "/macchine": () => {
    setPath("macchine");
    return (
      <ToastProvider>
        <Provider store={store}>
          <Macchine />
        </Provider>
      </ToastProvider>
    )
  },
  "/interventi": () => {
    setPath("interventi");
    return (
      <Provider store={store}>
        <Interventi />
      </Provider>
    )
  },
  "/rilevazioniarticolo": () => {
    setPath("rilevazioniarticolo");
    return (
      <Provider store={store}>
        <RilevazioniArticolo />
      </Provider>
    )
  },
  "/rilevazioniintervento": () => {
    setPath("rilevazioniintervento");
    return (
      <Provider store={store}>
        <RilevazioniIntervento />
      </Provider>
    )
  },
  "/quickoperation": () => {
    setPath("quickoperation")
    return <QuickOperation />
  },
  "/timemanagement": () => {
    setPath("timemanagement")
    return <TimeManagement />
  }
};


const App: React.FC = () => {
  const routeResult = useRoutes(routes);

  return routeResult || <NotFound />;
};

export default App;
