import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import Orologio from '../orologio/orologio'
import { RepartoModel } from '../../Azienda/Model'
import { isValidDateRange } from '../../../Utilities/Functions'
import * as _ from 'ramda'
import { ColturaModel, InterventoModel } from '../../Azienda/Coltura/Model'

const Produzione: React.FC<{ 
  colture: ColturaModel[]
  reparti: RepartoModel[]
  interventoSelezionato: (i: InterventoModel) => void
  visualizzaStorico: () => void
}> = ({ colture, reparti, interventoSelezionato, visualizzaStorico }) => {

  const getInterventi = (colt: ColturaModel) =>
    colt.interventi.filter(i => i.tipoIntervento.utilizzoPersone && isValidDateRange(i))

  const getReparto = (c: ColturaModel) =>
    reparti.find(r => r.idReparto === c.idReparto)

  const [interventoAttivo, setInterventoAttivo] = useState("")

  const tileClasses = (i: InterventoModel) => 
    i.idIntervento === interventoAttivo ? 'tile is-child notification is-success' : 'tile is-child notification'

  const handleInterventoAttivo = (i: InterventoModel) => { 
    setInterventoAttivo(i.idIntervento ?? "")
    interventoSelezionato(i)
  }

  return (
    <>
      <h1 className="title is-4 has-text-centered">
        <FontAwesomeIcon icon={faLeaf} size="1x" className="has-10-margin-right" />
        Produzione
      </h1>
      <section className="is-scrollable-column">
        {colture.map(coltura => (
          getInterventi(coltura).map((intervento, i) => (
            <div className="tile is-ancestor" key={i}>
              <div className="tile">
                <div className="tile is-parent">
                  <article className={tileClasses(intervento)} onClick={_ => handleInterventoAttivo(intervento)}>
                    <p className="title is-4 is-marginless">{getReparto(coltura)?.nomeReparto}</p>
                    <p className="subtitle is-marginless">{coltura.descrizione}</p>
                    <div className="content is-marginless">{intervento.tipoIntervento.descrizione}</div>
                  </article>
                </div>
              </div>
            </div>
          ))
        ))}
        {colture.length === 0 && (<progress className="progress is-small is-success" max="100">15%</progress>)}
      </section>
      <Orologio 
        visualizzaStorico={visualizzaStorico}
        storicoAbilitato={interventoAttivo === ""} />
    </>
  )
}

export default Produzione