import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "../../../Utilities/SharedHooks/State";
import { User } from "../../../Reducers/User";
// import { navigate } from "hookrouter";
import { AllActions } from "../../../Reducers/Root";
import { navigate as navigateTo } from "../../../Reducers/Route";
import { navigate, resetPath } from "hookrouter";

const UserInfo: React.FC = (props) => {
    const dispatch = useDispatch<AllActions>();
    const lsUser = localStorage.getItem('user')
    const user: User = (lsUser != null && lsUser !== "")  ? JSON.parse(lsUser) : {} as User
    const nomeAzienda = user.username

    const logout = () => {
        localStorage.removeItem('userrole')
        localStorage.removeItem('user')
        localStorage.removeItem('roles')
        localStorage.removeItem('logindata')
        dispatch(navigateTo("HOME"))
        navigate('/')

        dispatch({type: 'DESTROY_ALL' })
    }

    return (
        <section className="section is-fullheight ">
            <div className="columns is-centered is-mobile">
                <div className="column has-text-centered">
                    <FontAwesomeIcon icon={faUserCircle} size="7x" />
                    <h3 className="title is-5 has-20-margin-top">{nomeAzienda}</h3>
                </div>
            </div>
            <a href="#" onClick={_ => logout()}><FontAwesomeIcon icon={faSignOutAlt} size="1x" /> <span>Disconnettiti</span></a>
        </section>
    );
};

export default UserInfo;
