import { Reducer, Action } from "redux";

export default <S, A extends Action>(initialState: S, ...reducers: Array<Reducer<S, A>>): Reducer<S, A> => {

  return (prevState, value, ...args) => {
    const prevStateIsUndefined = typeof prevState === 'undefined';
    const valueIsUndefined = typeof value === 'undefined';

    if (prevStateIsUndefined && valueIsUndefined && initialState) {
      return initialState;
    }
    const seed: S = prevStateIsUndefined && !valueIsUndefined ? initialState : prevState as S;
    return reducers.reduce((newState, reducer, index) => {

      return reducer(newState, value, ...args);
    }, seed);
  };
};

// Reducer combining cannot be correctly typed as of typescript 3.8 (covariance) so it gets cast to any as a workaround
// tslint:disable-next-line:no-any
export const anyList = (...args: any[]): any[] => args;
