import React, { useState } from "react";
import { RepartoModel } from "../Model";
import { ErrorModal, BasicInput, postPhoto } from "../../../Utilities/SharedComponent";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import produce from "immer";
import { useToasts } from "react-toast-notifications";
import { navigateBack } from "../../../Reducers/Route";
import { useDispatch } from "../../../Utilities/SharedHooks/State";
import { AllActions } from "../../../Reducers/Root";

const defaultReparto : RepartoModel = {
  nomeReparto: ""
, codiceReparto: ""
, estensione: 0
}

function setField<T, K extends keyof T>(fn: (React.Dispatch<React.SetStateAction<T>>), obj: T, key: K, value: any) {
  fn(produce(obj, (x: T) => { x[key] = value }))
};

const NuovoReparto: React.FC<{ idUnitaProduttiva: string, onCreate : (_: RepartoModel) => void }> = (props) => {
  const [nuovoReparto, setNuovoReparto] = useState(defaultReparto);
  const [postReparti, repartiPostati] = usePost<Omit<RepartoModel, "idReparto">>(`reparti/${props.idUnitaProduttiva}`);
  const [photo, setPhoto] = useState()
  const { addToast } = useToasts();
  const dispatch = useDispatch<AllActions>();
  const onChangePhoto = (e : any) => {
    e.preventDefault();
    const file = e.target.files[0]
    setPhoto(file)    
  }
  
  const postProfilePhoto = (idReparto: any) => {
    let formData = new FormData();
    if (photo && idReparto) {
      formData.append('image', photo, photo.name);

      postPhoto(`foto/reparto/${idReparto}`, formData)
        .then(_ => addToast('Reparto salvato correttamente', { appearance: 'success', autoDismiss: true }))
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const _nuovoReparto: Omit<RepartoModel, "idReparto"> = {
      codiceReparto: nuovoReparto.codiceReparto
    , nomeReparto: nuovoReparto.nomeReparto
    , estensione: nuovoReparto.estensione,
    };
    setNuovoReparto(_nuovoReparto);

    postReparti(_nuovoReparto)
      .then(idReparto => {
        if (!idReparto.isError) {
          postProfilePhoto(idReparto)
          props.onCreate(_nuovoReparto);
          dispatch(navigateBack())
        }
      })
  };

  return (
    <>
      <ErrorModal prom={repartiPostati} />
      <form onSubmit={handleFormSubmit} className="has-450-max-width">
        
          <div className="file file-input-upload">
            <label className="file-label">
              <input className="file-input" type="file" name="resume" accept="image/*" onChange={onChangePhoto}/>
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  Immagine
                </span>
              </span>
              <span className="file-name">
                {photo && photo.name || "Selezionare un'immagine"}
              </span>
            </label>
          </div>

          <BasicInput 
            label="Nome" 
            value={nuovoReparto.nomeReparto} 
            readOnly={false} 
            onChange={ e => setField(setNuovoReparto, nuovoReparto, "nomeReparto", e.target.value) } 
          />
          
          <BasicInput 
            label="Estensione" 
            value={nuovoReparto.estensione} 
            readOnly={false} 
            onChange={ e => setField(setNuovoReparto, nuovoReparto, "estensione", e.target.value) } 
          />

          <BasicInput 
            label="Codice" 
            value={nuovoReparto.codiceReparto} 
            readOnly={false} 
            onChange={ e => setField(setNuovoReparto, nuovoReparto, "codiceReparto", e.target.value) } 
          />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Salva reparto
          </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default NuovoReparto;
