import * as _ from 'ramda';
import { Reducer } from 'redux';
import { MacchinaIntervento, ColturaModel } from '../Containers/Azienda/Coltura/Model';

export type State = {
  macchina: MacchinaIntervento | null,
  idIntervento: string;
  nuovaMacchina?: ColturaModel
  coltura?: ColturaModel
};

export type Action = {
  type: 'NUOVA_MACCHINA'
  colturaRow: ColturaModel,
  idIntervento: string
} | {
  type: 'EDIT_MACCHINA'
  macchina: MacchinaIntervento
  coltura: ColturaModel
  idIntervento: string
};

export const defaultState: State
  = { macchina: null, idIntervento: "" };

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;

  switch (payload.type) {
    case 'NUOVA_MACCHINA':
      if (state.macchina !== null) {
        return {
          ...state,
          // macchina: null,
          nuovaMacchina: payload.colturaRow,
          idIntervento: payload.idIntervento
        };
      } else return state;
    case 'EDIT_MACCHINA':
      return {
        ...state,
        macchina: payload.macchina,
        idIntervento: payload.idIntervento,
        coltura: payload.coltura
      };
    default: return state;
  }
};
