import React, { useState, useEffect } from 'react';
import { ArticoloUscitaIntervento, initialStateArticoloUscitaIntervento, ArticoloUscitaInterventoNew, ColturaModel, Articolo, Persona, UnitaMisura } from '../../Coltura/Model';
import { useDispatch, useSelector } from '../../../../Utilities/SharedHooks/State';
import { UNDO_ACTION, UNDO, AllActions } from '../../../../Reducers/Root';
import { fetchObjects, BasicDropdown, BasicInput, ErrorModal, DateInput, ID_AZIENDA, PUNTO_AZIENDA, ID_UNITA, postObject } from '../../../../Utilities/SharedComponent';
import * as _ from 'ramda';
import produce from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { usePost } from '../../../../Utilities/SharedHooks/Sync';
import { finalizeRestore } from '../../../../Utilities/Redux/Restore';
import { navigateBack } from '../../../../Reducers/Route';
import * as uuid from "uuid"
import Modali, { useModali } from 'modali'

const fetchArticoli = fetchObjects(`${ID_AZIENDA}/${PUNTO_AZIENDA}/articoli`);
const fetchPersone = fetchObjects(`persone/${ID_UNITA}`)
const fetchPersona = (idArticoloUscita: string) => fetchObjects(`articoloUscita/${idArticoloUscita}`)
const fetchUM = fetchObjects(`${ID_AZIENDA}/unitamisura`)

export const ArticoloUscita: React.FC<{ onSubmit: (_: ColturaModel) => void }> = ({ onSubmit }) => {
  const articoloUscita = useSelector(x => x.present.articoloUscita);
  const coltura = useSelector(x => x.present.coltura)
  const idIntervento = useSelector(x => x.present.idIntervento)
  const dispatch = useDispatch();

  const [quantitaCalcolata, setQuantitaCalcolata] = useState(0)
  const [articoli, setArticoli] = useState<Articolo[]>([])
  const [persone, setPersone] = useState<Persona[]>([])
  const [personaSelezionata, setPersonaSelezionata] = useState<Persona>()
  const [articoloModificato, setArticoloModificato] = useState(articoloUscita)
  const [articoloSelezionato, setArticoloSelezionato] = useState<Articolo | null>(null)
  const [postColtura, colturaPostata] = usePost(`colture/${coltura?.idReparto}`)
  const [persona, setPersona] = useState<Persona>()
  const [um, setUm] = useState<UnitaMisura[]>([])

  useEffect(() => {
    fetchUM
      .then(res => setUm(res))
  }, [])

  useEffect(() => {
    fetchArticoli
      .then(res => setArticoli(res))
  }, [])

  useEffect(() => {
    if (articoli.length > 0 && articoloModificato) {
      const tmp = articoli.find(a => a.codiceArticolo === articoloModificato.codiceArticolo)
      if (tmp) {
        setArticoloSelezionato(tmp)
      }
    }
  }, [articoli])

  useEffect(() => {
    fetchPersone
      .then(res => setPersone(res))
  }, [])

  useEffect(() => {
    if (articoloUscita != null) {
      fetchPersona(articoloUscita.idArticoloUscitaIntervento)
        .then(res => setPersona(res))
    }
  }, [articoloUscita])

  useEffect(() => {
    if (persona !== undefined) {
      setPersonaSelezionata(persona)
    }
  }, [persona])

  const handleUMSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const um: string = e.target.value
    if (um !== "0") {
      handleTextboxChange((f: ArticoloUscitaInterventoNew) => { f.unitaDiMisuraVendita = um })
    }
  }

  const handleSelezionePersona = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const n = e.target.value.split("-")[1].trimStart()
    const pers =
      _.find(
        _.propEq("nomePersona", n)
        , persone);
    if (pers) {
      setPersonaSelezionata(pers);
    }
  };

  const handleTextboxChange = (fn: (v: ArticoloUscitaIntervento) => void) => {
    setArticoloModificato(produce(articoloModificato, fn));
  };

  useEffect(() => {
    if (articoloModificato) {
      setQuantitaCalcolata(articoloModificato.colli * articoloModificato.coefficienteConversione)
    }
  }, [articoloModificato?.colli, articoloModificato?.coefficienteConversione])

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!articoloModificato) return

    if (articoloUscita && personaSelezionata) {
      const nuovoArticoloOut: ArticoloUscitaIntervento = {
        idPersona: personaSelezionata?.idPersona || ""
        , idArticoloUscitaIntervento: articoloUscita.idArticoloUscitaIntervento
        , codiceInternoArticolo: articoloModificato.codiceInternoArticolo
        , codiceArticolo: articoloModificato.codiceArticolo
        , data: articoloModificato.data
        , unitaDiMisuraVendita: articoloModificato.unitaDiMisuraVendita
        , quantita: Number(quantitaCalcolata.toFixed(2))
        , colli: articoloModificato.colli
        , aliquota: articoloModificato.aliquota
        , numeroListino: articoloModificato.numeroListino
        , calibro: articoloModificato.calibro
        , listinoIvato: articoloModificato.listinoIvato
        , descrizioneArticolo: articoloModificato.descrizioneArticolo
        , coefficienteConversione: articoloModificato.coefficienteConversione
        , rilevazioni: []
      }

      if (coltura) {
        const index = coltura.interventi.findIndex(s => s.idIntervento === idIntervento)
        const newIntervento = produce(coltura.interventi[index], i => {
          const indexArt = i.articoliUscita.findIndex(s => s.idArticoloUscitaIntervento === articoloUscita.idArticoloUscitaIntervento)
          i.articoliUscita[indexArt] = nuovoArticoloOut
        })
        const newStateColtura: ColturaModel = produce(coltura, c => {
          c.interventi[index] = newIntervento
        });
        if (newStateColtura !== undefined) {

          postObject(`articolouscita/${idIntervento}`, nuovoArticoloOut)
            .then(res => {
              if (res.ok) {
              
                onSubmit(newStateColtura)
                dispatch({ type: "COLTURA_EDIT", coltura: newStateColtura })
                dispatch({ type: "INTERVENTO_EDIT", intervento: newIntervento })
                dispatch(navigateBack())
              }
            })
            .catch(err => console.log(err))

          // postColtura(newStateColtura).then(e => {
          //   if (!e.isError) {
          //     onSubmit(newStateColtura)
          //     dispatch({ type: "COLTURA_EDIT", coltura: newStateColtura })
          //     dispatch({ type: "INTERVENTO_EDIT", intervento: newIntervento })
          //     dispatch(navigateBack())
          //   }
          // });
        }
      }
    }

  };

  const formBody =
    articoloModificato && (
      <form onSubmit={submitForm} className="has-450-max-width">
        <span>
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
          <span className="subtitle has-text-grey">{articoloModificato.descrizioneArticolo}</span>
        </span>
        <br />
        <br />

        <DateInput
          label="Data utilizzo"
          disabledDays={coltura?.blocchi}
          defaultValue={articoloModificato.data}
          onDayChange={(e) => { handleTextboxChange((p: ArticoloUscitaIntervento) => { p.data = moment(e).format('YYYY-MM-DD') }) }}
        />

        <BasicDropdown label={"Operatore"} defaultValue={persona && persona.codicePersona + " - " + persona.nomePersona || "Seleziona un operatore"} values={persone.map(x => x.codicePersona + " - " + x.nomePersona)} onSelection={(e) => { handleSelezionePersona(e); }} />

        <BasicDropdown
          label="Calibro"
          defaultValue={articoloModificato.calibro ?? ""}
          values={articoloSelezionato?.campiLiberi?.map(c => c.valore) ?? []}
          onSelection={e => handleTextboxChange((p: ArticoloUscitaInterventoNew) => { p.calibro = e.target.value })}
        />

        <BasicInput
          label="Colli"
          step=".1"
          value={articoloModificato.colli}
          readOnly={false}
          type="number"
          onChange={(e) => { handleTextboxChange((p: ArticoloUscitaIntervento) => { p.colli = Number(e.target.value); }); }}
        />

        <BasicInput
          label="Coefficiente"
          value={articoloModificato.coefficienteConversione || 0}
          readOnly={false}
          type="number"
          onChange={(e) => { handleTextboxChange((p: ArticoloUscitaIntervento) => { p.coefficienteConversione = Number(e.target.value); }); }}
        />

        <div className="columns">
          <div className="column">
            <BasicInput
              label="Quantità"
              value={quantitaCalcolata.toFixed(2)}
              readOnly={false}
              onChange={(e) => { handleTextboxChange((p: ArticoloUscitaIntervento) => { p.quantita = Number(e.target.value); }); }}
            />

          </div>
          <div className="column">
            <BasicInput
              label="Unità di misura"
              value={articoloUscita && articoloUscita.unitaDiMisuraVendita || ""}
              readOnly={true}
            />
            {/* <BasicDropdown label={"Unità di misura"} defaultValue={articoloModificato.unitaDiMisuraVendita} values={um.map(x => x.descrizione).filter(x => x !== articoloModificato.unitaDiMisuraVendita)} onSelection={(e) => { handleUMSelection(e) }} /> */}
          </div>
        </div>

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Applica
            </button>
          </p>
        </div>
      </form>
    );

  return (
    <>{formBody}</>
  )
}

export const NuovoArticoloUscita: React.FC<{ onCreate: (c: ColturaModel) => void }> = ({ onCreate }) => {
  const [articoli, setArticoli] = useState<Articolo[]>([])
  const [articoloSelezionato, setArticoloSelezionato] = useState<Articolo>()

  const [articolo, setArticolo] = useState<ArticoloUscitaInterventoNew>(initialStateArticoloUscitaIntervento)
  const [quantitaCalcolata, setQuantitaCalcolata] = useState(0)
  const [error, setError] = useState("")
  const [persone, setPersone] = useState<Persona[]>([])
  const [personaSelezionata, setPersonaSelezionata] = useState<Persona>()
  const [um, setUm] = useState<UnitaMisura[]>([])

  const stateColtura = useSelector(state => state.present.nuovoArticoloUscita)
  const dispatch = useDispatch<AllActions>()
  const idIntervento = useSelector(state => state.present.idIntervento)
  const interventoCorrente = useSelector(state => state.present.interventoSelectedRow)
  // const [postColtura, colturaPostata] = usePost(`colture/${stateColtura?.idReparto}`)

  useEffect(() => {
    fetchPersone
      .then(res => setPersone(res))
      .catch(err => setError(err))
  }, [])

  useEffect(() => {
    caricaArticoli()
  }, [interventoCorrente])

  const caricaArticoli = () => {
    fetchArticoli
      .then((data: Articolo[]) => {

        if (interventoCorrente) {
          const filtroTipoArticolo = interventoCorrente.tipoIntervento.tipiArticoloUscita
          if (filtroTipoArticolo.length === 0) {
            setArticoli(data)
            return
          }
          const arts: Articolo[] = []
          data.map(d => {
            for (const f of filtroTipoArticolo) {
              if (d.tipoArticolo === f) {
                arts.push(d)
              }
            }
          })
          setArticoli(arts)
        }
      })
      .catch((err) => setError(err))
  }

  useEffect(() => {
    fetchUM
      .then(res => setUm(res))
      .catch((err) => setError(err))
  }, [])

  useEffect(() => {
    if (articoloSelezionato) {
      setQuantitaCalcolata(articolo.colli * articoloSelezionato.coefficienteConversione)
    }
  }, [articolo.colli, articolo.coefficienteConversione])

  const handleArticoloSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tmp = e.target.value.split('-')[0].trimEnd()
    const art =
      _.find(
        _.propEq("codiceArticolo", tmp)
        , articoli);
    if (art) {
      setArticoloSelezionato(art);
    } else setArticoloSelezionato(undefined)
  };

  const [modal, toggle] = useModali({
    title: "Errore",
    animated: true,
    closeButton: true
  })
  const openModal = () => {
    if (!modal.isShown) {
      toggle()
    }
  }

  const submitForm = (type: "salva" | "salva-continua") => {

    const cal = calibri.length === 1 ? calibri[0] : articolo.calibro

    if (articoloSelezionato) {
      const nuovoArticoloOut: ArticoloUscitaIntervento = {
        idArticoloUscitaIntervento: uuid.v4()
        , idPersona: personaSelezionata?.idPersona ?? ""
        , codiceInternoArticolo: articoloSelezionato.codiceInternoArticolo
        , codiceArticolo: articoloSelezionato.codiceArticolo
        , descrizioneArticolo: articoloSelezionato.descrizione
        , data: articolo.data
        , unitaDiMisuraVendita: articoloSelezionato.unitaDiMisuraVendita
        , quantita: Number(quantitaCalcolata.toFixed(2))
        , colli: articolo.colli
        , calibro: cal ?? ''
        , aliquota: articoloSelezionato.aliquota
        , numeroListino: articoloSelezionato.numeroListino
        , listinoIvato: articoloSelezionato.listinoIvato
        , coefficienteConversione: articoloSelezionato.coefficienteConversione
        , rilevazioni: []
      };

      if (stateColtura) {
        const index = stateColtura.interventi.findIndex(s => s.idIntervento === idIntervento)
        const newIntervento = produce(stateColtura.interventi[index], i => {
          i.articoliUscita.push({
            ...nuovoArticoloOut
          })
        })

        const newStateColtura: ColturaModel = produce(stateColtura, c => {
          c.interventi[index] = newIntervento
        });

        if (interventoCorrente) {

          postObject(`articolouscita/${interventoCorrente.idIntervento}`, nuovoArticoloOut)
            .then(res => {
              if (res.status === 409) {
                setError("Nessun composto inserito.")
                openModal()
              } else if (res.ok) {
                onCreate(newStateColtura)
                dispatch({ type: "COLTURA_EDIT", coltura: newStateColtura })
                dispatch({ type: "INTERVENTO_EDIT", intervento: newIntervento })

                if (type === "salva") {

                  setArticoloSelezionato(undefined)
                  setArticoli([])
                  setQuantitaCalcolata(0)
                  caricaArticoli()
                  setCalibri([])
                  setArticolo({ ...initialStateArticoloUscitaIntervento, data: nuovoArticoloOut.data });

                  dispatch({
                    type: 'NUOVO_ARTICOLO_OUT',
                    colturaRow: newStateColtura,
                    idIntervento: interventoCorrente?.idIntervento ?? ""
                  })

                } else {
                  dispatch(navigateBack())
                  setArticolo(initialStateArticoloUscitaIntervento);
                }
              }
              else {
                setError("Errore durante il salvataggio dell'articolo, riprovare.")
                openModal()
              }
            })
            .catch(err => {
              console.log(`errore durante l'inserimento del nuovo articolo in uscita`, err.message)
            })
        }
      }
    }
  };

  const handleSelezionePersona = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value)
    if (e.target.value !== "0") {

      const n = e.target.value.split("-")[1].trimStart()
      const pers =
        _.find(
          _.propEq("nomePersona", n)
          , persone);
      if (pers) {
        setPersonaSelezionata(pers);
      } else setPersonaSelezionata(undefined);
    }
  };

  const handleTextboxChange = (fn: (v: ArticoloUscitaInterventoNew) => void) => {
    setArticolo(produce(articolo, fn));
  };

  const [calibri, setCalibri] = useState<string[]>([])

  useEffect(() => {
    if (articoloSelezionato) {
      const _calibri = articoloSelezionato.campiLiberi?.map(c => c.valore) ?? []
      setCalibri(_calibri)
    }
  }, [articoloSelezionato])

  return (
    <form className="has-450-max-width">
      {/* <ErrorModal prom={colturaPostata} /> */}
      <Modali.Modal {...modal}>
        <p>Si sono verificati degli errori: </p>
        <br/>
        {error}
      </Modali.Modal>

      <span><FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" /><span className="subtitle has-text-grey">Aggiungi un articolo in uscita</span></span>

      <br />
      <br />

      <DateInput
        label="Data utilizzo"
        disabledDays={stateColtura?.blocchi}
        onDayChange={(e) => { handleTextboxChange((p: ArticoloUscitaInterventoNew) => { p.data = e.toISOString() }) }}
      />

      <BasicDropdown
        label={"Operatore"}
        defaultValue="Seleziona persona"
        values={persone.map(x => x.codicePersona + " - " + x.nomePersona)}
        onSelection={(e) => { handleSelezionePersona(e); }}
      />

      <BasicDropdown
        label="Articolo"
        defaultValue="Seleziona articolo"
        values={articoli.map(x => `${x.codiceArticolo} - ${x.descrizione}`)}
        onSelection={(e) => { handleArticoloSelection(e); }}
      />

      <BasicDropdown
        label="Calibro"
        defaultValue={calibri.length === 1 ? calibri[0] : "Seleziona calibro"}
        values={calibri}
        onSelection={e => handleTextboxChange((p: ArticoloUscitaInterventoNew) => { p.calibro = e.target.value })}
      />

      <BasicInput
        type="number"
        label="Colli"
        step=".1"
        value={articolo.colli}
        placeholder={String(articolo.colli) || "0"}
        readOnly={false}
        onChange={(e) => { handleTextboxChange((p: ArticoloUscitaInterventoNew) => { p.colli = Number(e.target.value); }); }}
      />

      <BasicInput
        label="Coefficiente"
        value={articoloSelezionato?.coefficienteConversione.toString() ?? "0"}
        readOnly={true}
      // onChange={(e) => { handleTextboxChange((p: ArticoloUscitaInterventoNew) => { p.coefficienteConversione = Number(e.target.value); }); }}
      />

      <div className="columns">
        <div className="column">
          <BasicInput
            label="Quantità"
            value={quantitaCalcolata.toFixed(2)}
            readOnly={true}
          />
        </div>
        <div className="column">
          <BasicInput
            label="Unità di misura"
            value={articoloSelezionato && articoloSelezionato.unitaDiMisuraVendita || ""}
            readOnly={true}
          />
          {/* <BasicDropdown label={"Unità di misura"} defaultValue="Seleziona UM" values={um.map(x => x.descrizione)} onSelection={(e) => { handleUMSelection(e) }} /> */}
        </div>
      </div>

      <div className="field is-right">
        <p className="control">
          <button type="button" onClick={_ => submitForm("salva")} className="button is-info has-margin-right-10">
            Salva e continua
          </button>
          <button type="button" onClick={_ => submitForm("salva-continua")} className="button is-success" >
            Salva
          </button>
        </p>
      </div>
    </form>
  );
};
