import * as _ from "ramda";
import { Reducer } from "redux";
import { InterventoModelNew, ColturaModel, InterventoModel } from "../Containers/Azienda/Coltura/Model";
import { DeleteAction } from "../Utilities/Redux/Types";
import produce from "immer";

export interface State {
  colturaSelectedRow: ColturaModel | null; // ! Aggiunto
  interventoSelectedRow: InterventoModel | null;
}
export const defaultState: State = { interventoSelectedRow: null, colturaSelectedRow: null }; // ! Aggiunto

export type Action = {
  type: 'INTERVENTO_EDIT'
  intervento: InterventoModel
  // colturaRow : ColturaModel
} | DeleteAction<'DELETE_ARTICOLO_IN'>
  | DeleteAction<'DELETE_ARTICOLO_OUT'>
  | DeleteAction<'DELETE_MACCHINA'>
  | DeleteAction<'DELETE_PERSONA'>
  | DeleteAction<'DELETE_RILEVAZIONE'>;

function transformColtura(coltura : ColturaModel, intervento: InterventoModel): ColturaModel {
  return { ...coltura, interventi: coltura.interventi.map(i => i.idIntervento === intervento.idIntervento ? intervento : i) }
}
function alignIntervento(state : State) : State {
  return {
    ...state,
    colturaSelectedRow: 
      state.colturaSelectedRow === null ? null :
      state.interventoSelectedRow === null ? state.colturaSelectedRow : 
        transformColtura(state.colturaSelectedRow, state.interventoSelectedRow)
  }
}

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) return defaultState;


  /* TODO(C): 
      da rivedere. 
      Dal momento che ho visto che al dispatch dell'evento 'INTERVENTO_EDIT' si perdeva la coltura selezionata, 
      ho deciso di passarla avanti in modo tale da averla disponibile negli articoli in ingresso. 
      Ovviamente nel file 'Azienda.tsx' ci saranno due elementi che saranno resi visibili data la presenza della 'colturaSelectedRow', 
      per questo motivo alla riga 279 ho aggiunto la riga 
      `{colturaSelectedRow && (!interventoSelectedRow)` 
      per indicare che vogliamo spostarci nella pagina modifica dell'intervento 
  */
  switch (payload.type) {
    case 'INTERVENTO_EDIT':
      // return {
      //   interventoSelectedRow : payload.intervento
      // }
      if (state.colturaSelectedRow !== null) {
        const newState = {
          ...state,
          colturaSelectedRow: state.colturaSelectedRow,
          interventoSelectedRow: payload.intervento
        };
        return newState;
      } else return state;

    // i delete sono tutti uguali, salvo per le chiavi da usare e i campi da sostituire
    // sono tutti `produce(state, ({ intervento }) => /*sostituisci campo con filter del campo*/)
    case 'DELETE_ARTICOLO_IN':
      if(state.interventoSelectedRow){
        
        return alignIntervento({
          ...state, 
          interventoSelectedRow: { 
            ...state.interventoSelectedRow, 
            articoliIngresso: state.interventoSelectedRow.articoliIngresso.filter(x => x.idArticoloIngressoIntervento !== payload.id)
          }
        })
      }
    case 'DELETE_ARTICOLO_OUT':
      if(state.interventoSelectedRow){
        return alignIntervento({
          ...state, 
          interventoSelectedRow: { 
            ...state.interventoSelectedRow, 
            articoliUscita: state.interventoSelectedRow.articoliUscita.filter(x => x.idArticoloUscitaIntervento !== payload.id)
          }
        })
      }
    case 'DELETE_MACCHINA':
      if(state.interventoSelectedRow){
        return alignIntervento({
          ...state, 
          interventoSelectedRow: { 
            ...state.interventoSelectedRow, 
            macchine: state.interventoSelectedRow.macchine.filter(x => x.idMacchina !== payload.id)
          }
        })
      }
    case 'DELETE_PERSONA':
      if(state.interventoSelectedRow){
        const newstate = {
          ...state, 
          interventoSelectedRow: { 
            ...state.interventoSelectedRow, 
            persone: state.interventoSelectedRow.persone.filter(x => x.idPersonaIntervento !== payload.id)
          }
        }
        return alignIntervento(newstate)
      }
    case 'DELETE_RILEVAZIONE':
      if(state.interventoSelectedRow){
        return alignIntervento({
          ...state, 
          interventoSelectedRow: { 
            ...state.interventoSelectedRow, 
            rilevazioni: state.interventoSelectedRow.rilevazioni.filter(x => x.idRilevazione !== payload.id)
          }
        })
      }
    default: return state;

  }
};
