import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { produce } from "immer";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { BasicDropdown, BasicInput, BasicTextArea } from "../../../Utilities/SharedComponent";
import CustomTable, { TableSubject } from "../../CustomTable/CustomTable";
import { ColturaModel, StatoIntervento } from "./Model";
import { useDispatch, useSelector } from "../../../Utilities/SharedHooks/State";
import { UNDO_ACTION, UNDO, AllActions } from "../../../Reducers/Root";
import DateInfo from "../../../Components/DatesInfo/DateInfo";
import Collapsible from "react-collapsible";
import EditButtonGroup from "../../EditButtonGroup/EditButtonGroup";
import Modali, { useModali } from 'modali';
import { useToasts } from "react-toast-notifications";
import { navigateBack } from "../../../Reducers/Route";


const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      La coltura verrà eliminata permanentemente.
    </Modali.Modal>
  );
};

const Coltura: React.FC<{
  coltura: ColturaModel
  onUpdate: (value: TableSubject) => void
  onCreate: () => void
  onDelete: (value: TableSubject) => void,
  handleDeleteColtura : (intervento : ColturaModel) => void
  handlePostColtura : (intervento : ColturaModel) => void
}> = ({ coltura, onUpdate, onCreate, onDelete, handleDeleteColtura, handlePostColtura }) => {
  const ColturaVuota = _.empty(coltura);
  const [localColtura, setLocalColtura] = useState<ColturaModel>(ColturaVuota);
  const { addToast } = useToasts();

  const dispatch = useDispatch<AllActions>();

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => handleDeleteColtura(localColtura)}
      />
    ]
  });

  useEffect(() => {
    // Popolo il form con i dati recuperati dalla coltura selezionata
    setLocalColtura(coltura);
    const colturaToInterventi = (xs : ColturaModel[]) => {
      const co = xs.find(x => x.idColtura === coltura.idColtura);
      return { colturaSelectedRow: co };
    }
    // dispatch({ type: 'PUSH_RESTORER', payload: colturaToInterventi})
  }, [coltura]);

  const handleTexboxChange = (fn: (v: ColturaModel) => void) => {
    setLocalColtura(produce(localColtura, fn));
  };

  const handleUpdateForm = () => {
    handlePostColtura(localColtura)
    addToast('Coltura aggiornata correttamente', { appearance: 'success', autoDismiss: true })
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handlePostColtura(localColtura);
  };

  return (
    <>
      <span>
        <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" /><span className="subtitle has-text-grey">Coltura {coltura.descrizione}</span>
      </span>
      <form onSubmit={handleFormSubmit}>

        <br />
        <EditButtonGroup onEditConfirm={handleUpdateForm} onDelete={ ()=> { toggleDeleteModal()} } />
        <div className="field columns">
          <div className="column is-bordered-column">            
            <BasicInput label="Descrizione" value={localColtura.descrizione || ""} readOnly={false}
              onChange={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.descrizione = e.target.value; });
              }
              } />
            <BasicDropdown label={"Stato"} defaultValue={localColtura.stato} values={["Programmato", "InCorso", "Concluso"]}
              onSelection={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.stato = e.target.value as StatoIntervento });
              }
              } />
            <BasicInput label="Lotto" value={localColtura.lotto || ""} readOnly={false}
              onChange={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.lotto = e.target.value; });
              }
              } />
            <BasicInput label="Superficie" value={localColtura.superficieImpegnata || ""} readOnly={false}
              onChange={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.superficieImpegnata = Number(e.target.value); });
              }
              } />
          </div>

          <div className="column is-bordered-column">
            <BasicTextArea label="Annotazioni" value={localColtura.note} readOnly={false} onChange={(e) => {
              handleTexboxChange((p: ColturaModel) => { p.note = e.target.value; });
            }} />
          </div>
        </div>

        <div className="columns">
          <div className="column is-bordered-column">
            <DateInfo title="Data prevista"
              from={localColtura.dataInizioPrevisto}
              to={localColtura.dataFinePrevista}
              onFromChanged={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.dataInizioPrevisto = e.target.value; });
              }
              }
              onToChanged={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.dataFinePrevista = e.target.value; });
              }
              }>
            </DateInfo>

            <DateInfo title="Data reale"
              from={localColtura.dataInizioReale}
              to={localColtura.dataFineReale}
              onFromChanged={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.dataInizioReale = e.target.value; });
              }}
              onToChanged={(e) => {
                handleTexboxChange((p: ColturaModel) => { p.dataFineReale = e.target.value; });
              }}>
            </DateInfo>

          </div>

        </div>

        <CustomTable
          title="Interventi"
          headers={[{ key: "stato", value: "status" }, "dataInizioReale", "dataFineReale", "tipoIntervento.descrizione", { key: "costoIntervento", value: "currency" }, "annotazioniPubbliche"]}
          rows={coltura.interventi}
          onCreate={onCreate}
          onUpdate={onUpdate}
          // onDelete={onDelete} 
        />

      </form>
      <ModalDelete modal={deleteModal} />
    </>
  );

};

export default Coltura;
