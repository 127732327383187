import React from "react";
import Azienda from "../../Containers/Azienda/Azienda";
import Navbar from "../../Containers/Navbar/Navbar";

const Main: React.FC = () => {
  return (
    <div id="outer-container">
      <Navbar outerId="outer-container" pageWrap="page-wrap" breadcrumbs={["Quaderno di campagna", "La mia azienda"]} />
      <div id="page-wrap">
        <Azienda />
      </div>
    </div>
  );
};

export default Main;
