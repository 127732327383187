import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { State } from "react-burger-menu";
import logo from "../../Assets/logo-zeuslab.png";
import Burger from "../../Components/Sidebar/Burger";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import "./Navbar.css";

const Navbar: React.FC<{
  outerId: string;
  pageWrap: string;
  breadcrumbs: string[];
}> = ({ outerId, pageWrap, breadcrumbs }) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [localBreadcrumbs, setLocalBreadcrumbs] = useState("");

  const onStateChange = (burgerState: State) => {
    setMenuOpenState(burgerState.isOpen);
  };

  useEffect(() => {
    generateBreadcumbs();
  });

  const generateBreadcumbs = () => {
    const _breadcrumbs = _.join(" → ", breadcrumbs);
    setLocalBreadcrumbs(_breadcrumbs);
  };

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation" id="main-navbar" >
        <Burger outerContainerId={outerId} pageWrapId={pageWrap} isOpen={menuOpenState} onStateChange={onStateChange} >
          <Sidebar />
        </Burger>
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <span onClick={() => { setMenuOpenState(!menuOpenState); }} className="navbar-item is-clickable">
              <FontAwesomeIcon icon={faBars} size="2x" />
            </span>
          </div>

          <div className="navbar-end">
            <figure className="image is-180x180">
              <img src={logo} alt="logo-zeuslab " />
            </figure>
          </div>
        </div>
      </nav>

      {/* <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="subtitle has-text-grey has-text-weight-light">
              {localBreadcrumbs || "Quaderno di campagna"}
            </h1>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Navbar;
