



export class RestartablePromise<T> {
  _current: Promise<T>;
  constructor(private init: () => Promise<T>) {
    this._current = this.init();
  }
  static from<T>(p: () => Promise<T>) {
    return new RestartablePromise(p);
  }

  get promise() {
    return this._current.then();
  }

  //N: onrejected takes any as it complies to the Promise Spec
  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    // tslint:disable-next-line: no-any
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null):
    RestartablePromise<TResult1 | TResult2> {
    return new RestartablePromise(
      () => this._current.then(onfulfilled, onrejected)
    );
  }

  catch<TResult = never>(
    // N: in accordance with the Promise spec 
    // tslint:disable-next-line: no-any
    onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null):
    RestartablePromise<T | TResult> {
    return new RestartablePromise(() => this._current.catch(onrejected));
  }
  restart() {
    this._current = this.init();
  }

}

export const restartable = RestartablePromise.from;
