import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { UnitaProduttiva } from '../../Azienda/Model'
import { CampoLibero } from '../../Azienda/Coltura/Model'

const CategorieRapida: React.FC<{
  unitaProduttive: UnitaProduttiva[]
  categoriaSelezionata: (c: CampoLibero) => void
  categoriaModificata?: CampoLibero
  categorie: CampoLibero[]
  reset: boolean
}> = ({ unitaProduttive, categoriaSelezionata, categoriaModificata, categorie, reset }) => {

  useEffect(() => {
    setCategoriaAttiva("")
  }, [reset])

  useEffect(() => {
    if (categoriaModificata) {
      setCategoriaAttiva(categoriaModificata.valore)
    }
  }, [categoriaModificata])

  // const [categorie, setCategorie] = useState<CampoLibero[]>([])

  // useEffect(() => {
  //   const cod = unitaProduttive.find(x => x.idUnitaProduttiva === ID_UNITA)?.codiceUnitaProduttiva
  //   if (cod) {
  //     promiseArticoli(cod)
  //       .then((art: Articolo[]) => {
  //         const cal = flatMap(art, x => x.campiLiberi || [])
  //         setCategorie(cal.filter((v, i, a) => a.findIndex(x => x.valore === v.valore) === i))
  //       })
  //   }
  // }, [unitaProduttive])

  const [categoriaAttiva, setCategoriaAttiva] = useState("")
  const handleClickCategoria = (categoria: CampoLibero) => {
    setCategoriaAttiva(categoria.valore ?? "")
    categoriaSelezionata(categoria)
  }

  const tileClasses = (p: CampoLibero) => 
    p.valore === categoriaAttiva ? 'tile is-child notification is-success' : 'tile is-child notification'

  return (
    <>
      <h1 className="title is-5 has-text-centered">
        <FontAwesomeIcon icon={faTags} size="1x" className="has-10-margin-right" />
        Categorie
      </h1>
      <div className="is-scrollable">
        {categorie.map((categoria, i) => (
          <div className="tile is-ancestor is-marginless fade-from-bottom" key={i} onClick={_ => handleClickCategoria(categoria) }>
            <div className="tile ">
              <div className="tile is-parent is-few-padding">
                <article className={tileClasses(categoria)}>
                  <p className="is-inline">{categoria.valore}</p>
                </article>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default CategorieRapida
