import * as _ from "ramda";
import React, { useState, useEffect } from "react";
import { BasicInput, deleteObject, fetchPhoto, postPhoto } from "../../../Utilities/SharedComponent";
import CustomTable, { TableSubject } from "../../CustomTable/CustomTable";
import { RepartoModel } from "../Model";
import { ColturaModel } from "../Coltura/Model";
import Modali, { useModali } from 'modali';
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "../../../Utilities/SharedHooks/State";
import { AllActions } from "../../../Reducers/Root";
import produce from "immer";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import { navigateBack } from "../../../Reducers/Route";

const Dashboard: React.FC<{
  reparto: RepartoModel,
  colture: ColturaModel[],
  onUpdate: (value: TableSubject) => void
  onCreate: () => void
  onDelete: (value: TableSubject) => void,
}> = ({ reparto, colture, onUpdate, onCreate, onDelete }) => {

  return (
    <div className="has-animation">
      <h1 className="has-animation title">{reparto.nomeReparto}</h1>
      <h1 className="subtitle">Superficie utilizzabile: {reparto.estensione} mq</h1>
      <br />
      <hr className="dropdown-divider" />
      <br />

      <CustomTable
        headers={[
          { key: "stato", value: "status" },
          "dataInizioPrevisto",
          "dataFinePrevista",
          "lotto",
          "descrizione",
          { key: "superficieImpegnata", value: "area" }
        ]}
        rows={colture}
        title="Piano colturale"
        footer={false}
        onCreate={onCreate}
        onUpdate={onUpdate}
        // onDelete={onDelete}
      />
    </div>
  );
};

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      Il reparto verrà eliminato permanentemente.
    </Modali.Modal>
  );
};

function setField<T, K extends keyof T>(fn: (React.Dispatch<React.SetStateAction<T>>), obj: T, key: K, value: any) {
  fn(produce(obj, (x: T) => { x[key] = value }))
};

const Anagrafica: React.FC<{ reparto: RepartoModel, onCreate: () => void }> = ({ reparto, onCreate }) => {
  const [photo, setPhoto] = useState();
  const [newPhoto, setNewPhoto] = useState();
  const { addToast } = useToasts();
  const dispatch = useDispatch<AllActions>();
  const idUnitaProduttiva = useSelector(x => x.present.idUnitaProduttiva)
  const [repartoModifcato, setRepartoModificato] = useState(reparto)
  const [postReparto, repartoPostato] = usePost<RepartoModel>(`reparti/${idUnitaProduttiva}`);

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deleteReparto()}
      />,
    ],
  });

  useEffect(() => {
    setRepartoModificato(reparto)
  }, [reparto])

  const deleteReparto = () => {
    deleteObject(`reparti/${reparto.idReparto}`)
       .then(_ => addToast('Reparto eliminato correttamente', { appearance: 'success', autoDismiss: true }))
    toggleDeleteModal();
    dispatch(navigateBack())
    onCreate()
  }

  useEffect(() => {
    fetchPhoto(`foto/reparto/${reparto.idReparto}`)
      .then(res => {
        if (!(res === "Immagine non trovata")) {
          setPhoto(res)
        } else { setPhoto(undefined) }
      })
      .catch(_ => setPhoto(undefined))
  }, [reparto])

  const postProfilePhoto = (idReparto: any) => {
    let formData = new FormData();
 
    if (newPhoto && idReparto) {
      formData.append('image', newPhoto, newPhoto.name);

      postPhoto(`foto/reparto/${idReparto}`, formData)
    }
  }
  
  const onChangePhoto = (e : any) => {
    e.preventDefault();
    const file = e.target.files[0]
    setNewPhoto(file)    
  }

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const _nuovoReparto: RepartoModel = {
      idReparto: reparto.idReparto 
    , codiceReparto: reparto.codiceReparto
    , nomeReparto: repartoModifcato.nomeReparto
    , estensione: repartoModifcato.estensione,
    };

    postReparto(_nuovoReparto)
      .then(idReparto => {
        postProfilePhoto(idReparto)
        addToast('Reparto salvato correttamente', { appearance: 'success', autoDismiss: true })
        onCreate()
      })
  }

  return (
    <form onSubmit={submit}>
      <div className="columns">
        <div className="column is-half is-bordered-column">
          <div className="title-area">
            <span className="title is-5 has-grey-color">Anagrafica</span>
          </div>
          {photo ? (
            <figure className="image is-200x200 figure-profile-picture">
              <img className="is-rounded" src={photo} alt="profile-picture" />
            </figure>
          ) : (<small className="no-photo-message">Nessuna foto inserita</small>)}

          <div className="file file-input-upload figure-profile-picture-input">
            <label className="file-label">
              <input className="file-input" type="file" name="resume" accept="image/*" onChange={onChangePhoto} />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  Immagine
                  </span>
              </span>
              <span className="file-name">
                {photo && photo.name || "Selezionare un'immagine"}
              </span>
            </label>
          </div>
          
          <BasicInput 
            label="Nome" 
            value={repartoModifcato.nomeReparto || ""} 
            readOnly={false} 
            onChange={e => { setField(setRepartoModificato, repartoModifcato, "nomeReparto", e.target.value) }}  
          />

          <BasicInput 
            label="Sup. utilizzabile" 
            value={repartoModifcato.estensione || ""} 
            readOnly={false} 
            onChange={e => { setField(setRepartoModificato, repartoModifcato, "estensione", e.target.value) }}  
          />
          
          <BasicInput 
            label="Codice" 
            value={reparto.codiceReparto || ""} 
            readOnly={true} 
            onChange={() => { }} 
          />

          <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Modifica
          </button>
          </p>
          </div>
          <div className="field is-right">
            <p className="control">
              <button type="button" onClick={ () => toggleDeleteModal() } className="button is-danger" >
                Elimina
            </button>
            </p>
          </div>
          <ModalDelete modal={deleteModal} />
        </div>
      </div>
    </form>
  );
};

const Reparto: React.FC<{
  colture: ColturaModel[],
  reparto: RepartoModel
  onUpdate: (value: TableSubject) => void
  onCreate: () => void
  onDelete: (value: TableSubject) => void
  onRefresh: () => void
}> = ({ colture, reparto, onUpdate, onCreate, onDelete, onRefresh }) => {
  // False indica che la prima tab è attiva e aperta
  const [activeTab, setActiveTab] = useState(true);
  const dispatch = useDispatch<AllActions>();
  // useEffect(() => {
  //   dispatch({type: 'PUSH_RESTORER'})
  // }, [])
  return (
    <>
      <div className="tabs is-boxed">
        <ul>
          <li className={activeTab ? "is-active" : ""}><a style={{ outline: 'none'}} href="#" onClick={() => { setActiveTab(true); }}>Dashboard</a></li>
          <li className={!activeTab ? "is-active" : ""}><a style={{ outline: 'none'}} href="#" onClick={() => { setActiveTab(false); }}>Anagrafica</a></li>
        </ul>
      </div>

      {activeTab && (
        <div className="tab-panel">
          <Dashboard reparto={reparto} colture={colture} onUpdate={onUpdate} onCreate={onCreate} onDelete={onDelete} />
        </div>
      )}

      {_.not(activeTab) && (
        <div className="tab-panel">
          <Anagrafica reparto={reparto} onCreate={onRefresh} />
        </div>
      )}
    </>
  );
};

export default Reparto;
