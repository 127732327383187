import produce from "immer";
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { BasicDropdown, BasicInput, fetchObjects, deleteObjectAtId, ErrorModal, sortByCaseInsensitive, ID_AZIENDA, PUNTO_AZIENDA, ID_UNITA } from "../../Utilities/SharedComponent";
import { TipoRilevazione, Articolo, UnitaMisura } from "../Azienda/Coltura/Model";
import Navbar from "../Navbar/Navbar";
import CustomTable, { TableSubject } from "../CustomTable/CustomTable";
import Modali, { useModali } from "modali";
import { restartable } from "../../Utilities/Async";
import { usePost } from "../../Utilities/SharedHooks/Sync";

const promiseTipiRilevazione = restartable(() => fetchObjects(`tiporilevazione/${ID_UNITA}`));
const fetchArticoli = fetchObjects(`${ID_AZIENDA}/${PUNTO_AZIENDA}/articoli`);
const fetchUm = fetchObjects(`${ID_AZIENDA}/unitamisura`)

const ModalRilevazioniArticoloNuovo: React.FC<{
  modal: Modali.ModalHook;
  onSubmit: () => void
}> = ({ modal, onSubmit }) => {
  return (
    <Modali.Modal {...modal}>
      <RilevazioniArticoloNuovo onSubmit={onSubmit} />
    </Modali.Modal>
  );
};

const ModalRilevazioniArticoloAnagrafica: React.FC<{
  modal: Modali.ModalHook
  tipoRilevazione: TipoRilevazione | undefined
}> = ({ modal, tipoRilevazione }) => {
  return (
    <Modali.Modal {...modal}>
      {tipoRilevazione && (
        <RilevazioniArticoloAnagrafica tipoRilevazione={tipoRilevazione} />
      )}
    </Modali.Modal>
  );
};

const ModalDelete: React.FC<{
  modal: Modali.ModalHook
}> = ({ modal }) => {
  return (
    <Modali.Modal {...modal}>
      La rilevazione selezionata verrà eliminata permanentemente.
    </Modali.Modal>
  );
};

const RilevazioniArticolo: React.FC = () => {
  const [tipiRilevazioni, setTipiRilevazioni] = useState<TipoRilevazione[] | undefined>();
  const [localTipiRilevazioni, setLocalTipiRilevazioni] = useState<TipoRilevazione[] | undefined>();
  const [tipoRilevazioneAttivo, setTipoRilevazioneAttivo] = useState<TipoRilevazione>({} as TipoRilevazione);
  const [toDelete, setToDelete] = useState({} as TipoRilevazione)
  const [error, setError] = useState("");

  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: 'Sei sicuro?',
    buttons: [
      <Modali.Button
        label="Annulla"
        isStyleCancel
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        label="Elimina"
        isStyleDestructive
        onClick={() => deleteRilevazioneArticolo()}
      />,
    ],
  });

  const [nuovoTipoRilevazioneArticoloModal, setNuovoTipoRilevazioneArticoloModal] = useModali({
    title: "Nuovo tipo rilevazione (articolo)",
    animated: true,
    closeButton: true,
    onHide: () => { promiseTipiRilevazione.restart(); }
  });

  const [editTipoRilevazioneArticoloModal, setEditTipoRilevazioneArticoloModal] = useModali({
    title: "Modifica tipo rilevazione (articolo)",
    animated: true,
    closeButton: true,
    onHide: () => { promiseTipiRilevazione.restart(); }
  });

  useEffect(() => {
    // Carico le tipologie di rilevazioni per intervento configurate dall'utente
    promiseTipiRilevazione
      .then((data) => { setTipiRilevazioni(data); })
      .catch((err) => { setError(err); });
  }, [promiseTipiRilevazione._current]);

  useEffect(() => {
    setLocalTipiRilevazioni(sortByCaseInsensitive<TipoRilevazione>("descrizione", tipiRilevazioni?.filter(x => x.tag !== "Intervento")));
  }, [tipiRilevazioni]);

  const handleDeleteRilevazione = (value: TableSubject) => {
    const tipoRilevazione = value as TipoRilevazione;
    if (tipoRilevazione && tipoRilevazione.idTipoRilevazione) {
      setToDelete(tipoRilevazione)
    }
  };

  const deleteRilevazioneArticolo = () => {
    const tmpTipiRilevazioni = tipiRilevazioni?.filter(x => x.idTipoRilevazione !== toDelete.idTipoRilevazione);
    setTipiRilevazioni(tmpTipiRilevazioni);
    deleteObjectAtId("tiporilevazione", toDelete.idTipoRilevazione);
    toggleDeleteModal()
  }

  const updateRilevazione = (e: TableSubject) => {
    const tmp = e as TipoRilevazione;
    if (tmp) {
      setTipoRilevazioneAttivo(tmp);
      setEditTipoRilevazioneArticoloModal();
    }
  };

  const Body = (
    <>
      <section className="section">
        <div className="columns">
          <div className="column isfull custom-card-light is-cm-fullheight">
            <CustomTable
              classes="tableRilevazioniArticolo"
              headers={["descrizione", "tipoValore", "obbligatoria"]}
              rows={localTipiRilevazioni || []}
              title="Tabella rilevazioni per articolo"
              onCreate={setNuovoTipoRilevazioneArticoloModal}
              onUpdate={updateRilevazione}
              onDelete={ e => { handleDeleteRilevazione(e); toggleDeleteModal()} }
            />
          </div>
          <ModalRilevazioniArticoloNuovo modal={nuovoTipoRilevazioneArticoloModal} onSubmit={() => { promiseTipiRilevazione.restart(); }} />
          <ModalRilevazioniArticoloAnagrafica modal={editTipoRilevazioneArticoloModal} tipoRilevazione={tipoRilevazioneAttivo} />

        </div>
      </section>
      <ModalDelete modal={deleteModal} />
    </>
  );

  return (
    <div id="outer-container">
      <Navbar outerId="outer-container" pageWrap="page-wrap" breadcrumbs={["Quaderno di campagna", "Rilevazioni per articolo"]} />
      <div id="page-wrap">
        {Body}
      </div>
    </div>
  );
};

const fetchArticolo = (codice: string) => fetchObjects(`${ID_AZIENDA}/${PUNTO_AZIENDA}/articoli?codice=${codice}`);

const RilevazioniArticoloAnagrafica: React.FC<{ tipoRilevazione: TipoRilevazione }> = ({ tipoRilevazione }) => {
  const idArticolo: string = _.path(['articolo'], tipoRilevazione.tag) ?? "";
  const [art, setArt] = useState<Articolo[]>();
  const [um, setUm] = useState<UnitaMisura[]>([]);
  const [tipoRilevazioneModificato, setTipoRilevazioneModificato] = useState(tipoRilevazione)
  const [postTipoRilevazione, tipoRilevazionePostato] = usePost<TipoRilevazione>(`tiporilevazione/${ID_UNITA}`);

  useEffect(() => {
    fetchUm
      .then(res => setUm(res))
  }, [])

  useEffect(() => {
    fetchArticolo(idArticolo)
      .then(res => setArt(res))
  }, [])

  const handleTexboxChange = (fn: (v: TipoRilevazione) => void) => {
    setTipoRilevazioneModificato(
      produce(tipoRilevazioneModificato, fn)
    );
  };

  const handleTipoValoreSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTipoRilevazioneModificato(
      produce(tipoRilevazioneModificato, v => {
        // N: Safe cause it's a dropdown
        // tslint:disable-next-line: no-any
        v.tipoValore = e.target.value as any;
      })
    );
  };

  const handleUMSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTipoRilevazioneModificato(produce(tipoRilevazioneModificato, v => {
      v.unitaDiMisura = e.target.value as string
    }))
  }

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    const _nuovoTipoRilevazioneIntervento: TipoRilevazione = {
      idTipoRilevazione: tipoRilevazione.idTipoRilevazione,
      // idAzienda: ID_AZIENDA ?? "",
      descrizione: tipoRilevazioneModificato.descrizione,
      tipoValore: tipoRilevazioneModificato.tipoValore,
      tag: { articolo: idArticolo },
      obbligatoria: tipoRilevazioneModificato.obbligatoria,
      unitaDiMisura: tipoRilevazioneModificato.unitaDiMisura
    };
    postTipoRilevazione(_nuovoTipoRilevazioneIntervento);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        {art && (
          <>
            <BasicInput label="Descrizione" value={tipoRilevazioneModificato.descrizione} readOnly={false} onChange={e => { handleTexboxChange((p: TipoRilevazione) => { p.descrizione = e.target.value; }) }} />
            <BasicDropdown label={"Valore"} defaultValue={tipoRilevazioneModificato.tipoValore} values={["string", "dataOra", "boolean", "decimal"]} onSelection={handleTipoValoreSelection} />
            {(tipoRilevazioneModificato.tipoValore === "Decimal" || tipoRilevazioneModificato.tipoValore === "DataOra") && (
              <BasicDropdown label={"Unità di misura"} defaultValue={tipoRilevazioneModificato.unitaDiMisura} values={um.map(x => x.descrizione)} onSelection={handleUMSelection} />
            )}
            <BasicInput label="Articolo" value={_.head(art)?.descrizione ?? ""} readOnly={true} onChange={() => { }} />
            <div className="field-label is-flex">
              <label className="label has-10-padding has-text-grey has-text-weight-normal">Obbligatorio</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    id="c1"
                    name="c-group"
                    checked={tipoRilevazioneModificato.obbligatoria}
                    onChange={e => {
                      handleTexboxChange((p: TipoRilevazione) => {
                        p.obbligatoria = e.target.checked;
                      });
                    }} />
                </div>
              </div>
            </div>

            <div className="field is-right">
              <p className="control">
                <button type="submit" className="button is-success">
                  Applica
            </button>
              </p>
            </div>
          </>)}
      </form>
    </>
  );
};

type LightTipoRilevazione
  = Omit<TipoRilevazione, "idTipoRilevazione">;

const RilevazioniArticoloNuovo: React.FC<{ onSubmit?: (_: LightTipoRilevazione) => void }> = ({ onSubmit }) => {
  const initialStateTipoRilevazioneArticolo: LightTipoRilevazione = {
    // idAzienda: ID_AZIENDA ?? "",
    descrizione: "",
    tag: { articolo: "" },
    tipoValore: "string",
    obbligatoria: false,
    unitaDiMisura: ""
  };
  const defaultTipoRilevazioneArticolo: LightTipoRilevazione = {
    // idAzienda: ID_AZIENDA ?? "", 
    descrizione: "", 
    tag: { articolo: "cda61793-cd82-4141-971c-b37921863968" }, 
    tipoValore: "string", 
    obbligatoria: false,
    unitaDiMisura: ""
  };

  const [um, setUm] = useState<UnitaMisura[]>([])
  const [articoli, setArticoli] = useState<Articolo[]>([]);
  const [nuovoTipoRilevazioneArticolo, setNuovoTipoRilevazioneArticolo] = useState<LightTipoRilevazione>(defaultTipoRilevazioneArticolo);
  const [postTipoRilevazione, tipoRilevazionePostato] = usePost<LightTipoRilevazione>(`tiporilevazione/${ID_UNITA}`);
  const [articoloSelezionato, setArticoloSelezionato] = useState<Articolo>();

  useEffect(() => {
    fetchUm
      .then(res => setUm(res))
  }, [])

  useEffect(() => {
    fetchArticoli
      .then((data) => { setArticoli(data); })
  }, []);

  const handleTexboxChange = (fn: (v: TipoRilevazione) => void) => {
    setNuovoTipoRilevazioneArticolo(
      produce(nuovoTipoRilevazioneArticolo, fn)
    );
  };

  const handleTipoValoreSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNuovoTipoRilevazioneArticolo(
      produce(nuovoTipoRilevazioneArticolo, v => {
        // N: Safe cause it's a dropdown
        // tslint:disable-next-line: no-any
        v.tipoValore = e.target.value as any;
      })
    );
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (articoloSelezionato) {
      const _nuovoTipoRilevazioneArticolo: LightTipoRilevazione = {
        // idAzienda: ID_AZIENDA ?? "", 
        descrizione: nuovoTipoRilevazioneArticolo.descrizione, 
        tag: { articolo: articoloSelezionato.codiceInternoArticolo }, 
        tipoValore: nuovoTipoRilevazioneArticolo.tipoValore, 
        obbligatoria: nuovoTipoRilevazioneArticolo.obbligatoria,
        unitaDiMisura: nuovoTipoRilevazioneArticolo.unitaDiMisura
      };

      setNuovoTipoRilevazioneArticolo(_nuovoTipoRilevazioneArticolo);
      postTipoRilevazione(_nuovoTipoRilevazioneArticolo);
      setNuovoTipoRilevazioneArticolo(initialStateTipoRilevazioneArticolo);
      
      onSubmit?.(_nuovoTipoRilevazioneArticolo);
    }
  };

  const handleUMSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNuovoTipoRilevazioneArticolo(produce(nuovoTipoRilevazioneArticolo, v => {
      v.unitaDiMisura = e.target.value as string
    }))
  }

  const handleArticoloSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const art =
      _.find(
        _.propEq("descrizione", e.target.value)
        , articoli);
    if (art) {
      setArticoloSelezionato(art);
    }
  };

  return (
    <>
      <ErrorModal prom={tipoRilevazionePostato} />
      <form id='test' onSubmit={handleFormSubmit}>
        <BasicInput label="Descrizione" value={nuovoTipoRilevazioneArticolo.descrizione} readOnly={false} onChange={e => { handleTexboxChange((p: TipoRilevazione) => { p.descrizione = e.target.value; }); }} />

        <BasicDropdown label={"Articolo"} defaultValue="Seleziona articolo" values={articoli.map(x => x.descrizione)} onSelection={(e) => { handleArticoloSelection(e); }} />

        <BasicDropdown label={"Valore"} defaultValue="Seleziona tipo valore" values={["string", "dataOra", "boolean", "decimal"]} onSelection={handleTipoValoreSelection} />

        {(nuovoTipoRilevazioneArticolo.tipoValore === "decimal" || nuovoTipoRilevazioneArticolo.tipoValore === "dataOra") && (
          <BasicDropdown label={"Unità di misura"} defaultValue="Seleziona UM" values={um.map(x => x.descrizione)} onSelection={handleUMSelection} />
        )}

        <div className="field-label is-flex">
          <label className="label has-10-padding has-text-grey has-text-weight-normal">Obbligatorio</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="checkbox-input"
                checked={nuovoTipoRilevazioneArticolo.obbligatoria}
                type="checkbox"
                id="c1"
                name="c-group"
                onChange={(e) => { handleTexboxChange((p: TipoRilevazione) => { p.obbligatoria = e.target.checked; }); }}
              />
            </div>
          </div>
        </div>

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Salva e aggiungi
          </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default RilevazioniArticolo;
