import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { Articolo, CampoLibero } from '../../Azienda/Coltura/Model'
import * as _ from 'ramda'

const ArticoliRapida: React.FC<{
  articoli: Articolo[]
  articoloSelezionato: (a: Articolo) => void
  articoloModificato?: Articolo
  reset: boolean
  categoriaSelezionata: CampoLibero | undefined
}> = ({ articoli, articoloSelezionato, articoloModificato, reset, categoriaSelezionata }) => {
  
  useEffect(() => {
    setArticoloAttivo("")
  }, [reset])

  useEffect(() => {
    if (!_.isNil(articoloModificato)) {
      setArticoloAttivo(articoloModificato.codiceInternoArticolo)
      articoloSelezionato(articoloModificato)
    }
  }, [articoloModificato])
  
  const [articoloAttivo, setArticoloAttivo] = useState("")
  const handleClickArticolo = (articolo: Articolo) => {
    setArticoloAttivo(articolo.codiceInternoArticolo ?? "")
    articoloSelezionato(articolo)
  }

  const tileClasses = (i: Articolo) => 
    i.codiceInternoArticolo === articoloAttivo ? 'tile is-child notification is-success' : 'tile is-child notification'

  return (
    <>
      <h1 className="title is-5 has-text-centered">
        <FontAwesomeIcon icon={faBox} size="1x" className="has-10-margin-right" />
        Articoli
      </h1>
      <div className="is-scrollable is-lowest-layer">
        {categoriaSelezionata ? articoli.map((articolo, i) => (
          <div className="tile is-ancestor is-marginless fade-from-bottom" key={i} onClick={_ => handleClickArticolo(articolo)}>
            <div className="tile ">
              <div className="tile is-parent is-few-padding">
                <article className={tileClasses(articolo)}>
                <p className="is-inline has-text-weight-bold">{articolo.codiceArticolo}</p>
                <br/>
                <p className="is-inline">{articolo.descrizione}</p>
                </article>
              </div>
            </div>
          </div>
        )) : (
          <h1 className="has-text-centered">Selezionare una categoria</h1>
        )}
      </div>
    </>
  )
}

export default ArticoliRapida