import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner: React.FC = () => {
  const spinner = (
    <svg className="spinner" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" ></circle>
    </svg>
  );

  return spinner;
};

export default LoadingSpinner;
