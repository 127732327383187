import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import * as uuid from "uuid"
import * as _ from "ramda";
import React, { useEffect, useState } from "react";
import { BasicDropdown, BasicTextArea, BasicCheckbox, ErrorModal, fetchObjects, ID_AZIENDA, ID_UNITA } from "../../../Utilities/SharedComponent";
import {
  InterventoModelNew,
  StatoIntervento,
  TipoIntervento,
  ColturaModel,
  ArticoloUscitaIntervento,
  MacchinaIntervento,
  PersonaIntervento,
  Rilevazione,
  initialStateTipoIntervento,
  initialStateIntervento,
  ArticoloIngressoIntervento,
  InterventoModel
} from "../Coltura/Model";
import { useDispatch } from "../../../Utilities/SharedHooks/State";
import { UNDO, AllActions } from "../../../Reducers/Root";
import DateInfo from "../../../Components/DatesInfo/DateInfo";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import { useToasts } from "react-toast-notifications";
import { finalizeRestore } from "../../../Utilities/Redux/Restore"
import { navigateBack } from "../../../Reducers/Route";
const promiseTipiIntervento = fetchObjects(`tipointervento/${ID_UNITA}`);

type ColturaModelLocal = {
  idColtura: string;
  idReparto: string;
  descrizione: string;
  interventi: InterventoModelNew[];
  dataInizioPrevisto: string;
  dataFinePrevista: string;
  stato: StatoIntervento;
  dataInizioReale: string;
  dataFineReale: string;
  superficieImpegnata: number;
  lotto: string;
  note: string;
};

const NuovoIntervento: React.FC<{ coltura: ColturaModel, onCreate: (id: string) => void }> = (props) => {
  const [nuovoIntervento, setNuovoIntervento] = useState<Omit<InterventoModelNew, "idIntervento">>(initialStateIntervento);
  const [tipiIntervento, setTipiIntervento] = useState<TipoIntervento[]>([]);
  const [interventoSelected, setInterventoSelected] = useState<Omit<TipoIntervento, "idTipoIntervento">>(initialStateTipoIntervento);
  const [, setError] = useState("");

  const [postColtura, colturaResult] = usePost<ColturaModelLocal>(`colture/${props.coltura.idReparto}`);
  const { addToast } = useToasts();
  const dispatch = useDispatch<AllActions>();

  useEffect(() => {
    // Carico le tipologie di intervento configurate dall'utente
    promiseTipiIntervento
      .then((data) => {
        setTipiIntervento(data);
      })
      .catch((err) => { setError(err); });
  }, []);

  useEffect(() => {
    setInterventoSelected(tipiIntervento?.[0]);
  }, [tipiIntervento]);

  const handleTexboxChange = (fn: (v: InterventoModelNew) => void) => {
    setNuovoIntervento(produce(nuovoIntervento, fn));
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const _nuovoIntervento = {
      idIntervento: uuid.v4()
    , tipoIntervento: interventoSelected
    , articoliIngresso: [] as ArticoloIngressoIntervento[]
    , articoliUscita: [] as ArticoloUscitaIntervento[]
    , macchine: [] as MacchinaIntervento[]
    , persone: [] as PersonaIntervento[]
    , rilevazioni: [] as Rilevazione[]
    , posizione: nuovoIntervento.posizione
    , dataInizioPrevisto: nuovoIntervento.dataInizioPrevisto
    , dataFinePrevista: nuovoIntervento.dataFinePrevista
    , previsto: nuovoIntervento.previsto
    , dataInizioReale: nuovoIntervento.dataInizioReale
    , dataFineReale: nuovoIntervento.dataFineReale
    , stato: nuovoIntervento.stato
    , carenza: nuovoIntervento.carenza
    , causaleTrattamento: nuovoIntervento.causaleTrattamento
    , annotazioniPubbliche: nuovoIntervento.annotazioniPubbliche
    , annotazioniPrivate: nuovoIntervento.annotazioniPrivate
    , costoIntervento: nuovoIntervento.costoIntervento,
    };

    const nuovaColtura: ColturaModel =
      { ...props.coltura, interventi: props.coltura.interventi.concat([_nuovoIntervento]) };

    setNuovoIntervento(_nuovoIntervento);
    postColtura(nuovaColtura)
      .then(_ => {
        if(!_.isError){
          addToast('Intervento inserito correttamente', { appearance: 'success', autoDismiss: true })
          props.onCreate(props.coltura.idReparto)
          setNuovoIntervento(initialStateIntervento);
          finalizeRestore();
          dispatch({ 
            type: 'COLTURA_EDIT', 
            coltura: nuovaColtura
          });
          dispatch(navigateBack())
        }
      })
    // Svuoto le texbox della form

  };

  const handleTipoInterventoSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tipoIntervento =
      _.find(
        _.propEq("descrizione", e.target.value)
        , tipiIntervento);
    if (tipoIntervento) {
      setInterventoSelected(tipoIntervento);
    }
  };

  return (
    <>
      <span><FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" icon={faChevronLeft} onClick={() => dispatch(navigateBack())} size="lg" /><span className="subtitle has-text-grey">Nuovo intervento</span></span>
      <ErrorModal prom={promiseTipiIntervento} />
      <ErrorModal prom={colturaResult} />
      <form onSubmit={handleFormSubmit}>
        <br />
        <div className="columns">
          <div className="column">
            {tipiIntervento && <BasicDropdown label={"Intervento"} defaultValue="Seleziona tipo intervento" values={tipiIntervento.map((x) => x.descrizione)} onSelection={(e) => { handleTipoInterventoSelection(e); }} />}
          </div>
          <div className="column" style={{ display: 'inline-flex' }}>
            <BasicCheckbox
              label="Previsto"
              checked={nuovoIntervento.previsto}
              onChange={(e) => { handleTexboxChange(p => { p.previsto = e.target.checked; }); }} />
            <BasicDropdown
              defaultValue="Seleziona stato"
              values={["programmato", "inCorso", "concluso"]}
              label={"Stato"}
              onSelection={(e) => { handleTexboxChange(p => { p.stato = e.target.value as StatoIntervento; }); }} />

          </div>
        </div>

        <div className="columns display-mobile-block">
          <div className="column">
            <DateInfo title="Data prevista"
              from={nuovoIntervento.dataInizioPrevisto}
              to={nuovoIntervento.dataFinePrevista}
              onFromChanged={(e) => {
                handleTexboxChange((p: InterventoModel) => { p.dataInizioPrevisto = e.target.value; });
              }
              }
              onToChanged={(e) => {
                handleTexboxChange((p: InterventoModel) => { p.dataFinePrevista = e.target.value; });
              }
              }>
            </DateInfo>
          </div>
          <div className="column">
            <DateInfo title="Data reale"
              from={nuovoIntervento.dataInizioReale}
              to={nuovoIntervento.dataFineReale}
              onFromChanged={(e) => {
                handleTexboxChange((p: InterventoModel) => { p.dataInizioReale = e.target.value; });
              }
              }
              onToChanged={(e) => {
                handleTexboxChange((p: InterventoModel) => { p.dataFineReale = e.target.value; });
              }
              }>
            </DateInfo>
          </div>
        </div>

        <BasicTextArea
          label="Annotazioni pubbliche"
          value={nuovoIntervento.annotazioniPubbliche}
          readOnly={false}
          onChange={(e) => { handleTexboxChange((p: InterventoModelNew) => { p.annotazioniPubbliche = e.target.value; }); }} />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Salva intervento
            </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default NuovoIntervento;
