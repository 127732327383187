import { Reducer } from 'redux';

export type User = {
  username: string | null
  token: string | null
}

export type State = User

let lsUser = localStorage.getItem('user')
let user: User = 
  (lsUser !== null && lsUser !== "") ? JSON.parse(lsUser) : {} as User

export const defaultState: State = user ? user : {
  username: null,
  token: null
}

export type Action = {
  type: 'USER_LOGIN',
  user: User
} | {
  type: 'USER_LOGOUT'
}

export const reducer: Reducer<State, Action> = (state, payload) => {
  if (state === undefined) {
    return defaultState
  }
  switch(payload.type) {
    case 'USER_LOGIN':
      return {
        ...state,
        username: payload.user.username,
        token: payload.user.token
      }

    case 'USER_LOGOUT':
      return {
        ...state,
        username: null,
        token: null,
        idAzienda: null
      }

    default: return state
  }
}