import React, { useState } from "react";
import { UnitaProduttiva } from "../Model";
import { ErrorModal, BasicInput, postPhoto, ID_AZIENDA } from "../../../Utilities/SharedComponent";
import { usePost } from "../../../Utilities/SharedHooks/Sync";
import { produce } from "immer";
import { useToasts } from "react-toast-notifications";

type LightUnitaProduttiva = Omit<UnitaProduttiva, "idUnitaProduttiva">;

const defaultUnitaProduttiva: LightUnitaProduttiva= {
  idAzienda: ID_AZIENDA ?? ""
, codiceUnitaProduttiva: ""
, nomeUnita: ""
, alias: ""
, indirizzo: ""
, cap: ""
, citta: ""
, provincia: ""
, regione: ""
, comune: ""
, reparti: []
}

const NuovaUnitaProduttiva: React.FC<{ onCreate: (_ : LightUnitaProduttiva) => void }> = ({ onCreate }) => {
  const [unitaProduttiva, setNuovaUnitaProduttiva] = useState<LightUnitaProduttiva>(defaultUnitaProduttiva);
  const [postUnitaProduttiva, upPostate] = usePost<Omit<UnitaProduttiva, "idUnitaProduttiva">>("unitaproduttive");
  const [photo, setPhoto] = useState()
  const { addToast } = useToasts();

  function setField<T, K extends keyof T>(fn: (React.Dispatch<React.SetStateAction<T>>), obj: T, key: K, value: any) {
    fn(produce(obj, (x: T) => { x[key] = value }))
  };

  const onChangePhoto = (e : any) => {
    e.preventDefault();
    const file = e.target.files[0]
    setPhoto(file)    
  }

  const postProfilePhoto = (idPersona: any) => {
    let formData = new FormData();
    if (photo && idPersona) {
      formData.append('image', photo, photo.name);

      postPhoto(`foto/unitaproduttiva/${idPersona}`, formData)
        .then(_ => addToast('Unità produttiva salvata correttamente', { appearance: 'success', autoDismiss: true }))
    }
  }
  
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const _nuovaUnitaProduttiva: Omit<UnitaProduttiva, "idUnitaProduttiva"> = {
      idAzienda: ID_AZIENDA ?? ""
    , codiceUnitaProduttiva: unitaProduttiva.codiceUnitaProduttiva
    , nomeUnita: unitaProduttiva.nomeUnita
    , alias: unitaProduttiva.alias
    , indirizzo: unitaProduttiva.indirizzo
    , cap: unitaProduttiva.cap
    , citta: unitaProduttiva.citta
    , provincia: unitaProduttiva.provincia
    , regione: unitaProduttiva.regione
    , comune: unitaProduttiva.comune
    , reparti: []
    };

    setNuovaUnitaProduttiva(_nuovaUnitaProduttiva);
    postUnitaProduttiva(_nuovaUnitaProduttiva)
    .then(idUnitaProduttiva => {
      postProfilePhoto(idUnitaProduttiva)
      onCreate(_nuovaUnitaProduttiva);
      })
  };

  return (
    <>
      <ErrorModal prom={upPostate} />
      <form onSubmit={handleFormSubmit}>
      <div className="file file-input-upload padded-input figure-profile-picture-input">
        <label className="file-label">
          <input className="file-input" type="file" name="resume" accept="image/*" onChange={onChangePhoto}/>
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">
              Immagine
            </span>
          </span>
          <span className="file-name">
            {photo && photo?.name || "Selezionare un'immagine"}
          </span>
        </label>
      </div>
        <div className="field is-horizontal">
          <BasicInput 
            label="Nome"
            value={unitaProduttiva.nomeUnita} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "nomeUnita", e.target.value) }} 
          />

          <BasicInput 
            label="Codice" 
            value={unitaProduttiva.codiceUnitaProduttiva} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "codiceUnitaProduttiva", e.target.value) }} 
          />
          
          <BasicInput 
            label="Alias" 
            value={unitaProduttiva.alias} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "alias", e.target.value) }} 
          />

        </div>
        <br />
        <hr className="dropdown-divider" />
        <br />

        <div className="field is-horizontal">
          <BasicInput 
            label="Indirizzo" 
            value={unitaProduttiva.indirizzo} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "indirizzo", e.target.value) }} 
          />

          <BasicInput 
            label="Città" 
            value={unitaProduttiva.citta} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "citta", e.target.value) }} 
          />
        </div>

        <div className="field is-horizontal">
          <BasicInput 
            label="CAP" 
            value={unitaProduttiva.cap} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "cap", e.target.value) }} 
          />

          <BasicInput 
            label="Provincia" 
            value={unitaProduttiva.provincia} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "provincia", e.target.value) }} 
          />

          <BasicInput 
            label="Regione" 
            value={unitaProduttiva.regione} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "regione", e.target.value) }} 
          />

          <BasicInput 
            label="Comune" 
            value={unitaProduttiva.comune} 
            classes="padded-input"
            readOnly={false} 
            onChange={e => { setField(setNuovaUnitaProduttiva, unitaProduttiva, "comune", e.target.value) }} 
          />
        </div>
        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Salva unità produttiva
          </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default NuovaUnitaProduttiva;
