import React from 'react'

export type PadKey =
  | "CE"
  | "."
  | number

const Tastierino: React.FC<{
  sendKey: (key: PadKey) => void
}> = ({ sendKey }) => {

  const buttonClass = "button is-light is-fullwidth is-large"
  return (
    <section className="section">
      <div className="columns">
        {[7, 8, 9].map(n => (
            <div key={n} className="column has-little-padding">
              <button className={buttonClass} onClick={_ => sendKey(n)}>{n}</button>
            </div>
          ))}
      </div>

      <div className="columns">
        {[4, 5, 6].map(n => (
            <div key={n} className="column has-little-padding">
              <button className={buttonClass} onClick={_ => sendKey(n)}>{n}</button>
            </div>
          ))}
      </div>

      <div className="columns">
        {[1, 2, 3].map(n => (
            <div key={n} className="column has-little-padding">
              <button className={buttonClass} onClick={_ => sendKey(n)}>{n}</button>
            </div>
          ))}
      </div>

      <div className="columns">
        <div className="column has-little-padding">
          <button className={buttonClass} onClick={_ => sendKey(".")}>.</button>
        </div>
        <div className="column has-little-padding">
          <button className={buttonClass} onClick={_ => sendKey(0)}>0</button>
        </div>
        <div className="column has-little-padding">
          <button className={buttonClass} onClick={_ => sendKey("CE")}>CE</button>
        </div>
      </div>
    </section>
  )
}

export default Tastierino