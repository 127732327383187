import React, { useState, useEffect } from 'react';
import { MacchinaIntervento, Macchina as MacchinaModel, MacchinaInterventoNew, initialStateMacchinaIntervento, ColturaModel } from '../../Coltura/Model';
import { fetchObjects, BasicDropdown, postObject, BasicInput, ErrorModal, TimeInput, DateInput, ID_UNITA } from '../../../../Utilities/SharedComponent';
import { UNDO_ACTION, UNDO } from '../../../../Reducers/Root';
import { useDispatch, useSelector } from '../../../../Utilities/SharedHooks/State';
import * as _ from 'ramda';
import produce from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import DateInfo from '../../../../Components/DatesInfo/DateInfo';
import moment from 'moment';
import { usePost } from '../../../../Utilities/SharedHooks/Sync';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import { navigateBack } from '../../../../Reducers/Route';
registerLocale('it', it)

const fetchMacchine = fetchObjects(`macchine/${ID_UNITA}`);

export const Macchina: React.FC = () => {
  const macchina = useSelector(x => x.present.macchina);
  const dispatch = useDispatch();
  const stateColtura = useSelector(x => x.present.coltura)
  const idIntervento = useSelector(x => x.present.idIntervento)

  const [macchine, setMacchine] = useState<MacchinaModel[]>([]);
  const [macchinaModificata, setMacchinaModificata] = useState(macchina)
  const [tempo, setTempo] = useState<string>("")
  const [postColtura, colturaPostata] = usePost(`colture/${stateColtura?.idReparto}`)

  useEffect(() => {
    fetchMacchine
      .then((data) => { setMacchine(data); })
  }, []);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const macchina = macchine.find(x => x.idMacchina === macchinaModificata?.idMacchina)

    if (macchina && macchinaModificata) {
      const nuovaMacchina: MacchinaIntervento = {
        idMacchinaIntervento: macchinaModificata.idMacchinaIntervento
      , idMacchina: macchina.idMacchina
      , dataInizio: macchinaModificata.dataInizio
      , dataFine: macchinaModificata.dataFine
      , descrizione: macchinaModificata.descrizione
      , tempo: macchinaModificata.tempo
      , costoFisso: macchinaModificata.costoFisso
      , costoOrario: macchinaModificata.costoOrario
      , costoTotale: macchinaModificata.costoTotale
      };
      
      const newStateColtura: ColturaModel | undefined = produce(stateColtura, c => {
        if (c) {
          const index = c.interventi
            .find(colt => colt.idIntervento === idIntervento)?.macchine
            .findIndex(mac => mac.idMacchinaIntervento === macchinaModificata.idMacchinaIntervento)
          if (index !== undefined) {
            const x = c.interventi.find(s => s.idIntervento === idIntervento)
            if (x) {
              x.macchine[index] = nuovaMacchina
            }
          }
        }
      });
      if (newStateColtura !== undefined) {
        postColtura(newStateColtura);
      }
    }
  };

  const handleTextboxChange = (fn: (v: MacchinaIntervento) => void) => {
    setMacchinaModificata(produce(macchinaModificata, fn));
  };

  const calcolaTempo = () => {
    const s = moment(macchinaModificata?.dataInizio)
    const e = moment(macchinaModificata?.dataFine)
    if (!_.isNil(s) && !_.isNil(e)) {
      const diff = e.diff(s)
      setTempo(moment(diff).utc().format('HH:mm'))
    }
  }

  useEffect(() => {
    calcolaTempo()
  }, [macchinaModificata?.dataFine, macchinaModificata?.dataInizio])

  const formBody =
    macchina && (
      <form onSubmit={submitForm} className="has-450-max-width">
        <span>
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
          <span className="subtitle has-text-grey">{macchina.descrizione}</span> 
        </span>

        <br/>
        <br/>

        <span>Tempo di utilizzo</span>
      
        <div className="columns">
          <div className="column is-one-fifth" style={{ margin: 'auto'}}>
            <span>Dal</span>
          </div>
          <div className="column">
            <DatePicker
              selected={new Date(macchinaModificata?.dataInizio || moment().format('YYYY-MM-DD'))}
              onChange={d => { handleTextboxChange((p: MacchinaIntervento) => { p.dataInizio = d?.toISOString() || moment().toISOString() })}}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="Ore"
              locale="it"
              dateFormat="d MMMM, yyyy h:mm aa"
              className="input"
              withPortal
            />
          </div>
          <div className="column is-one-fifth" style={{ margin: 'auto'}}>
            <span>Al</span>
          </div>
          <div className="column">
            <DatePicker
              selected={new Date(macchinaModificata?.dataFine || moment().format('YYYY-MM-DD'))}
              onChange={d => { handleTextboxChange((p: MacchinaIntervento) => { p.dataFine = d?.toISOString() || moment().toISOString() })}}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="Ore"
              locale="it"
              dateFormat="d MMMM, yyyy h:mm aa"
              className="input"
              withPortal
            />
          </div>
        </div>

        <BasicInput 
          label="Tempo/h" 
          value={tempo || 0} 
          readOnly={true} 
        />

        <BasicInput 
          label="Costo fisso" 
          value={macchina.costoFisso.toFixed(2)} 
          readOnly={true} 
        />
        
        <BasicInput 
          label="Costo orario" 
          value={macchina.costoOrario.toFixed(2)} 
          readOnly={true} 
        />

        <div className="field is-right">
          <p className="control">
            <button type="submit" className="button is-success" >
              Applica
            </button>
          </p>
        </div>
      </form>
    );

  return (
    <>{formBody}</>
  );
};

export const NuovaMacchina: React.FC = () => {
  const [macchine, setMacchine] = useState<MacchinaModel[]>([]);

  const [macchina, setMacchina] = useState<MacchinaInterventoNew>(initialStateMacchinaIntervento);
  const [macchinaSelezionata, setMacchinaSelezionata] = useState<MacchinaModel>();

  const [error, setError] = useState("");

  const stateColtura = useSelector(state => state.present.nuovaMacchina);
  const dispatch = useDispatch();
  const idIntervento = useSelector(state => state.present.idIntervento);
  const [postColtura, colturaPostata] = usePost(`colture/${stateColtura?.idReparto}`)

  useEffect(() => {
    fetchMacchine
      .then((data) => { setMacchine(data); })
      .catch((err) => { setError(err); });
  }, []);

  const handleMacchinaSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const macch =
      _.find(
        _.propEq("nomeMacchina", e.target.value)
        , macchine);
    if (macch) {
      setMacchinaSelezionata(macch);
    } else setMacchinaSelezionata({} as MacchinaModel)
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (macchinaSelezionata) {
      const nuovaMacchina: MacchinaInterventoNew = {
        idMacchina: macchinaSelezionata?.idMacchina ?? macchine[0].idMacchina
      , dataInizio: macchina.dataInizio
      , dataFine: macchina.dataFine
      , descrizione: macchinaSelezionata.nomeMacchina
      , tempo: macchina.tempo
      , costoFisso: macchinaSelezionata.costoFisso
      , costoOrario: macchinaSelezionata.costoOrario
      , costoTotale: (macchina.tempo / 60) * macchinaSelezionata.costoOrario
      };
      if (stateColtura) {
        const newStateColtura: ColturaModel = produce(stateColtura, c => {
          c.interventi
            .find(colt => colt.idIntervento === idIntervento)
            ?.macchine?.push({
              ...nuovaMacchina, idMacchinaIntervento: ""
            });
        });
        postColtura(newStateColtura);
      }
    }
  };

  const handleTextboxChange = (fn: (v: MacchinaInterventoNew) => void) => {
    setMacchina(produce(macchina, fn));
  };

  const [tempo, setTempo] = useState<string>("")
  const calcolaTempo = () => {
    const s = moment(macchina.dataInizio)
    const e = moment(macchina.dataFine)
    if (!_.isNil(s) && !_.isNil(e)) {
      const diff = e.diff(s)
      const m = moment.duration(e.diff(s))
      setMacchina(produce(macchina, (x: MacchinaInterventoNew) => { x.tempo = m.asMinutes()}))
      setTempo(moment(diff).utc().format('HH:mm'))
    }
  }

  useEffect(() => {
    calcolaTempo()
  }, [macchina.dataFine, macchina.dataInizio])

  return (
    <form onSubmit={submitForm} className="has-450-max-width">
      <ErrorModal prom={colturaPostata} />
      <span>
          <FontAwesomeIcon className="is-sidebar-listitem-icon is-clickable" onClick={() => dispatch(navigateBack())} icon={faChevronLeft} size="lg" />
          <span className="subtitle has-text-grey">Aggiungi un macchinario</span>
      </span>

      <br />
      <br />

      <BasicDropdown label={"Macchinari"} defaultValue="Seleziona macchinario" values={macchine.map(x => x.nomeMacchina)} onSelection={(e) => { handleMacchinaSelection(e); }} />

      <span>Tempo di utilizzo</span>
      
      <div className="columns">
        <div className="column is-one-fifth" style={{ margin: 'auto'}}>
          <span>Dal</span>
        </div>
        <div className="column">
          <DatePicker
            selected={new Date(macchina.dataInizio || moment().format('YYYY-MM-DD'))}
            onChange={d => { handleTextboxChange((p: MacchinaInterventoNew) => { p.dataInizio = d?.toISOString() || moment().toISOString() })}}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            timeCaption="Ore"
            locale="it"
            dateFormat="d MMMM, yyyy h:mm aa"
            className="input"
            withPortal
          />
        </div>
        <div className="column is-one-fifth" style={{ margin: 'auto'}}>
          <span>Al</span>
        </div>
        <div className="column">
          <DatePicker
            selected={new Date(macchina.dataFine || moment().format('YYYY-MM-DD'))}
            onChange={d => { handleTextboxChange((p: MacchinaInterventoNew) => { p.dataFine = d?.toISOString() || moment().toISOString() })}}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            timeCaption="Ore"
            locale="it"
            dateFormat="d MMMM, yyyy h:mm aa"
            className="input"
            withPortal
          />
        </div>
      </div>

      <BasicInput
        label="Tempo/h"
        value={tempo || 0}
        readOnly={true}
      />

      <BasicInput
        label="Costo fisso"
        value={macchinaSelezionata?.costoFisso || 0}
        readOnly={true}
      />
        
      <BasicInput
        label="Costo orario"
        value={macchinaSelezionata?.costoOrario || 0}
        readOnly={true}
        // onChange={(e) => { handleTextboxChange((p: MacchinaInterventoNew) => { p.costoOrario = Number(e.target.value); }); }}
      />

      <div className="field is-right">
        <p className="control">
          <button type="submit" className="button is-success" >
            Salva macchinario
          </button>
        </p>
      </div>

    </form>
  );
};
