import React from "react";
import QDCSidebarButton from "./QDCSidebarButton/QDCSidebarButton";
import "./Sidebar.css";
import UserInfo from "./UserInfo/UserInfo";

export const Sidebar: React.FC = () => {

  function isDev() {

    if (window.location.hostname.startsWith('localhost')) {
      return true
    }
  
    return window.location.hostname.includes('staging')
  }

  return (
    <>
      <UserInfo />
      <QDCSidebarButton />
      <small style={{ color: 'gray', position: 'absolute', bottom: '20px' }}>Versione: 1.0.16 {isDev() ? <span className="tag is-warning">STAGING</span> : <></>}</small>
    </>
  )
};
